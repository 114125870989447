import create from 'zustand';
import { LoginModalDataType } from '@/constants/modal';

interface LoginModalStore {
  isShowing: boolean;
  setIsShowing: (isShowing: boolean) => void;
  data: LoginModalDataType | undefined;
  setData: (data: LoginModalDataType) => void;
}

const useLoginModalStore = create<LoginModalStore>((set) => ({
  isShowing: false,
  setIsShowing: (isShowing: boolean) => {
    set((state) => ({ ...state, isShowing }));
  },
  data: undefined,
  setData: (data: LoginModalDataType) => {
    set((state) => ({ ...state, data }));
  },
}));

export default useLoginModalStore;
