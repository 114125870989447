/* eslint sort-keys: ["error", "asc", {"caseSensitive": true, "natural": false, "minKeys": 2}] */

export const WEB_STORAGE_KEY = {
  CATEGORY_REVIEW_SELECTED: 'categoryReviewSelected',
  CHECK_FILTER_RESET_MODAL_TIME: 'CHECK_FILTER_RESET_MODAL_TIME',
  DIV_SCROLL_X_POSITION: 'divScrollXPosition',
  DIV_SCROLL_Y_POSITION: 'divScrollYPosition',
  HOSPITAL_SEARCH_ADDRESS_CODE: 'HOSPITAL_SEARCH_ADDRESS_CODE',
  HOSPITAL_SEARCH_DATA: 'HOSPITAL_SEARCH_DATA',
  HOSPITAL_SEARCH_IN_CATEGORY_DATA: 'HOSPITAL_SEARCH_IN_CATEGORY_DATA',
  HOSPITAL_SEARCH_IN_SYMPTOM_DATA: 'HOSPITAL_SEARCH_IN_SYMPTOM_DATA',
  IS_DIRECT_RESULT: 'IS_DIRECT_RESULT',
  IS_FOLD_CATEGORY_LIST: 'IS_FOLD_CATEGORY_LIST',
  IS_GET_CURRENT_LOCATION_ONCE: 'IS_GET_CURRENT_LOCATION_ONCE',
  IS_HASHTAG: 'IS_HASHTAG',
  IS_ROUTE_FROM_PLAY: 'IS_ROUTE_FROM_PLAY',
  IS_SHOW_CATEGORY_NEW_LABEL: 'IS_SHOW_CATEGORY_NEW_LABEL',
  IS_SHOW_RESERVATION_PROCESS_GUIDE_TOOLTIP: 'IS_SHOW_RESERVATION_PROCESS_GUIDE_TOOLTIP',
  KAKAO_MAP_COORDINATION: 'KAKAO_MAP_COORDINATION',
  KAKAO_MAP_RADIUS: 'KAKAO_MAP_RADIUS',
  LAST_CHANGED_RESERVATIONS: 'LAST_CHANGED_RESERVATIONS',
  LOCATION_PERMISSION: 'LOCATION_PERMISSION',
  LOCATION_PERMISSION_FIRST_ONCE_CHECK: 'LOCATION_PERMISSION_FIRST_ONCE_CHECK',
  MYPAGE_SCRAP_TAB: 'MYPAGE_SCRAP_TAB',
  PAGE_SCROLL_Y_POSITION: 'pageScrollYPosition',
  PLAY_REVIEW_ONBOARDING: 'PLAY_REVIEW_ONBOARDING',
  RECENT_SEARCH_HOSPITAL: 'RECENT_SEARCH_HOSPITAL',
  RECENT_SEARCH_HOSPITAL_WORD: 'RECENT_SEARCH_HOSPITAL_WORD',
  RECENT_SEARCH_SYMPTOM: 'RECENT_SEARCH_SYMPTOM',
  RECENT_SEARCH_SYMPTOM_WORD: 'RECENT_SEARCH_SYMPTOM_WORD',
  RECENT_VIEW_HOSPITAL: 'RECENT_VIEW_HOSPITAL',
  RESERVATION_COUNTING_TOOLTIP_SHOW_CHECK: 'RESERVATION_COUNTING_TOOLTIP_SHOW_CHECK',
  RESERVATION_POINTS_TOOLTIP_DISPLAY_DATE: 'RESERVATION_POINTS_TOOLTIP_DISPLAY_DATE',
  RESERVATION_PROVIDER_INITIALIZED: 'RESERVATION_PROVIDER_INITIALIZED',
  RESERVATION_USER_REQUEST_MESSAGE: 'RESERVATION_USER_REQUEST_MESSAGE',
  REVIEW_DETAIL_BACK_TO_SYMPTOM_SEARCH: 'REVIEW_DETAIL_BACK_TO_SYMPTOM_SEARCH',
  REVIEW_DETAIL_TOOLTIP_SHOW_CHECK: 'REVIEW_DETAIL_TOOLTIP_SHOW_CHECK',
  REVIEW_LIKE_ID_LIST_COPIED: 'REVIEW_LIKE_ID_LIST_COPIED',
  REVIEW_WRITE_LOCATION: 'REVIEW_WRITE_LOCATION',
  REVIEW_WRITE_TOOLTIP_SHOW_CHECK: 'REVIEW_WRITE_TOOLTIP_SHOW_CHECK',
  SEARCH_LOCATION_SYMPTOM: 'SEARCH_LOCATION_SYMPTOM',
  SELECTED_ADDRESS_LATITUDE: 'SELECTED_ADDRESS_LATITUDE',
  SELECTED_ADDRESS_LONGITUDE: 'SELECTED_ADDRESS_LONGITUDE',
  SHOW_RESERVATION_TOOLTIP: 'showReservationTooltip',
  SHOW_REVIEW_CATEGORY_TOOLTIP: 'showReviewCategoryTooltip',
  SHOW_SYMPTOM_SEARCH_TOOLTIP: 'SHOW_SYMPTOM_SEARCH_TOOLTIP',
  SYMPTOM_SEARCH_DATA: 'SYMPTOM_SEARCH_DATA',
  SYMPTOM_SEARCH_SELECT_ANIMAL: 'SYMPTOM_SEARCH_SELECT_ANIMAL',
};
