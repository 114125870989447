import styled from '@emotion/styled';
import { GLOBAL_COLOR } from '@/styles/colors';
import { Z_INDEX } from '@/styles/zIndex';
import { toRem } from '@/utils/commonUtils';

const BaseContainer = styled.div`
  display: flex;
  position: absolute;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - (${toRem(28)} * 2));
  height: auto;
  border-radius: ${toRem(16)};
  overflow: hidden;
  background-color: ${GLOBAL_COLOR.WHITE};
  z-index: ${Z_INDEX.MODAL_CONTENT};
`;

export default BaseContainer;
