import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import useToastStore from './useToastStore';
import { TOAST_ANIMATION_TIME, TOAST_MARGIN, TOAST_SIDE_PADDING } from '@/constants/toast';
import { GLOBAL_LAYOUT_PX } from '@/styles/layout';
import { Z_INDEX } from '@/styles/zIndex';
import { toRem } from '@/utils/commonUtils';

const GlobalToast = () => {
  const { toastList, clearAllToastList } = useToastStore((state) => state);

  useEffect(() => {
    const toastListTimeout = setTimeout(() => {
      clearAllToastList();
    }, TOAST_ANIMATION_TIME.TIME_OUT * 1000);

    return () => clearTimeout(toastListTimeout);
  }, [clearAllToastList, toastList]);

  useEffect(() => {
    clearAllToastList();
  }, [clearAllToastList]);

  return (
    <Container height={toastList[0]?.margin || TOAST_MARGIN.SMALL}>
      <Wrapper>
        {toastList.map((toast, index) => {
          const isOverlayed = index > 0 && toast.text === toastList[index - 1].text;

          return (
            <ContentContainer key={index} onClick={() => toast.onClick?.()} isOverlayed={isOverlayed}>
              <Content>{toast.text}</Content>
              {toast.secondCol && <Content>{toast.secondCol}</Content>}
            </ContentContainer>
          );
        })}
      </Wrapper>
    </Container>
  );
};

export default GlobalToast;

const Container = styled.section<{ height: number }>`
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  width: calc(100% - ${TOAST_SIDE_PADDING}px);
  max-width: calc(${GLOBAL_LAYOUT_PX.SCREEN_OFFSET}px - ${TOAST_SIDE_PADDING}px);
  z-index: ${Z_INDEX.TOAST};
  bottom: ${({ height }) => `calc(env(safe-area-inset-bottom) + ${toRem(height)})`};
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const ContentContainer = styled.div<{ isOverlayed: boolean }>`
  padding: ${toRem(14)} ${toRem(16)};
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  min-height: 48px;
  border-radius: 8px;
  background-color: #1b1e21;
  color: white;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
  animation: ${TOAST_ANIMATION_TIME.FADE_IN_DURATION}s cubic-bezier(0.65, 0, 0.35, 1),
    ${TOAST_ANIMATION_TIME.FADE_IN_DELAY}s forwards fadeIn,
    ${TOAST_ANIMATION_TIME.FADE_OUT_DURATION}s cubic-bezier(0.65, 0, 0.35, 1) ${TOAST_ANIMATION_TIME.FADE_OUT_DELAY}s
      forwards fadeOut,
    0s step-end ${TOAST_ANIMATION_TIME.TIME_OUT}s forwards hide;

  ${(props) =>
    props.isOverlayed &&
    css`
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    `};

  @keyframes fadeIn {
    100% {
      opacity: 0.9;
    }
  }

  @keyframes fadeOut {
    100% {
      opacity: 0;
    }
  }

  @keyframes hide {
    100% {
      position: absolute;
      bottom: -2000px;
    }
  }
`;

const Content = styled.div`
  text-align: center;
`;
