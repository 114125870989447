import { useCallback, useEffect, useState } from 'react';
import useHospitalScrapStore from '@/components/hospital/useHospitalScrapStore';
import useReviewLikeIdListStore from '@/components/review/store/useReviewLikeIdListStore';
import useReviewScrapIdListStore from '@/components/review/store/useReviewScrapIdListStore';
import { useGetHospitalScrapIdList } from '@/queries/query/useHospitalListQuery';
import { useGetMyScrapReviewIdList, useGetMyLikeReviewIdList } from '@/queries/query/useReviewsQuery';

interface UseInitializerType {
  init: () => void;
  reInit: () => void;
  deInit: () => void;
  invalidateQueriesForReInit: () => void;
}

const useInitializer = (): UseInitializerType => {
  const [doInit, setDoInit] = useState<boolean>(false);
  const { refetch: getHospitalScrapIdListRefetch } = useGetHospitalScrapIdList(false);
  const { setHospitalScrapSet: setHospitalScrapSetStore, clearHospitalScrap: clearHospitalScrapStore } =
    useHospitalScrapStore();

  const { refetch: getMyScrapReviewIdListRefetch } = useGetMyScrapReviewIdList(false);
  const { setReviewScrapedIdList: setReviewScrapedIdListStore, clearReviewScrapedList: clearReviewScrapedListStore } =
    useReviewScrapIdListStore();

  const { refetch: getMyLikeReviewIdListRefetch } = useGetMyLikeReviewIdList(false);
  const { setMyReviewLikedIdList: setMyReviewLikedIdListStore, clearReviewLikedList: clearReviewLikedListStore } =
    useReviewLikeIdListStore();

  const init = useCallback(() => {
    if (!doInit) {
      setDoInit(true);
    }
  }, [doInit]);
  const deInit = useCallback(() => {
    if (doInit) {
      setDoInit(() => false);
    }
  }, [doInit]);

  const initHospitalScrap = useCallback(async () => {
    const hospitalScrapIdListResult = await getHospitalScrapIdListRefetch();
    if (hospitalScrapIdListResult.isSuccess) {
      setHospitalScrapSetStore(new Set<number>(hospitalScrapIdListResult.data));
    }
  }, [getHospitalScrapIdListRefetch, setHospitalScrapSetStore]);

  const initReviewScrap = useCallback(async () => {
    const myScrapReviewIdListResult = await getMyScrapReviewIdListRefetch();
    if (myScrapReviewIdListResult.isSuccess) {
      setReviewScrapedIdListStore(myScrapReviewIdListResult.data);
    }
  }, [getMyScrapReviewIdListRefetch, setReviewScrapedIdListStore]);

  const initReviewLiked = useCallback(async () => {
    const myLikeReviewIdListResult = await getMyLikeReviewIdListRefetch();
    if (myLikeReviewIdListResult.isSuccess) {
      setMyReviewLikedIdListStore(myLikeReviewIdListResult.data);
    }
  }, [getMyLikeReviewIdListRefetch, setMyReviewLikedIdListStore]);

  const reInit = useCallback(async () => {
    await initHospitalScrap();
    await initReviewScrap();
    await initReviewLiked();
  }, [initHospitalScrap, initReviewScrap, initReviewLiked]);

  const invalidateQueriesForReInit = useCallback(async () => {}, []);

  useEffect(() => {
    if (doInit) {
      initHospitalScrap().then(() => {});
      initReviewScrap().then(() => {});
      initReviewLiked().then(() => {});
    } else {
      clearHospitalScrapStore();
      clearReviewLikedListStore();
      clearReviewScrapedListStore();
      invalidateQueriesForReInit().then(() => {});
    }
  }, [
    doInit,
    initHospitalScrap,
    initReviewScrap,
    initReviewLiked,
    clearHospitalScrapStore,
    clearReviewLikedListStore,
    clearReviewScrapedListStore,
    invalidateQueriesForReInit,
  ]);

  return {
    init,
    reInit,
    deInit,
    invalidateQueriesForReInit,
  };
};

export default useInitializer;
