export enum SECTION_TYPE {
  MAIN_BANNER = 'main_banner',
  MIDDLE_BANNER = 'middle_banner',
  BOTTOM_BANNER = 'bottom_banner',
}

export enum HospitalEvent {
  CLICK_HOSPITALHOME_ONBOARDING_BUTTON = 'click_hospitalhome_onboarding_button',
  VIEW_HOSPITALHOME = 'view_hospitalhome',
  VIEW_HOSPITALHOME_SEARCHRESULT = 'view_hospitalhome_searchresult',
  CLICK_HOSPITALHOME_CATEGORY_BUTTON = 'click_hospitalhome_category_button',
  VIEW_HOSPITALSYMPTOM_CATEGORY = 'view_hospitalsymptom_category',
  CLICK_HOSPITALSYMPTOM_CATEGORYSYMPTOM = 'click_hospitalsymptom_categorysymptom',
  CLICK_HOSPITALSYMPTOM_CATEGORYDETAIL_SYMPTOM = 'click_hospitalsymptom_categorydetail_symptom',
  CLICK_HOSPITALSYMPTOM_CATEGORYDETAIL_TOTALREVIEW = 'click_hospitalsymptom_categorydetail_totalreview',
  CLICK_HOSPITALSYMPTOM_CATEGORYREVIEW = 'click_hospitalsymptom_categoryreview',
  VIEW_HOSPITALSYMPTOM_SEARCHRESULT = 'view_hospitalsymptom_searchresult',
  CLICK_HOSPITALSYMPTOM_SEARCHRESULT_REVIEW = 'click_hospitalsymptom_searchresult_review',
  CLICK_HOSPITALREVIEW_COMMON_REVIEWSCRAP = 'click_hospitalreview_common_reviewscrap',
  CLICK_HOSPITALREVIEW_COMMON_REVIEWUNSCRAP = 'click_hospitalreview_common_reviewunscrap',
  CLICK_HOSPITALREVIEW_COMMON_REVIEWLIKE = 'click_hospitalreview_common_reviewlike',
  CLICK_HOSPITALREVIEW_COMMON_REVIEWUNLIKE = 'click_hospitalreview_common_reviewunlike',
  VIEW_HOSPITALREVIEW_COMMON_REVIEWLIST = 'view_hospitalreview_common_reviewlist',
  VIEW_HOSPITALREVIEW_DETAIL = 'view_hospitalreview_detail',
  CLICK_HOSPITALREVIEW_DETAIL_HOSPITAL = 'click_hospitalreview_detail_hospital',
  CLICK_HOSPITALREVIEW_DETAIL_POPULARREVIEW = 'click_hospitalreview_detail_popularreview',
  CLICK_HOSPITALREVIEW_DETAIL_RELATEDHOSPITAL = 'click_hospitalreview_detail_relatedhospital',
  CLICK_HOSPITALREVIEW_DETAIL_RECOMMENDPRODUCT = 'click_hospitalreview_detail_recommendproduct',
  CLICK_HOSPITALREVIEW_DETAIL_RECOMMENDPRODUCT_ANOTHER = 'click_hospitalreview_detail_recommendproduct_another',
  CLICK_HOSPITALRESERVATION_TAB_HOSPITAL = 'click_hospitalreservation_tab_hospital',
  VIEW_HOSPITALRESERVATION_TAB = 'view_hospitalreservation_tab',
  SEARCH_HOSPITALRESERVATION_TAB_KEYWORD = 'search_hospitalreservation_tab_keyword',
  CLICK_HOSPITALRESERVATION_TAB_SEARCHRESULT_HOSPITAL = 'click_hospitalreservation_tab_searchresult_hospital',
  CLICK_HOSPITALDETAIL_CALL = 'click_hospitaldetail_call',
  CLICK_HOSPITALDETAIL_RESERVATION_BUTTON = 'click_hospitaldetail_reservation_button',
  CLICK_HOSPITALDETAIL_SCRAP = 'click_hospitaldetail_scrap',
  CLICK_HOSPITALDETAIL_UNSCRAP = 'click_hospitaldetail_unscrap',
  CLICK_HOSPITALDETAIL_REVIEWWRITE_BUTTON = 'click_hospitaldetail_reviewwrite_button',
  CLICK_HOSPITALDETAIL_REVIEWDETAIL = 'click_hospitaldetail_reviewdetail',
  CLICK_HOSPITALDETAIL_TOTALREVIEW = 'click_hospitaldetail_totalreview',
  CLICK_HOSPITALDETAIL_REVIEWLIST_REVIEW = 'click_hospitaldetail_reviewlist_review',
  CLICK_HOSPITALREVIEW_WRITE_CERTIFICATIONSUBMIT = 'click_hospitalreview_write_certificationsubmit',
  CLICK_HOSPITALREVIEW_WRITE_DISEASEADD = 'click_hospitalreview_write_diseaseadd',
  CLICK_HOSPITALREVIEW_WRITE_REVIEWWRITECOMPLETED = 'click_hospitalreview_write_reviewwritecompleted',
  CLICK_HOSPITALRESERVATION_PETSELECT_NEXT = 'click_hospitalreservation_petselect_next',
  CLICK_HOSPITALRESERVATION_DATESELECT_NEXT = 'click_hospitalreservation_dateselect_next',
  CLICK_HOSPITALRESERVATION_DETAILSELECT_NEXT = 'click_hospitalreservation_detailselect_next',
  CLICK_HOSPITALRESERVATION_REQUESTWRITE_NEXT = 'click_hospitalreservation_requestwrite_next',
  COMPLETE_HOSPITALADMIN_CONFIRM = 'complete_hospitaladmin_confirm',
  COMPLETE_HOSPITALADMIN_VISIT = 'complete_hospitaladmin_visit',
  VIEW_HOSPITALREVIEW_DETAIL_RECOMMENDPRODUCT = 'view_hospitalreview_detail_recommendproduct',
  VIEW_HOSPITALDETAIL_REVIEWTAB = 'view_hospitaldetail_reviewtab',
  CLICK__HOSPITALRESERVATION_KEYWORD_RECENTHOSPITAL = 'click_hospitalreservation_keyword_recenthospital',
  CLICK__HOSPITALRESERVATION_TAB_SEARCH = 'click_hospitalreservation_tab_search',
  CLICK_HOSPITALDETAIL_SHARE = 'click_hospitaldetail_share',
  CLICK_HOSPITALSYMPTOM_CATEGORYSEARCH_BUTTON = 'click_hospitalsymptom_categorysearch_button',
  CLICK_HOSPITALREVIEW_DETAIL_ANOTHER = 'click_hospitalreview_detail_another',
  CLICK__HOSPITALRESERVATION_HOME_BANNER = 'click_hospitalreservation_home_banner',
  VIEW__HOSPITALRESERVATION_HOME_BANNER = 'view_hospitalreservation_home_banner',
  CLICK_HOSPITALRESERVATION_HOME_MIDDLEBANNER = 'click_hospitalreservation_home_middlebanner',
  VIEW_HOSPITALRESERVATION_HOME_MIDDLEBANNER = 'view_hospitalreservation_home_middlebanner',
  CLICK__HOSPITALRESERVATION_TAB_RECOMMENDFILTER = 'click_hospitalreservation_tab_recommfilter',
  CLICK__HOSPITALRESERVATION_TAB_RESERVATIONFILTER = 'click_hospitalreservation_tab_reservationfilter',
  CLICK__HOSPITALRESERVATION_TAB_HOSPITALTIMEFILTER = 'click_hospitalreservation_tab_hospitaltimefilter',
  CLICK__HOSPITALRESERVATION_TAB_LISTFILTER = 'click_hospitalreservation_tab_listfilter',
  CLICK__HOSPITALRESERVATION_TAB_LOCATIONFILTER = 'click_hospitalreservation_tab_locfilter',
  VIEW_HOSPITAL_REVIEW_WRITE = 'view_hospital_review_write',
  VIEW_HOSPITAL_QNA = 'view_hospital_qna',
  COMPLETE_HOSPITAL_QNA = 'complete_hospital_qna',
  VIEW_HOSPITALDETAIL = 'view_hospitaldetail',
  CLICK_HOSPITALRESERVATION_RECENTINFO = 'click_hospitalreservation_recentinfo',
  VIEW_HOSPITALRESERVATION = 'view_hospitalreservation',
  CLICK_HOSPITALRESERVATION_PETSELECT = 'click_hospitalreservation_petselect',
  CLICK_HOSPITALRESERVATION_PETADD = 'click_hospitalreservation_petadd',
  CLICK_HOSPITALRESERVATION_TYPESELECT = 'click_hospitalreservation_typeselect',
  CLICK_HOSPITALRESERVATION_OPTIONSELECT = 'click_hospitalreservation_optionselect',
  CLICK_HOSPITALRESERVATION_DATESELECT = 'click_hospitalreservation_dateselect',
  CLICK_HOSPITALRESERVATION_TIMESELECT = 'click_hospitalreservation_timeselect',
  CLICK_HOSPITALRESERVATION_RESERVATION_BUTTON = 'click_hospitalreservation_reservation_button',
  COMPLETE_HOSPITALRESERVATION = 'complete_hospitalreservation',
  VIEW_RESERVATIONLIST = 'view_reservationlist',
  CLICK_RESERVATIONLIST_AGAIN = 'click_reservationlist_again',
  CLICK_RESERVATIONLIST_REVIEWWRITE = 'click_reservationlist_reviewwrite',
  CLICK_RESERVATIONLIST_QNA = 'click_reservationlist_qna',
  VIEW_RESERVATIONDETAIL = 'view_reservationdetail',
  CLICK_RESERVATIONDETAIL_AGAIN = 'click_reservationdetail_again',
  CLICK_RESERVATIONDETAIL_CANCEL = 'click_reservationdetail_cancel',
  CLICK_RESERVATIONDETAIL_MODIFY = 'click_reservationdetail_modify',
  COMPLETE_HOSPITALRESERVATION_MODIFY = 'complete_hospitalreservation_modify',
  VIEW_HOSPITALTAB_SHORTCUT = 'view_hospitaltab_shortcut',
  CLICK_HOSPITALTAB_SHORTCUT = 'click_hospitaltab_shortcut',
  VIEW_HOSPITALMAP = 'view_hospitalmap',
  CLICK_HOSPITALMAP_RECOMMFILTER = 'click_hospitalmap_recommfilter',
  CLICK_HOSPITALMAP_SEARCHAGAIN = 'click_hospitalmap_searchagain',
  CLICK_HOSPITALMAP_ICON = 'click_hospitalmap_icon',
  CLICK_HOSPITALMAP_LIST = 'click_hospitalmap_list',
  CLICK_HOSPITALMAP_LIST_RESERVATIONFILTER = 'click_hospitalmap_list_reservationfilter',
  CLICK_HOSPITALMAP_LIST_HOSPITALTIMEFILTER = 'click_hospitalmap_list_hospitaltimefilter',
  CLICK_HOSPITALMAP_LIST_SCRAP = 'click_hospitalmap_list_scrap',
  CLICK_HOSPITALMAP_CURRENT = 'click_hospitalmap_current',
  VIEW_RESERVATION_POPUP_LIMIT = 'view_reservation_popup_limit',
  CLICK_RESERVATION_POPUP_LIMIT = 'click_reservation_popup_limit',
  VIEW_HOSPITALREVIEW_TAB = 'view_hospitalreview_tab',
  VIEW_HOSPITALREVIEW_RESERVATIONBANNER = 'view_hospitalreview_reservationbanner',
  VIEW_HOSPITALREVIEW_RESERVATIONLIST = 'view_hospitalreview_reservationlist',
  CLICK_HOSPITALREVIEW_LIST_HOSPITAL = 'click_hospitalreview_list_hospital',
  CLICK_HOSPITALREVIEW_HOSPITAL_TOTAL_BUTTON = 'click_hospitalreview_hospital_total_button',
  CLICK_HOSPITALREVIEW_AHEADBANNER = 'click_hospitalreview_aheadbanner',
  CLICK_HOSPITALREVIEW_RESERVATIONLIST_HOSPITAL = 'click_hospitalreview_reservationlist_hospital',
  CLICK_HOSPITALREVIEW_AHEADLIST = 'click_hospitalreview_aheadlist',
  CLICK_HOSPITALREVIEW_RECENTREVIEW_REVIEW = 'click_hospitalreview_recentreview_review',
  CLICK_HOSPITALREVIEW_RESERVATIONBANNER = 'click_hospitalreview_reservationbanner',
  VIEW_HOSPITALREVIEW_RESERVATIONPOPUP = 'view_hospitalreview_reservationpopup',
  CLICK_HOSPITALREVIEW_RESERVATIONPOPUP = 'click_hospitalreview_reservationpopup',
  CLICK_HOSPITALREVIEW_RESERVATIONLIST_MORE = 'click_hospitalreview_reservationlist_more',
  CLICK_HOSPITALREVIEW_RECENTREVIEW_HOSPITAL = 'click_hospitalreview_recentreview_hospital',
  VIEW_HOSPITALREVIEW_FLOATINGALARM = 'view_hospitalreview_floatingalarm',
  CLICK_HOSPITALREVIEW_FLOATINGALARM = 'click_hospitalreview_floatingalarm',
  CLICK_HOSPITALREVIEW_SCRAP = 'click_hospitalreview_scrap',
  CLICK_HOSPITALREVIEW_UNSCRAP = 'click_hospitalreview_unscrap',
  CLICK_HOSPITALCOMMON_TOPMENU_TAB = 'click_hospitalcommon_topmenu_tab',
  CLICK_HOSPITALCOMMON_RESERVATION_ICON = 'click_hospitalcommon_reservation_icon',
  CLICK_HOSPITALDETAIL_TEXTBANNER = 'click_hospitaldetail_textbanner',
  CLICK_HOSPITALRESERVATION_MOREINFO = 'click_hospitalreservation_moreinfo',
  VIEW_HOSPITALRESERVATION_CURATIONITEM = 'view_hospitalreservation_curationitem',
  CLICK_HOSPITALRESERVATION_CURATIONITEM = 'click_hospitalreservation_curationitem',
  VIEW_HOSPITALRESERVATION_CURATIONLIST = 'view_hospitalreservation_curationlist',
  CLICK_HOSPITALRESERVATION_CURATIONLIST_HOSPITAL = 'click_hospitalreservation_curationlist_hospital',
  VIEW_HOSPITALRESERVATION_RECENTLIST = 'view_hospitalreservation_recentlist',
  VIEW_HOSPITAL_SCRAPLIST = 'view_hospital_scraplist',
}

export const HospitalEventVersionMap = {
  [HospitalEvent.CLICK_HOSPITALHOME_ONBOARDING_BUTTON]: '1.0.0',
  [HospitalEvent.VIEW_HOSPITALHOME]: '1.0.0',
  [HospitalEvent.VIEW_HOSPITALHOME_SEARCHRESULT]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALHOME_CATEGORY_BUTTON]: '1.0.0',
  [HospitalEvent.VIEW_HOSPITALSYMPTOM_CATEGORY]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALSYMPTOM_CATEGORYSYMPTOM]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALSYMPTOM_CATEGORYDETAIL_SYMPTOM]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALSYMPTOM_CATEGORYDETAIL_TOTALREVIEW]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALSYMPTOM_CATEGORYREVIEW]: '1.0.0',
  [HospitalEvent.VIEW_HOSPITALSYMPTOM_SEARCHRESULT]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALSYMPTOM_SEARCHRESULT_REVIEW]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_COMMON_REVIEWSCRAP]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_COMMON_REVIEWUNSCRAP]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_COMMON_REVIEWLIKE]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_COMMON_REVIEWUNLIKE]: '1.0.0',
  [HospitalEvent.VIEW_HOSPITALREVIEW_COMMON_REVIEWLIST]: '1.0.0',
  [HospitalEvent.VIEW_HOSPITALREVIEW_DETAIL]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_DETAIL_HOSPITAL]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_DETAIL_POPULARREVIEW]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_DETAIL_RELATEDHOSPITAL]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_DETAIL_RECOMMENDPRODUCT]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_DETAIL_RECOMMENDPRODUCT_ANOTHER]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALRESERVATION_TAB_HOSPITAL]: '1.0.0',
  [HospitalEvent.VIEW_HOSPITALRESERVATION_TAB]: '1.0.0',
  [HospitalEvent.SEARCH_HOSPITALRESERVATION_TAB_KEYWORD]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALRESERVATION_TAB_SEARCHRESULT_HOSPITAL]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALDETAIL_CALL]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALDETAIL_RESERVATION_BUTTON]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALDETAIL_SCRAP]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALDETAIL_UNSCRAP]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALDETAIL_REVIEWWRITE_BUTTON]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALDETAIL_REVIEWDETAIL]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALDETAIL_TOTALREVIEW]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALDETAIL_REVIEWLIST_REVIEW]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_WRITE_CERTIFICATIONSUBMIT]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_WRITE_DISEASEADD]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_WRITE_REVIEWWRITECOMPLETED]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALRESERVATION_PETSELECT_NEXT]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALRESERVATION_DATESELECT_NEXT]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALRESERVATION_DETAILSELECT_NEXT]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALRESERVATION_REQUESTWRITE_NEXT]: '1.0.0',
  [HospitalEvent.COMPLETE_HOSPITALRESERVATION]: '1.0.0',
  [HospitalEvent.COMPLETE_HOSPITALADMIN_CONFIRM]: '1.0.0',
  [HospitalEvent.COMPLETE_HOSPITALADMIN_VISIT]: '1.0.0',
  [HospitalEvent.VIEW_HOSPITALREVIEW_DETAIL_RECOMMENDPRODUCT]: '1.0.0',
  [HospitalEvent.VIEW_HOSPITALDETAIL_REVIEWTAB]: '1.0.0',
  [HospitalEvent.VIEW_HOSPITALDETAIL]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALRESERVATION_RECENTINFO]: '1.0.0',
  [HospitalEvent.VIEW_HOSPITALRESERVATION]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALRESERVATION_PETSELECT]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALRESERVATION_PETADD]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALRESERVATION_TYPESELECT]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALRESERVATION_OPTIONSELECT]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALRESERVATION_DATESELECT]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALRESERVATION_TIMESELECT]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALRESERVATION_RESERVATION_BUTTON]: '1.0.0',
  [HospitalEvent.COMPLETE_HOSPITALRESERVATION]: '1.0.0',
  [HospitalEvent.VIEW_RESERVATIONLIST]: '1.0.0',
  [HospitalEvent.CLICK_RESERVATIONLIST_AGAIN]: '1.0.0',
  [HospitalEvent.CLICK_RESERVATIONLIST_REVIEWWRITE]: '1.0.0',
  [HospitalEvent.CLICK_RESERVATIONLIST_QNA]: '1.0.0',
  [HospitalEvent.VIEW_RESERVATIONDETAIL]: '1.0.0',
  [HospitalEvent.CLICK_RESERVATIONDETAIL_AGAIN]: '1.0.0',
  [HospitalEvent.CLICK_RESERVATIONDETAIL_CANCEL]: '1.0.0',
  [HospitalEvent.CLICK_RESERVATIONDETAIL_MODIFY]: '1.0.0',
  [HospitalEvent.COMPLETE_HOSPITALRESERVATION_MODIFY]: '1.0.0',
  [HospitalEvent.VIEW_HOSPITALTAB_SHORTCUT]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALTAB_SHORTCUT]: '1.0.0',
  [HospitalEvent.VIEW_HOSPITALMAP]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALMAP_RECOMMFILTER]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALMAP_SEARCHAGAIN]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALMAP_ICON]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALMAP_LIST]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALMAP_LIST_RESERVATIONFILTER]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALMAP_LIST_HOSPITALTIMEFILTER]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALMAP_LIST_SCRAP]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALMAP_CURRENT]: '1.0.0',
  [HospitalEvent.VIEW_RESERVATION_POPUP_LIMIT]: '1.0.0',
  [HospitalEvent.CLICK_RESERVATION_POPUP_LIMIT]: '1.0.0',
  [HospitalEvent.VIEW_HOSPITALREVIEW_TAB]: '1.0.0',
  [HospitalEvent.VIEW_HOSPITALREVIEW_RESERVATIONBANNER]: '1.0.0',
  [HospitalEvent.VIEW_HOSPITALREVIEW_RESERVATIONLIST]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_LIST_HOSPITAL]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_HOSPITAL_TOTAL_BUTTON]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_AHEADBANNER]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_RESERVATIONLIST_HOSPITAL]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_AHEADLIST]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_RECENTREVIEW_REVIEW]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_RESERVATIONBANNER]: '1.0.0',
  [HospitalEvent.VIEW_HOSPITALREVIEW_RESERVATIONPOPUP]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_RESERVATIONPOPUP]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_RESERVATIONLIST_MORE]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_RECENTREVIEW_HOSPITAL]: '1.0.0',
  [HospitalEvent.VIEW_HOSPITALREVIEW_FLOATINGALARM]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_FLOATINGALARM]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_SCRAP]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALREVIEW_UNSCRAP]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALCOMMON_TOPMENU_TAB]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALCOMMON_RESERVATION_ICON]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALDETAIL_TEXTBANNER]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALRESERVATION_MOREINFO]: '1.0.0',
  [HospitalEvent.VIEW_HOSPITALRESERVATION_CURATIONITEM]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALRESERVATION_CURATIONITEM]: '1.0.0',
  [HospitalEvent.VIEW_HOSPITALRESERVATION_CURATIONLIST]: '1.0.0',
  [HospitalEvent.CLICK_HOSPITALRESERVATION_CURATIONLIST_HOSPITAL]: '1.0.0',
  [HospitalEvent.VIEW_HOSPITALRESERVATION_RECENTLIST]: '1.0.0',
  [HospitalEvent.VIEW_HOSPITAL_SCRAPLIST]: '1.0.0',
};

export const HospitalEventProperties = {
  AVAILABLE_PEOPLE_CNT: 'available_people_cnt',
  AVAILABLE_TIME_CNT: 'available_time_cnt',
  BADGE_TEXT: 'badge_text',
  CATEGORY_IDS: 'category_ids',
  CATEGORY_NAMES: 'category_names',
  CONFIRMED_RESERVATION_DATE: 'confirmed_reservation_date',
  HOSPITAL_HASHTAG_KEYWORD: 'hospital_hashtag_keyword',
  HOSPITAL_REVIEW_ID: 'hospital_review_id',
  HOSPITAL_REVIEW_PET_ID: 'hospital_review_pet_id',
  HOSPITAL_REVIEW_PET_TYPE: 'hospital_review_pet_type',
  HOSPITAL_REVIEW_TYPE: 'hospital_review_type',
  HOSPITAL_TOPMENU_NAME: 'hospital_topmenu_name',
  IS_ALLIANCE: 'is_alliance',
  IS_HASHTAG: 'is_hashtag',
  IS_INTOPET: 'is_intopet',
  IS_DATETIME_MODIFIED: 'is_datetime_modified',
  IS_RESERVATION_AVAILABLE: 'is_reservation_available',
  IS_RESERVATION_OPEN: 'is_reservation_open',
  LIKE_LOCATION_HOSPITAL: 'like_location_hospital',
  LOCATION_ID: 'location_id',
  LOCATION_NAME: 'location_name',
  LOCATION_TYPE: 'location_type',
  LIST_INDEX: 'list_index',
  MEMO_LENGTH: 'memo_length',
  PET_ID: 'pet_id',
  PET_NAME: 'pet_name',
  PET_CNT: 'pet_cnt',
  PET_TYPE_SEARCH: 'pet_type_search',
  PET_TYPE: 'pet_type',
  PET_TYPE_MALL: 'pet_type_mall',
  PLATFORM_DETAIL: 'platform_detail',
  PRODUCT_DISCOUNT_RATE: 'product_discount_rate',
  PRODUCT_ID: 'product_id',
  PRODUCT_IDS: 'product_ids',
  PRODUCT_NAMES: 'product_names',
  PRODUCT_IS_IN_STOCK: 'product_is_in_stock',
  PRODUCT_NAME: 'product_name',
  PRODUCT_PRICE: 'product_price',
  PRODUCT_PURCHASE_PRICE: 'product_purchase_price',
  PRODUCT_REVIEW_AVGSCORE: 'product_review_avgscore',
  PRODUCT_REVIEW_TOTAL_COUNT: 'product_review_total_count',
  RESERVATION_CNT: 'reservation_cnt',
  RESERVATION_ID: 'reservation_id',
  RESERVATION_STATUS: 'reservation_status',
  RESERVATION_DATE: 'reservation_date',
  RESERVATION_TIME: 'reservation_time',
  REQUEST_REUSE: 'request_reuse',
  RESERVATION_SERVICE_TYPE: 'reservation_service_type',
  RESERVATION_OPTION_TYPE: 'reservation_option_type',
  OPTION_TAG: 'option_tag',
  REVIEWLIST_LOCATION: 'reviewlist_location',
  SCRAP_LOCATION: 'scrap_location',
  SEARCH_KEYWORD: 'search_keyword',
  SEARCH_TYPE: 'search_type',
  SEARCH_LOCATION_HOSPITAL: 'search_location_hospital',
  SEARCH_LOCATION_SYMPTOM: 'search_location_symptom',
  SERVICE: 'service',
  SYMPTOM_2DEPTH_NAME: 'symptom_2depth_name',
  SYMPTOM_NAME: 'symptom_name',
  IS_TIME_SALE: 'is_timesale',
  EVENT_VERSION: 'event_version',
  BANNER_INDEX: 'banner_index',
  BANNER_ITEM_ID: 'banner_item_id',
  BANNER_ITEM_NAME: 'banner_item_name',
  SECTION_TITLE: 'section_title',
  RECOMMENDFILTER_NAME: 'recommfilter_name',
  RECOMMENDFILTER_LIST: 'recommfilter_list',
  LISTFILTER_NAME: 'listfilter_name',
  LOCATIONFILTER_NAME: 'locfilter_name',
  CHECK_BOX: 'check_box',
  LOCATION: 'location',
  STEP: 'step',
  SECTION_TYPE: 'section_type',
  IS_DIRECT_RESULT: 'is_direct_result',
  PET_TYPE_FILTER: 'pet_type_filter',
  SORT_BY: 'sort_by',
  PHOTO_ONLY: 'photo_only',
  TAB_NAME: 'tab_name',
  BUTTON_TEXT: 'button_text',
  SHORTCUT_TYPE: 'shortcut_type',
  PATH_PAGE: 'path_page',
  PATH_LOCATION: 'path_location',
  QUICKMENU_TYPE: 'quickmenu_type',
  ALARM_TYPE: 'alarm_type',
  IS_REDUCED: 'is_reduced',
  POPUP_TYPE: 'popup_type',
  POPUP_TEXT: 'popup_text',
  POPUP_LOCATION: 'popup_location',
  SPECIALTY_FILTER: 'specialty_filter',
  TIME_FILTER: 'time_filter',
  SERVICE_FILTER: 'service_filter',
  IS_SCRAP: 'is_scrap',
  HAVE_RECENTINFO: 'have_recentinfo',
  HOME_TYPE: 'home_type',
  LOCATION_DETAIL: 'location_detail',
  LOCATION_AGREE: 'location_agree',
  EXTRA_IMAGE_INCLUDED: 'extraimage_included',
  LABEL_NAMES: 'label_names',
  BANNER_TEXT: 'banner_text',
  FLOATING_TEXT: 'floating_text',
  HOSPITAL_REVIEW_TOTAL_COUNT: 'hospital_review_total_count',
  HOSPITAL_REVIEW_AVG_SCORE: 'hospital_review_avgscore',
  HOSPITAL_RESERVATION_TOTAL_COUNT: 'hospital_reservation_total_count',
  IS_OPEN: 'is_open',
  TAG_NAMES: 'tag_names',
  DISTANCE: 'distance',
  CURATION_TOTAL_NAMES: 'curation_total_names',
  CURATION_TOTAL_COUNT: 'curation_total_count',
  CURATION_NAME: 'curation_name',
  CHIP_NAME: 'chip_name',
  IS_DEFAULT: 'is_default',
  IS_NO_RESULT: 'is_noresult',
  IS_OPEN_CHECKED: 'is_open_checked',
  IS_RESERVATION_CHECKED: 'is_reservation_checked',
} as const;

export type HospitalEventPropertiesType = typeof HospitalEventProperties | {};
