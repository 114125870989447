import { PortalTarget } from '@/components/modal/BaseModal';

const Portal = () => {
  return (
    <>
      {Object.values(PortalTarget).map((value) => {
        return <div key={value} id={value} />;
      })}
    </>
  );
};

export default Portal;
