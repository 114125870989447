import { useCallback } from 'react';
import useEventTrackingStore from '@/store/useEventTrackingStore';
import { getPlatform } from '@/utils/deviceUtils';
import { HOSPITAL_REVIEW_TYPE, LOCATION_TYPE, PetType, Service } from '@/utils/tracker/constants';
import { HospitalEvent, HospitalEventProperties, SECTION_TYPE } from '@/utils/tracker/event/hospital';

// locationType은 병원 고정으로 각 메소드에 추가되어 있음
interface TrackingHospitalDetailParams {
  locationId: number; // 병원 id
  locationName: string; // 병원명
  isAlliance: boolean; // 제휴 병원 여부
  isReservationAvailable: boolean; // 예약 가능 여부
}

interface TrackingClickHospitalDetailButtonParams
  extends Omit<TrackingHospitalDetailParams, 'isReservationAvailable'> {}

interface TrackingViewHospitalDetailParams extends TrackingHospitalDetailParams {
  isIntopet: boolean;
  isReservationOpen: boolean; // 예약하기 버튼의 활성화 여부 (병원 예약 프로그램에서 모든 진료실의 모든 요일을 예약불가로 설정하여 실제로 모바일 예약이 불가한 병원은 False)
  extraImageIncluded?: boolean; // 병원 상세 페이지에 추가 상세 이미지 포함되어 있는지 여부
  pathPage: string | string[] | undefined; // 직전 유입 페이지 (url에서 https://hospital.fitpetmall.com 뒷부분 일부 추출, 숫자로 개별화되는 영역은 제외)
  pathLocation?: string; // 직전 유입 영역 (사전 정의한 영역 한정, 사전정의 없을 시 미전송)
  labelNames: string[]; // 병원 상세페이지 라벨 있을 시 array 형태로 전송
  bannerText: string; // 병원 프로모션 배너 텍스트
  floatingText: string; // 예약하기 버튼 위 플로팅 텍스트가 있는 경우 해당 텍스트
  hospitalReviewTotalCount: number; // 누적 리뷰 개수
  hospitalReviewAvgscore: number; // 누적 리뷰 평점
  hospitalReservationTotalCount: number; // 누적 예약 수
}

interface TrackingClickHospitalDetailCallParams extends TrackingHospitalDetailParams {}

interface TrackingClickHospitalDetailReservationButtonParams extends TrackingClickHospitalDetailButtonParams {}

interface TrackingClickHospitalDetailScrapParams extends TrackingClickHospitalDetailButtonParams {}

interface TrackingClickHospitalDetailReviewWriteButtonParams extends TrackingClickHospitalDetailButtonParams {}

interface TrackingClickHospitalDetailReviewDetailParams extends TrackingClickHospitalDetailButtonParams {
  hospitalReviewId: number; // 병원 리뷰 id
  hospitalReviewType: keyof typeof HOSPITAL_REVIEW_TYPE; // prescrip: 진료인증, receipt: 방문인증, normal: 간편리뷰
  hospitalReviewPetType: PetType; // 리뷰의 작성자의 반려동물 타입
}

interface TrackingClickHospitalDetailReviewListReviewParams extends TrackingClickHospitalDetailButtonParams {
  hospitalReviewId: number; // 병원 리뷰 id
  hospitalReviewType: keyof typeof HOSPITAL_REVIEW_TYPE; // prescrip: 진료인증, receipt: 방문인증, normal: 간편리뷰
  locationId: number; // 병원 id
  hospitalReviewPetType: PetType; // 리뷰의 작성자의 반려동물 타입
}

interface TrackingHospitalReservationBannerParams {
  bannerIndex: number;
  bannerItemId: number;
  bannerItemName: string;
  eventVersion: string;
  sectionTitle: string;
}

interface TrackingClickHospitalReservationHomeBannerParams extends TrackingHospitalReservationBannerParams {}

interface TrackingViewHospitalReservationHomeBannerParams extends TrackingHospitalReservationBannerParams {}

interface TrackingClickHospitalReservationHomeMiddleBannerParams extends TrackingHospitalReservationBannerParams {}

interface TrackingViewHospitalReservationHomeMiddleBannerParams extends TrackingHospitalReservationBannerParams {}

interface TrackingViewReservationPopupLimitParams {
  popupType: string;
  popupLocation: string;
}

interface TrackingClickReservationPopupLimitParams {
  buttonText: string;
  popupType: string;
  popupLocation: string;
}

interface TrackingClickHospitalDetailTextBannerParams {
  locationId: number;
  locationName: string;
  bannerText: string;
}

export enum EventTrackerPopupType {
  PHONE = 'phone',
  NAME_PHONE = 'name_phone',
  MULTIPLE_CANCEL = 'multiple_cancel',
  MULTIPLE_RESERVATION = 'multiple_reservation',
}

export enum EventTrackerPathLocation {
  HOSPITAL_DETAIL = 'hospital_detail',
  HOSPITAL_SHORTCUT = 'hospital_shortcut',
  RESERVATION_LIST = 'reservation_list',
  RESERVATION_DETAIL = 'reservation_detail',
  REVIEW_RESERVATION = 'review_reservation',
  REVIEW_HOSPITAL = 'review_hospital',
}

const useEventTrackerHospital = () => {
  const { tracker } = useEventTrackingStore();

  // 병원 상세 페이지 조회
  const trackingViewHospitalDetail = useCallback(
    ({
      locationId,
      locationName,
      isAlliance,
      isIntopet,
      isReservationAvailable,
      isReservationOpen,
      extraImageIncluded,
      pathPage,
      pathLocation,
      labelNames,
      bannerText = '',
      floatingText,
      hospitalReviewTotalCount,
      hospitalReviewAvgscore,
      hospitalReservationTotalCount,
    }: TrackingViewHospitalDetailParams) => {
      tracker.sendHospitalEvent(HospitalEvent.VIEW_HOSPITALDETAIL, {
        [HospitalEventProperties.LOCATION_TYPE]: LOCATION_TYPE.HOSPITAL,
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.LOCATION_NAME]: locationName,
        [HospitalEventProperties.IS_ALLIANCE]: isAlliance,
        [HospitalEventProperties.IS_INTOPET]: isIntopet,
        [HospitalEventProperties.IS_RESERVATION_AVAILABLE]: isReservationAvailable,
        [HospitalEventProperties.IS_RESERVATION_OPEN]: isReservationOpen,
        [HospitalEventProperties.EXTRA_IMAGE_INCLUDED]: extraImageIncluded,
        [HospitalEventProperties.PATH_PAGE]: pathPage,
        ...(pathLocation && { [HospitalEventProperties.PATH_LOCATION]: pathLocation }),
        [HospitalEventProperties.LABEL_NAMES]: labelNames,
        [HospitalEventProperties.BANNER_TEXT]: bannerText,
        [HospitalEventProperties.FLOATING_TEXT]: floatingText,
        [HospitalEventProperties.HOSPITAL_REVIEW_TOTAL_COUNT]: hospitalReviewTotalCount,
        [HospitalEventProperties.HOSPITAL_REVIEW_AVG_SCORE]: hospitalReviewAvgscore,
        [HospitalEventProperties.HOSPITAL_RESERVATION_TOTAL_COUNT]: hospitalReservationTotalCount,
      });
    },
    [tracker],
  );

  // 병원 상세 페이지에서 전화하기 버튼 클릭
  const trackingClickHospitalDetailCall = useCallback(
    ({ locationId, locationName, isAlliance, isReservationAvailable }: TrackingClickHospitalDetailCallParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALDETAIL_CALL, {
        [HospitalEventProperties.LOCATION_TYPE]: LOCATION_TYPE.HOSPITAL,
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.LOCATION_NAME]: locationName,
        [HospitalEventProperties.IS_ALLIANCE]: isAlliance,
        [HospitalEventProperties.IS_RESERVATION_AVAILABLE]: isReservationAvailable,
      });
    },
    [tracker],
  );

  // 병원 상세 페이지에서 예약하기 버튼 클릭
  const trackingClickHospitalDetailReservationButton = useCallback(
    ({ locationId, locationName, isAlliance }: TrackingClickHospitalDetailReservationButtonParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALDETAIL_RESERVATION_BUTTON, {
        [HospitalEventProperties.LOCATION_TYPE]: LOCATION_TYPE.HOSPITAL,
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.LOCATION_NAME]: locationName,
        [HospitalEventProperties.IS_ALLIANCE]: isAlliance,
      });
    },
    [tracker],
  );

  // 병원 상세 페이지에서 스크랩 버튼 클릭
  const trackingClickHospitalDetailScrap = useCallback(
    ({ locationId, locationName, isAlliance }: TrackingClickHospitalDetailScrapParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALDETAIL_SCRAP, {
        [HospitalEventProperties.LOCATION_TYPE]: LOCATION_TYPE.HOSPITAL,
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.LOCATION_NAME]: locationName,
        [HospitalEventProperties.IS_ALLIANCE]: isAlliance,
      });
    },
    [tracker],
  );

  // 병원 상세 페이지에서 스크랩 해제하기
  const trackingClickHospitalDetailUnScrap = useCallback(
    ({ locationId, locationName, isAlliance }: TrackingClickHospitalDetailScrapParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALDETAIL_UNSCRAP, {
        [HospitalEventProperties.LOCATION_TYPE]: LOCATION_TYPE.HOSPITAL,
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.LOCATION_NAME]: locationName,
        [HospitalEventProperties.IS_ALLIANCE]: isAlliance,
      });
    },
    [tracker],
  );

  // 리뷰 작성하기 버튼 Tap
  const trackingClickHospitalDetailReviewWriteButton = useCallback(
    ({ locationId, locationName, isAlliance }: TrackingClickHospitalDetailReviewWriteButtonParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALDETAIL_REVIEWWRITE_BUTTON, {
        [HospitalEventProperties.LOCATION_TYPE]: LOCATION_TYPE.HOSPITAL,
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.LOCATION_NAME]: locationName,
        [HospitalEventProperties.IS_ALLIANCE]: isAlliance,
      });
    },
    [tracker],
  );

  // 리뷰 상세 보기 클릭
  const trackingClickHospitalDetailReviewDetail = useCallback(
    ({
      locationId,
      locationName,
      isAlliance,
      hospitalReviewId,
      hospitalReviewType,
      hospitalReviewPetType,
    }: TrackingClickHospitalDetailReviewDetailParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALDETAIL_REVIEWDETAIL, {
        [HospitalEventProperties.LOCATION_NAME]: locationName,
        [HospitalEventProperties.IS_ALLIANCE]: isAlliance,
        [HospitalEventProperties.HOSPITAL_REVIEW_ID]: hospitalReviewId,
        [HospitalEventProperties.HOSPITAL_REVIEW_TYPE]: hospitalReviewType,
        [HospitalEventProperties.LOCATION_TYPE]: LOCATION_TYPE.HOSPITAL,
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.HOSPITAL_REVIEW_PET_TYPE]: hospitalReviewPetType,
      });
    },
    [tracker],
  );

  // 리뷰 전체보기 버튼 클릭
  const trackingClickHospitalDetailTotalReview = useCallback(() => {
    tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALDETAIL_TOTALREVIEW, {});
  }, [tracker]);

  // 병원 상세 리뷰 리스트 페이지 리뷰 클릭
  const trackingClickHospitalDetailReviewListReview = useCallback(
    ({
      locationName,
      isAlliance,
      hospitalReviewId,
      hospitalReviewType,
      locationId,
      hospitalReviewPetType,
    }: TrackingClickHospitalDetailReviewListReviewParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALDETAIL_REVIEWLIST_REVIEW, {
        [HospitalEventProperties.LOCATION_NAME]: locationName,
        [HospitalEventProperties.IS_ALLIANCE]: isAlliance,
        [HospitalEventProperties.HOSPITAL_REVIEW_ID]: hospitalReviewId,
        [HospitalEventProperties.HOSPITAL_REVIEW_TYPE]: hospitalReviewType,
        [HospitalEventProperties.LOCATION_TYPE]: LOCATION_TYPE.HOSPITAL,
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.HOSPITAL_REVIEW_PET_TYPE]: hospitalReviewPetType,
      });
    },
    [tracker],
  );

  const trackingViewHospitalDetailReviewTab = useCallback(() => {
    tracker.sendHospitalEvent(HospitalEvent.VIEW_HOSPITALDETAIL_REVIEWTAB, {});
  }, [tracker]);

  // 병원 리스트 이벤트 배너 클릭
  const trackingClickHospitalReservationHomeBanner = useCallback(
    ({
      bannerIndex,
      eventVersion,
      sectionTitle,
      bannerItemId,
      bannerItemName,
    }: TrackingClickHospitalReservationHomeBannerParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK__HOSPITALRESERVATION_HOME_BANNER, {
        [HospitalEventProperties.BANNER_INDEX]: bannerIndex,
        [HospitalEventProperties.EVENT_VERSION]: eventVersion,
        [HospitalEventProperties.SECTION_TITLE]: sectionTitle,
        [HospitalEventProperties.BANNER_ITEM_ID]: bannerItemId,
        [HospitalEventProperties.BANNER_ITEM_NAME]: bannerItemName,
        [HospitalEventProperties.SECTION_TYPE]: SECTION_TYPE.MAIN_BANNER,
      });
    },
    [tracker],
  );

  // 병원 리스트 이벤트 배너 조회
  const trackingViewHospitalReservationHomeBanner = useCallback(
    ({
      bannerIndex,
      eventVersion,
      sectionTitle,
      bannerItemId,
      bannerItemName,
    }: TrackingViewHospitalReservationHomeBannerParams) => {
      tracker.sendHospitalEvent(HospitalEvent.VIEW__HOSPITALRESERVATION_HOME_BANNER, {
        [HospitalEventProperties.BANNER_INDEX]: bannerIndex,
        [HospitalEventProperties.EVENT_VERSION]: eventVersion,
        [HospitalEventProperties.SECTION_TITLE]: sectionTitle,
        [HospitalEventProperties.BANNER_ITEM_ID]: bannerItemId,
        [HospitalEventProperties.BANNER_ITEM_NAME]: bannerItemName,
        [HospitalEventProperties.SECTION_TYPE]: SECTION_TYPE.MAIN_BANNER,
      });
    },
    [tracker],
  );

  // 병원 리스트 미들 이벤트 배너 클릭
  const trackingClickHospitalReservationHomeMiddleBanner = useCallback(
    ({
      bannerIndex,
      eventVersion,
      sectionTitle,
      bannerItemId,
      bannerItemName,
    }: TrackingClickHospitalReservationHomeMiddleBannerParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALRESERVATION_HOME_MIDDLEBANNER, {
        [HospitalEventProperties.BANNER_INDEX]: bannerIndex,
        [HospitalEventProperties.EVENT_VERSION]: eventVersion,
        [HospitalEventProperties.SECTION_TITLE]: sectionTitle,
        [HospitalEventProperties.BANNER_ITEM_ID]: bannerItemId,
        [HospitalEventProperties.BANNER_ITEM_NAME]: bannerItemName,
        [HospitalEventProperties.SECTION_TYPE]: SECTION_TYPE.MIDDLE_BANNER,
      });
    },
    [tracker],
  );

  // 병원 리스트 미들 이벤트 배너 조회
  const trackingViewHospitalReservationHomeMiddleBanner = useCallback(
    ({
      bannerIndex,
      eventVersion,
      sectionTitle,
      bannerItemId,
      bannerItemName,
    }: TrackingViewHospitalReservationHomeMiddleBannerParams) => {
      tracker.sendHospitalEvent(HospitalEvent.VIEW_HOSPITALRESERVATION_HOME_MIDDLEBANNER, {
        [HospitalEventProperties.BANNER_INDEX]: bannerIndex,
        [HospitalEventProperties.EVENT_VERSION]: eventVersion,
        [HospitalEventProperties.SECTION_TITLE]: sectionTitle,
        [HospitalEventProperties.BANNER_ITEM_ID]: bannerItemId,
        [HospitalEventProperties.BANNER_ITEM_NAME]: bannerItemName,
        [HospitalEventProperties.SECTION_TYPE]: SECTION_TYPE.MIDDLE_BANNER,
      });
    },
    [tracker],
  );

  // 병원 예약 시도 시 개인정보 부족 또는 예약 제한으로 인한 팝업 조회
  const trackingViewReservationPopupLimit = useCallback(
    ({ popupType, popupLocation }: TrackingViewReservationPopupLimitParams) => {
      tracker.sendHospitalEvent(HospitalEvent.VIEW_RESERVATION_POPUP_LIMIT, {
        [HospitalEventProperties.POPUP_TYPE]: popupType,
        [HospitalEventProperties.POPUP_LOCATION]: popupLocation,
        [HospitalEventProperties.SERVICE]: Service.HOSPITAL,
        [HospitalEventProperties.PLATFORM_DETAIL]: getPlatform(),
      });
    },
    [tracker],
  );

  // 개인정보 부족 또는 예약 제한으로 인한 팝업 조회 이후 버튼 클릭
  const trackingClickReservationPopupLimit = useCallback(
    ({ buttonText, popupType, popupLocation }: TrackingClickReservationPopupLimitParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_RESERVATION_POPUP_LIMIT, {
        [HospitalEventProperties.BUTTON_TEXT]: buttonText,
        [HospitalEventProperties.POPUP_TYPE]: popupType,
        [HospitalEventProperties.POPUP_LOCATION]: popupLocation,
        [HospitalEventProperties.SERVICE]: Service.HOSPITAL,
        [HospitalEventProperties.PLATFORM_DETAIL]: getPlatform(),
      });
    },
    [tracker],
  );

  // 병원 상세페이지 텍스트배너 클릭
  const trackingClickHospitalDetailTextBanner = useCallback(
    ({ locationId, locationName, bannerText }: TrackingClickHospitalDetailTextBannerParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALDETAIL_TEXTBANNER, {
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.LOCATION_NAME]: locationName,
        [HospitalEventProperties.BANNER_TEXT]: bannerText,
        [HospitalEventProperties.SERVICE]: Service.HOSPITAL,
      });
    },
    [tracker],
  );

  return {
    trackingViewHospitalDetail,
    trackingClickHospitalDetailCall,
    trackingClickHospitalDetailReservationButton,
    trackingClickHospitalDetailScrap,
    trackingClickHospitalDetailUnScrap,
    trackingClickHospitalDetailReviewWriteButton,
    trackingClickHospitalDetailReviewDetail,
    trackingClickHospitalDetailTotalReview,
    trackingClickHospitalDetailReviewListReview,
    trackingViewHospitalDetailReviewTab,
    trackingClickHospitalReservationHomeBanner,
    trackingViewHospitalReservationHomeBanner,
    trackingClickHospitalReservationHomeMiddleBanner,
    trackingViewHospitalReservationHomeMiddleBanner,
    trackingViewReservationPopupLimit,
    trackingClickReservationPopupLimit,
    trackingClickHospitalDetailTextBanner,
  };
};

export default useEventTrackerHospital;
