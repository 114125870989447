import create from 'zustand';

export interface HospitalScrapStore {
  hospitalScrapSet: Set<number>;
  setHospitalScrapSet: (list: Set<number>) => void;
  addHospitalScrap: (id: number) => void;
  deleteHospitalScrap: (id: number) => void;
  checkHospitalScrap: (id: number) => boolean;
  clearHospitalScrap: () => void;
}

const useHospitalScrapStore = create<HospitalScrapStore>((set, get) => ({
  hospitalScrapSet: new Set(),
  setHospitalScrapSet: (list: Set<number>) => {
    set(() => ({
      hospitalScrapSet: list,
    }));
  },
  addHospitalScrap: (id: number) => {
    const temp = new Set(get().hospitalScrapSet);
    temp.add(id);
    set(() => ({
      hospitalScrapSet: temp,
    }));
  },
  deleteHospitalScrap: (id: number) => {
    const temp = new Set(get().hospitalScrapSet);
    temp.delete(id);
    set(() => ({
      hospitalScrapSet: temp,
    }));
  },
  checkHospitalScrap: (id: number) => {
    return get().hospitalScrapSet.has(id);
  },
  clearHospitalScrap: () => {
    set(() => ({ hospitalScrapSet: new Set() }));
  },
}));

export default useHospitalScrapStore;
