export const MAX_REVIEW_IMAGE_COUNT = 5;
export const MAX_RECEIPT_FILE_SIZE_BYTES = 10 * 1024 * 1024;
export const MAX_REVIEW_FILE_SIZE_BYTES = 10 * 1024 * 1024;
export const BASIC_NO_DOG_IMAGE_URL = '/icons/ic_default_dog.svg';
export const BASIC_NO_CAT_IMAGE_URL = '/icons/ic_default_cat.svg';

export const IS_FIRST_DATE = new Date().getDate() === 1;

// 리뷰 리워드 금액
export const PREVIOUS_REVIEW_REWARD_PRICE = '10,000'; // 3월 20일 이전에 작성한 리뷰 리워드 금액
export const CURRENT_REVIEW_REWARD_PRICE = '1,000'; // 3월 20일 이후에 작성한 리뷰 리워드 금액

// 예약 방문 시, 최대 리워드 금액
export const MAX_RESERVATION_REWARD_PRICE = '20,000';

// 핏펫 예약 유의사항
export const fitpetReservationNote = [
  '예약 시 선택한 담당 수의사와 예약 일시가 동의 없이 변경될 수 있습니다.',
  '예약 날짜를 변경할 시 해당 요일에 가능한 담당자가 없을 경우 다른 담당자로 변경될 수 있습니다.',
  '해당 병원은 예약 대기 상태에서 예약 변경/취소가 가능합니다.',
  '예약이 확정된 이후 예약 변경/취소를 원할 시 해당 병원에 전화로 문의 바랍니다.',
];

// 인투펫 예약 유의사항
export const intopetReservationNote = [
  '하루 3회 이상 예약 취소 시 해당 병원을 포함한 일부 병원에 당일 하루 동안 신규 예약이 제한됩니다.',
  '예약한 반려동물과 내원한 반려동물이 다를 경우 진료가 거부될 수도 있습니다.',
  '응급 진료가 있는 경우, 예약 진료보다 우선 진료될 수 있습니다.',
  '병원 운영상 사용자가 예약한 예약 일정이 임의 변경될 수 있습니다.',
  '일 3회 이상 예약 취소는 24시간동안 예약이 제한됩니다.',
  '해당 병원의 예약 취소는 방문 2시간 전까지 가능합니다.',
];

// 인투링크 하루 최대 예약 취소 카운트
export const INTOLINK_MAX_CANCEL_COUNT = 3;

// 인투링크 하루 최대 예약 진행 가능 카운트
export const INTOLINK_MAX_ONGOING_RESERVATION_COUNT = 5;

// 프로모션 이벤트 중인 병원 아이디
export const PROMOTION_HOSPITAL_IDS = {
  SD_HOSPITAL_ID: 5796,
};

// 프로모션 이벤트 병원 리스트
export const PROMOTION_EVENT_HOSPITAL_LIST = [
  // 24시SD동물의료센터
  {
    id: PROMOTION_HOSPITAL_IDS.SD_HOSPITAL_ID,
    listPromotionText: '건강검진 20% or 모든 진료 15% 할인',
    detailPromotionText: '핏펫 고객만! 건강검진 20% or 모든 진료 15% 할인',
    tooltipText: '건강검진 20% 할인 + 적립 최대 2만원',
    tooltipTextNoReservation: '건강검진 20% 할인 + 적립 25,000원',
    isPromotion: true,
    hasRightIcon: false,
  },
];
