import IcAdd from '@/icons/ic_add.svg';
import IcArrowDown from '@/icons/ic_arrow_down.svg';
import IcArrowLeft from '@/icons/ic_arrow_left.svg';
import IcArrowRight from '@/icons/ic_arrow_right.svg';
import IcArrowUp from '@/icons/ic_arrow_up.svg';
import IcCalendar from '@/icons/ic_calendar.svg';
import IcCall from '@/icons/ic_call.svg';
import IcCategory from '@/icons/ic_category.svg';
import IcChange from '@/icons/ic_change.svg';
import IcCheck from '@/icons/ic_check.svg';
import IcChevronDown100 from '@/icons/ic_chevron_down_100.svg';
import IcChevronRightSmall from '@/icons/ic_chevron_right_small.svg';
import IcChevronUp from '@/icons/ic_chevron_up.svg';
import IcChevronUp100 from '@/icons/ic_chevron_up_100.svg';
import IcClock from '@/icons/ic_clock.svg';
import IcClockRight from '@/icons/ic_clock_right.svg';
import IcClose from '@/icons/ic_close.svg';
import IcClose100 from '@/icons/ic_close_100.svg';
import IcClose300 from '@/icons/ic_close_300.svg';
import IcCloseDefaultGray from '@/icons/ic_close_default_gray.svg';
import IcCoinFlat from '@/icons/ic_coin_flat.svg';
import IcConfirm from '@/icons/ic_confirm.svg';
import IcCopyGray from '@/icons/ic_copy_gray.svg';
import IcDelete from '@/icons/ic_delete.svg';
import IcDeleteSearch from '@/icons/ic_delete_search.svg';
import IcDot from '@/icons/ic_dot.svg';
import IcEllipseThree from '@/icons/ic_ellipse_three.svg';
import IcEtc from '@/icons/ic_etc.svg';
import IcHashTag from '@/icons/ic_hashtag.svg';
import IcHome from '@/icons/ic_home.svg';
import IcHomepage from '@/icons/ic_homepage.svg';
import IcHospital from '@/icons/ic_hospital.svg';
import IcInfo from '@/icons/ic_info.svg';
import IcInfoFilled from '@/icons/ic_info_filled.svg';
import IcLocation from '@/icons/ic_location.svg';
import IcOff from '@/icons/ic_off.svg';
import IcPin from '@/icons/ic_pin.svg';
import IcPinSmall from '@/icons/ic_pin_small.svg';
import IcPlus from '@/icons/ic_plus.svg';
import IcRadio from '@/icons/ic_radio.svg';
import IcRadioActive from '@/icons/ic_radio_active.svg';
import IcRecmmd from '@/icons/ic_recmmd.svg';
import IcReset from '@/icons/ic_reset.svg';
import IcResetBold from '@/icons/ic_reset_bold.svg';
import IcRollBack from '@/icons/ic_rollback.svg';
import IcScrap from '@/icons/ic_scrap.svg';
import IcScrapFill from '@/icons/ic_scrap_fill.svg';
import IcSearch from '@/icons/ic_search.svg';
import IcSearchBg from '@/icons/ic_search_bg.svg';
import IcShare from '@/icons/ic_share.svg';
import IcSpecialty from '@/icons/ic_specialty.svg';
import IcStar from '@/icons/ic_star.svg';
import IcTip from '@/icons/ic_tip.svg';
import IcTop from '@/icons/ic_top.svg';
import IcTrash from '@/icons/ic_trash.svg';
import IcVet from '@/icons/ic_vet.svg';
import IcCalendarImg from '@/images/calendar.svg';
import IcAheadBasic from '@/public/icons/ic_ahead_basic.svg';
import IcAheadDental from '@/public/icons/ic_ahead_dental.svg';
import IcAheadHistory from '@/public/icons/ic_ahead_history.svg';
import IcAheadMall from '@/public/icons/ic_ahead_mall.svg';
import IcChevronDown from '@/public/icons/ic_chevron_down.svg';
import IcChevronLeft from '@/public/icons/ic_chevron_left.svg';
import IcChevronRight from '@/public/icons/ic_chevron_right.svg';
import IcCopyBlue from '@/public/icons/ic_copy_blue.svg';
import IcDown from '@/public/icons/ic_down.svg';
import IcEventFlat from '@/public/icons/ic_event_flat.svg';
import IcPinFilled from '@/public/icons/ic_pin_filled.svg';
import IcPromotionFlat from '@/public/icons/ic_promotion_flat.svg';
import IcQuestionMark from '@/public/icons/ic_question_mark.svg';

/* eslint sort-keys: ["error", "asc", {"caseSensitive": true, "natural": false, "minKeys": 2}] */
const FlatIconList = {
  icAdd: IcAdd,
  icAheadBasic: IcAheadBasic,
  icAheadDental: IcAheadDental,
  icAheadHistory: IcAheadHistory,
  icAheadMall: IcAheadMall,
  icArrowDown: IcArrowDown,
  icArrowLeft: IcArrowLeft,
  icArrowRight: IcArrowRight,
  icArrowUp: IcArrowUp,
  icCalendar: IcCalendar,
  icCalendarImg: IcCalendarImg,
  icCall: IcCall,
  icCategory: IcCategory,
  icChange: IcChange,
  icCheck: IcCheck,
  icChevronDown: IcChevronDown,
  icChevronDown100: IcChevronDown100,
  icChevronLeft: IcChevronLeft,
  icChevronRight: IcChevronRight,
  icChevronRightSmall: IcChevronRightSmall,
  icChevronUp: IcChevronUp,
  icChevronUp100: IcChevronUp100,
  icClock: IcClock,
  icClockRight: IcClockRight,
  icClose: IcClose,
  icClose100: IcClose100,
  icClose300: IcClose300,
  icCloseDefaultGray: IcCloseDefaultGray,
  icCoinFlat: IcCoinFlat,
  icConfirm: IcConfirm,
  icCopyBlue: IcCopyBlue,
  icCopyGray: IcCopyGray,
  icDelete: IcDelete,
  icDeleteSearch: IcDeleteSearch,
  icDot: IcDot,
  icDown: IcDown,
  icEllipseThree: IcEllipseThree,
  icEtc: IcEtc,
  icEventFlat: IcEventFlat,
  icHashTag: IcHashTag,
  icHome: IcHome,
  icHomepage: IcHomepage,
  icHospital: IcHospital,
  icInfo: IcInfo,
  icInfoFilled: IcInfoFilled,
  icLocation: IcLocation,
  icOff: IcOff,
  icPin: IcPin,
  icPinFilled: IcPinFilled,
  icPinSmall: IcPinSmall,
  icPlus: IcPlus,
  icPromotionFlat: IcPromotionFlat,
  icQuestionMark: IcQuestionMark,
  icRadio: IcRadio,
  icRadioActive: IcRadioActive,
  icRecmmd: IcRecmmd,
  icReset: IcReset,
  icResetBold: IcResetBold,
  icRollBack: IcRollBack,
  icScrap: IcScrap,
  icScrapFill: IcScrapFill,
  icSearch: IcSearch,
  icSearchBg: IcSearchBg,
  icShare: IcShare,
  icSpecialty: IcSpecialty,
  icStar: IcStar,
  icTip: IcTip,
  icTop: IcTop,
  icTrash: IcTrash,
  icVet: IcVet,
};

export default FlatIconList;
