import { useInfiniteQuery, useQuery } from 'react-query';
import {
  GetHospitalSimpleReservationParams,
  GetLiveHospitalIdParams,
  GetRecentVisitedHospitalListParams,
  HospitalApi,
  HospitalFilterCountParams,
  HospitalListParams,
  HospitalMapListParams,
  HospitalRecommendParams,
  HospitalSearchParam,
} from '@/api/hospital';
import { SortOptionalPageable } from '@/constants/common';
import { QUERY_KEYS } from '@/constants/queryKey';

export const useGetHospitalList = (params: HospitalListParams) => {
  const result = useInfiniteQuery(
    QUERY_KEYS.HOSPITAL.LIST(params),
    async ({ pageParam = 0 }) => {
      const response = await HospitalApi.getHospitalList({
        ...params,
        pageable: { ...params.pageable, page: pageParam },
      });
      return response;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.pageable.pageNumber >= lastPage.totalPages) {
          return undefined;
        }
        return allPages.reduce((acc) => acc + 1, 0);
      },
    },
  );
  return result;
};

export const useGetHospitalFilterCount = (params: HospitalFilterCountParams) => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.LIST_FILTER_COUNT(params),
    async () => {
      const response = HospitalApi.getHospitalFilterCount(params);
      return response;
    },
    { notifyOnChangeProps: 'tracked' },
  );
  return result;
};

export const useGetHospitalFilterList = (addressCode: string) => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.FILTER_LIST(addressCode),
    async () => {
      const response = HospitalApi.getHospitalFilterList(addressCode);
      return response;
    },
    { notifyOnChangeProps: 'tracked' },
  );
  return result;
};

export const useGetHospitalSearchList = (params: HospitalSearchParam) => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.SEARCH_LIST(params),
    async () => {
      const response = HospitalApi.getHospitalSearchList(params);
      return response;
    },
    { notifyOnChangeProps: 'tracked', enabled: !!params.searchWord },
  );
  return result;
};

export const useGetHospitalRecommendList = (params: HospitalRecommendParams) => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.RECOMMEND_LIST(params),
    async () => {
      const response = HospitalApi.getRecommendHospitalList(params);
      return response;
    },
    { notifyOnChangeProps: 'tracked', enabled: params.addressCode !== '1' },
  );
  return result;
};

export const useGetHospitalScrapIdList = (enabled: boolean = true) => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.SCRAP.ID_LIST(),
    async () => {
      const response = HospitalApi.getHospitalScrapId();
      return response;
    },
    { notifyOnChangeProps: 'tracked', enabled },
  );
  return result;
};

export const useGetMapHospitalList = (params: HospitalMapListParams) => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.MAP_LIST(params),
    async () => {
      const response = await HospitalApi.getHospitalMapList(params);
      return response;
    },
    { enabled: params.currentLatitude > 0 && params.currentLongitude > 0, refetchOnWindowFocus: false },
  );
  return result;
};

export const useGetHospitalScrapList = (params: SortOptionalPageable) => {
  const result = useInfiniteQuery(
    QUERY_KEYS.HOSPITAL.SCRAP.LIST(params),
    async ({ pageParam = 0 }) => {
      const response = await HospitalApi.getHospitalScrapList({ ...params, page: pageParam });
      return response;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.pageable.pageNumber >= lastPage.totalPages) {
          return undefined;
        }
        return allPages.reduce((acc) => acc + 1, 0);
      },
      retry: false,
    },
  );
  return result;
};

export const useGetRecentVisitHospitalList = () => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.RECENT_VISIT_LIST(),
    async () => {
      const response = await HospitalApi.getRecentVisitHospitalList();
      return response;
    },
    { notifyOnChangeProps: 'tracked' },
  );
  return result;
};

export const useGetAllianceHospitalList = () => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.ALLIANCE(),
    async () => {
      const response = await HospitalApi.getAllianceHospitalList();
      return response;
    },
    { notifyOnChangeProps: 'tracked' },
  );
  return result;
};

export const useGetReviewWriteShortcut = () => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.SHORTCUT.REVIEW(),
    async () => {
      const response = await HospitalApi.getReviewWriteShortcut();
      return response;
    },
    { notifyOnChangeProps: 'tracked', refetchOnWindowFocus: 'always' },
  );
  return result;
};

export const useGetHospitalSimpleReservation = (params: GetHospitalSimpleReservationParams) => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.SIMPLE_RESERVATION_LIST(params),
    async () => {
      const response = await HospitalApi.getHospitalSimpleReservation(params);
      return response;
    },
    { notifyOnChangeProps: 'tracked', refetchOnWindowFocus: 'always' },
  );
  return result;
};

export const useGetLiveHospitalId = (params: GetLiveHospitalIdParams) => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.LIVE(params),
    async () => {
      const response = await HospitalApi.getLiveHospitalId(params);
      return response;
    },
    { notifyOnChangeProps: 'tracked', refetchOnWindowFocus: 'always' },
  );
  return result;
};

export const useGetRecentVisitedHospitalList = (params: GetRecentVisitedHospitalListParams) => {
  const result = useInfiniteQuery(
    QUERY_KEYS.HOSPITAL.RECENT_VISITED_LIST(params),
    async ({ pageParam = 0 }) => {
      const response = await HospitalApi.getRecentVisitedHospitalList({ ...params, page: pageParam });
      return response;
    },
    {
      retry: false,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.pageable.pageNumber >= lastPage.totalPages) {
          return undefined;
        }
        return allPages.reduce((acc) => acc + 1, 0);
      },
    },
  );
  return result;
};
