import dayjs from 'dayjs';
import { HospitalWorkingDay } from '@/api/hospital';
import { AvailableTimeModel } from '@/api/reservation';
import { DAY_MAP, WEEKDAY } from '@/constants/common';

export const DATE_FORMAT = {
  DATE: 'YYYY-MM-DD',
  TIME: 'HH:mm:ss',
  DATETIME: 'YYYY-MM-DD HH:mm:ss',
  DATE_WITH_DOT: 'YY.MM.DD',
  FULL_DATE_WITH_DOT: 'YYYY.MM.DD',
  FULL_DATE_WITH_DOT_TIME: 'YYYY.MM.DD (dd) A h:mm',
};

// 2023.04.21 (금) 오후 1:30
export enum WORKING_STATUS {
  OPEN = 'OPEN',
  EMPTY = 'EMPTY',
  CLOSED = 'CLOSED',
  DEFAULT = 'DEFAULT',
  TEMPORARY = 'TEMPORARY',
  HOLIDAY = 'HOLIDAY',
}

export enum WORKING_STATUS_TEXT {
  OPEN = '진료중',
  EMPTY = '영업 시간 정보 없음',
  CLOSED = '진료종료',
  EMPTY_SHORT = '정보없음',
  TEMPORARY = '임시휴무',
  HOLIDAY = '휴무',
}

export enum TIME_TYPE {
  MORNING = 'MORNING',
  AFTERNOON = 'AFTERNOON',
}

interface GetWorkingTimeParams {
  workingDay?: HospitalWorkingDay[];
  isTemporaryClose?: boolean;
}

interface GetMorningAfternoonTimeParams {
  type: keyof typeof TIME_TYPE;
  data: AvailableTimeModel[] | undefined;
  desiredDate: string;
  reservationTime?: string;
}

export const getPastAtStr = (createdAt: string, format?: string): string => {
  const dateAt = dayjs(createdAt);
  const now = dayjs();
  if (!dateAt.isValid() || now.isBefore(dateAt)) {
    return '';
  }
  const diffMinutes = now.diff(dateAt, 'minutes');
  if (diffMinutes < 60) {
    return '방금';
  }
  const diffHours = now.diff(dateAt, 'hours');
  if (diffHours < 24) {
    return `${diffHours}시간 전`;
  }
  return dateAt.format(format || DATE_FORMAT.DATE_WITH_DOT);
};

export const getPastAtStrWithoutRecent = (createdAt: string | null, format?: string): string => {
  if (!createdAt) {
    return '';
  }
  const dateAt = dayjs(createdAt);
  return dateAt.format(format || DATE_FORMAT.DATE_WITH_DOT);
};

export const getWorkingTime = ({ workingDay, isTemporaryClose }: GetWorkingTimeParams) => {
  const today = WEEKDAY[dayjs().day()];
  let workingData = {
    workingTime: WORKING_STATUS_TEXT.EMPTY as string,
    workingStatus: WORKING_STATUS.EMPTY,
    workingStatusText: WORKING_STATUS_TEXT.EMPTY,
  };

  if (!workingDay) {
    return workingData;
  }

  if (isTemporaryClose) {
    workingData = {
      workingTime: WORKING_STATUS_TEXT.TEMPORARY as string,
      workingStatus: WORKING_STATUS.TEMPORARY,
      workingStatusText: WORKING_STATUS_TEXT.TEMPORARY,
    };
    return workingData;
  }
  const { workTimeStart, workTimeEnd } = workingDay.find((openTime) => openTime.workDay === today) || {
    workTimeStart: '',
    workTimeEnd: '',
  };

  if (workingDay.length > 0 && !workTimeStart && !workTimeEnd) {
    workingData = {
      ...workingData,
      workingStatus: WORKING_STATUS.HOLIDAY,
      workingStatusText: `${DAY_MAP[today]}요일 ${WORKING_STATUS_TEXT.HOLIDAY}` as WORKING_STATUS_TEXT,
      workingTime: WORKING_STATUS_TEXT.HOLIDAY,
    };
  }

  if (workTimeStart && workTimeEnd) {
    const day = dayjs(new Date(), DATE_FORMAT.TIME);
    const todayDate = day.format(DATE_FORMAT.DATE);

    workingData = {
      ...workingData,
      workingTime: `${workTimeStart}~${workTimeEnd}`,
    };

    const startTime = `${todayDate} ${workTimeStart}`;
    const endTime = `${todayDate} ${workTimeEnd}`;

    if (dayjs(day).isAfter(dayjs(startTime)) && dayjs(day).isBefore(dayjs(endTime))) {
      workingData = {
        ...workingData,
        workingStatus: WORKING_STATUS.OPEN,
        workingStatusText: WORKING_STATUS_TEXT.OPEN,
      };
    } else {
      workingData = {
        ...workingData,
        workingStatus: WORKING_STATUS.CLOSED,
        workingStatusText: WORKING_STATUS_TEXT.CLOSED,
      };
    }
  }

  return workingData;
};

export const getMorningAfternoonTime = ({
  type,
  data,
  desiredDate,
  reservationTime,
}: GetMorningAfternoonTimeParams) => {
  const filterTimeByType = () => {
    const filteredTime = data?.filter((item) => {
      return type === TIME_TYPE.MORNING ? item.time < '12:00' : item.time >= '12:00';
    });
    return filteredTime;
  };

  const mapAvailableTime = (filteredTime: AvailableTimeModel[] | undefined) => {
    const date = new Date();
    const currentDate = dayjs(date).format('YYYY-MM-DD');
    const currentTime = dayjs(date).format('HH:mm');
    const isBeforeToday = new Date(currentDate) > new Date(desiredDate); // 오늘 이전일 때
    const isAfterToday = new Date(currentDate) < new Date(desiredDate); // 오늘 이후일 때

    const formattedReservationDate = reservationTime && dayjs(reservationTime).format('YYYY-MM-DD');
    const formattedReservationTime = reservationTime && dayjs(reservationTime).format('HH:mm');
    const isSameDate = desiredDate === formattedReservationDate;

    if (isBeforeToday) {
      return filteredTime?.map((item) => {
        const isAvailable = (isSameDate && item.time === formattedReservationTime) || false;
        return { time: item.time, isAvailable };
      });
    }

    if (isAfterToday) {
      return filteredTime?.map((item) => {
        const isAvailable = (isSameDate && item.time === formattedReservationTime) || item.isAvailable;
        return { time: item.time, isAvailable };
      });
    }

    return filteredTime?.map((item) => {
      const isAvailable =
        (isSameDate && item.time === formattedReservationTime) || (item.isAvailable && item.time > currentTime);
      return { time: item.time, isAvailable };
    });
  };

  const filteredTimeByType = filterTimeByType();
  const mappedAvailableTime = mapAvailableTime(filteredTimeByType) || [];
  return mappedAvailableTime;
};

export const convert12HourClock = (type: keyof typeof TIME_TYPE, time: string) => {
  if (type === TIME_TYPE.MORNING) {
    const hour = Number(time.substring(0, 2));
    const minute = time.substring(3, 5);
    if (hour === 0) {
      return `12:${minute}`;
    }
    return `${hour}:${minute}`;
  }

  const hour = Number(time.substring(0, 2));
  if (hour < 13) {
    return time;
  }
  const minute = time.substring(3, 5);
  const convertedHour = hour - 12;
  return `${convertedHour}:${minute}`;
};

export const convert24HourClock = (type: keyof typeof TIME_TYPE, time: string | undefined) => {
  if (!time) {
    return '';
  }

  const splitedHour = Number(time.split(':')[0]);
  const splitedMinute = time.split(':')[1];

  if (type === TIME_TYPE.MORNING) {
    if (splitedHour === 12) {
      return `00:${splitedMinute}`;
    }
    if (splitedHour < 10) {
      return `0${splitedHour}:${splitedMinute}`;
    }
    return `${splitedHour}:${splitedMinute}`;
  }

  return splitedHour === 12 ? `${splitedHour}:${splitedMinute}` : `${splitedHour + 12}:${splitedMinute}`;
};

export const getMorningOrAfternoonText = (type: keyof typeof TIME_TYPE) => {
  return type === TIME_TYPE.MORNING ? '오전' : '오후';
};

export const checkIsWithinSixMonths = (date: string) => {
  const currentDate = new Date();
  const reservationDate = new Date(date);
  const sixMonthsAgo = new Date();
  sixMonthsAgo.setMonth(currentDate.getMonth() - 6);

  if (reservationDate >= sixMonthsAgo && reservationDate <= currentDate) {
    return true;
  }
  return false;
};

export const checkIsOverpastReservationTime = (reservationTime: string) => {
  return new Date(reservationTime) < new Date();
};

export const formatReservationTime = (reservationTime: string) => {
  const formattedReservationDateValue = dayjs(reservationTime).format(DATE_FORMAT.DATE);
  const formattedReservationTimeValue = Number(dayjs(reservationTime).format('HH'));
  return { formattedReservationDateValue, formattedReservationTimeValue };
};
