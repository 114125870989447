import styled from '@emotion/styled';
import { ReactElement } from 'react';
import { Button, SINGLE_BUTTON_HEIGHT } from '@/components/common/buttons/Button';
import { GLOBAL_COLOR } from '@/styles/colors';
import { toRem } from '@/utils/commonUtils';

export interface SingleButtonProps {
  buttonType: 'primary' | 'secondary' | 'default';
  children: string | ReactElement | ReactElement[];
  onClick?: () => void;
  disabled?: boolean;
  width?: number;
  height?: number;
  className?: string;
  fontWeight?: number;
  backgroundColor?: string;
}

const SingleButton = ({
  children,
  buttonType,
  onClick,
  disabled,
  width,
  height,
  className,
  fontWeight,
  backgroundColor,
}: SingleButtonProps) => {
  return (
    <StyledButton
      buttonType={buttonType}
      onClick={onClick}
      disabled={disabled}
      width={width}
      height={height}
      className={className}
      fontWeight={fontWeight}
      backgroundColor={backgroundColor}>
      {children}
    </StyledButton>
  );
};

export default SingleButton;

const StyledButton = styled(Button)<SingleButtonProps>`
  width: ${({ width }) => (width ? `${toRem(width)}` : `100%`)};
  height: ${({ height }) => (height ? `${toRem(height)}` : `${toRem(SINGLE_BUTTON_HEIGHT)}`)};
  padding: ${toRem(14)} ${toRem(16)};
  font-size: ${toRem(16)};
  line-height: ${toRem(20)};
  text-align: center;
  letter-spacing: -0.02em;
  border-radius: ${toRem(8)};
  background-color: ${({ buttonType }) =>
    buttonType === 'secondary' || buttonType === 'default' ? GLOBAL_COLOR.WHITE : GLOBAL_COLOR.BLUE_500};
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: ${({ buttonType }) =>
    buttonType === 'secondary'
      ? `${toRem(1)} solid ${GLOBAL_COLOR.GRAY_200}`
      : buttonType === 'default' && `${toRem(1)} solid ${GLOBAL_COLOR.BLUE_500}`};

  font-weight: ${({ buttonType, fontWeight }) => {
    if (fontWeight) {
      return fontWeight;
    }
    if (buttonType === 'secondary') {
      return 500;
    }
    return 700;
  }};

  color: ${({ buttonType }) => {
    if (buttonType === 'secondary') {
      return GLOBAL_COLOR.GRAY_900;
    }
    if (buttonType === 'default') {
      return GLOBAL_COLOR.BLUE_500;
    }
    return GLOBAL_COLOR.WHITE;
  }};

  &:disabled {
    color: ${GLOBAL_COLOR.WHITE};
    background: ${GLOBAL_COLOR.GRAY_200};
    border: ${toRem(1)} solid ${GLOBAL_COLOR.GRAY_200};
    pointer-events: none;
  }
`;
