import { BASE_URL, HTTP } from './http';
import { Address } from '@/api/address';
import { getQueryParams } from '@/api/apiUtils';
import { Banner } from '@/api/banner';
import {
  DAY_TYPE,
  Device,
  Pageable,
  PAGEABLE_KEY,
  PageableObject,
  Sort,
  SortOptionalPageable,
} from '@/constants/common';
import { Filter, SORT_LIST_KEY, TIME_FILTER_KEY } from '@/constants/filter';

export enum INTO_LINK_STATUS {
  LINK = 'LINK', // isIntolink = true
  UNLINK = 'UNLINK', // isIntolink = false
  DUPLICATED = 'DUPLICATED', // isIntolink = false
}

// 운영일
export interface HospitalDetailWorkingDay {
  workDay: DAY_TYPE;
  workTimeStart: string;
  workTimeEnd: string;
}

// 병원 사진 리스트
export interface HospitalFile {
  file: string; // 사진 주소
  order: number; // 순서
}

export interface Hospital {
  id: number; // 병원 id
  name: string; // 병원명
  addr: string; // 주소
  shortAddress: string;
  tel: string; // 전화번호
  image: string;
  website: string; // 웹사이트
  isAlliance: boolean; // 제휴유무
  reviewCount: number; // 리뷰갯수
  totalScore: number;
  isEvent: boolean; // 이벤트 유무
  isWorking: boolean;
  isTemporaryClose: boolean;
  isScrap: boolean;
  distance: number; // 병원과의 거리
  expertise: string[];
  lat: number; // 위도
  lon: number; // 경도
  workingDay: HospitalWorkingDay[];
  intoLinkHospitalIdx: number | null;
  intoLinkStatus: keyof typeof INTO_LINK_STATUS;
  isReservationAvailable: boolean; // 병원 예약 가능 여부
  intoLinkHospitalWorkingStatus: boolean | null; // 인투링크 운영 상태 여부
  isReservationPossible: boolean; // 최종적인 병원 예약 가능 여부
}

export interface RecentVisitedHospital extends Hospital, GetHospitalSimpleReservationResponse {}

// 병원정보
export interface HospitalDetail extends Hospital {
  hospitalImages: HospitalFile[];
  eventTags: string[];
  scoreKeywords: string[];
  specialtyTags: string[];
  serviceTags: string[];
  holidayDay: string; // 휴무정보
  isParking: boolean; // 주차가능여부
  banner: Banner[];
  content: string; // 병원 소개
  contentImageUrl: string | null; // 병원 상세 이미지
  updatedAt: string; // 수정일
  overNight: boolean; // 야간 진료
  vetNumber: number;
  reservationCount: number;
  address?: Address;
  isTemporaryClose: boolean;
  bizCall?: string;
  intoLinkHospitalIdx: number | null;
  intoLinkStatus: keyof typeof INTO_LINK_STATUS;
}

export interface HospitalWorkingDay {
  workDay: DAY_TYPE;
  workTimeEnd: string;
  workTimeStart: string;
}

export interface HospitalListParams extends HospitalFilterCountParams {
  pageable: Pageable;
  device: Device;
}

export interface HospitalMapListParams extends HospitalFilterCountParams {
  sort: SORT_LIST_KEY;
  radius: number;
  isMap: boolean;
  mapLatitude: number;
  mapLongitude: number;
}

export interface HospitalListResponse {
  totalPages: number;
  totalElements: number;
  sort: Sort;
  first: boolean;
  last: boolean;
  numberOfElements: number;
  pageable: PageableObject;
  size: number;
  content: Hospital[];
  number: number;
  empty: boolean;
}

export interface GetRecentVisitedHospitalListResponse {
  totalPages: number;
  totalElements: number;
  sort: Sort;
  first: boolean;
  last: boolean;
  numberOfElements: number;
  pageable: PageableObject;
  size: number;
  content: RecentVisitedHospital[];
  number: number;
  empty: boolean;
}

export interface HospitalFilterCountParams {
  name?: string;
  radius?: number;
  tagIds?: string[];
  isWorking?: boolean;
  allDay?: boolean;
  overNight?: boolean;
  weekEnd?: boolean;
  isReservationAvailable?: boolean;
  intoLinkHospitalWorkingStatus?: boolean;
  isReservationPossible?: boolean;
  isAlliance?: boolean;
  addressCode: string | undefined;
  currentLatitude: number;
  currentLongitude: number;
  isNew?: string;
}

export interface SymptomCategory {
  id: number;
  categoryName: string;
  symptoms: Symptoms[];
}

export interface HospitalSymptomsAnimal {
  id: number;
  categoryName: string;
  symptoms: SymptomsAnimal[];
  categoryDogReviewCount: number;
  categoryCatReviewCount: number;
}

export interface Symptoms {
  id: number;
  name: string;
}

export interface SelectedSymptoms extends Symptoms {
  categoryId?: number;
  text?: string;
}

export interface SymptomsAnimal {
  id: number;
  name: string;
  dogReviewCount: number;
  catReviewCount: number;
}

export interface DiseaseCategory {
  id: number;
  categoryName: string;
  diseases: Diseases[];
}

export interface HospitalDiseasesAnimal {
  id: number;
  categoryName: string;
  diseases: DiseasesAnimal[];
  categoryDogReviewCount: number;
  categoryCatReviewCount: number;
}

export interface Diseases {
  id: number;
  name: string;
}

export interface SelectedDiseases extends Diseases {
  categoryId?: number;
  text?: string;
}

export interface DiseasesAnimal {
  id: number;
  name: string;
  dogReviewCount: number;
  catReviewCount: number;
}

export interface HospitalFilterList {
  timeFilter: Filter[];
  expertiseFilter: Filter[];
  serviceFilter: Filter[];
}

export interface HospitalSearchParam {
  searchWord: string;
  addressCode: string;
  sort: string;
  size: number;
}

export interface HospitalSearchResult {
  id: number;
  name: string;
  address: Address;
}

export interface HospitalSearchResponse {
  totalPages: number;
  totalElements: number;
  sort: Sort;
  first: boolean;
  last: boolean;
  numberOfElements: number;
  pageable: PageableObject;
  size: number;
  content: HospitalSearchResult[];
  number: number;
  empty: boolean;
}

export interface ScrapResponse {
  isScrap: boolean;
  id: number;
}

export interface LikeResponse {
  isLike: boolean;
  id: number;
}

export interface HospitalRecommendParams {
  addressCode: string;
  currentLatitude: number;
  currentLongitude: number;
  radius?: number;
}

export interface RecommendHospital {
  id: number;
  name: string;
  reviewCount: number;
  totalScore: number;
  imageUrl: string;
  distance: number;
  addr: string;
  intoLinkStatus: keyof typeof INTO_LINK_STATUS;
  isReservationAvailable: boolean;
  intoLinkHospitalWorkingStatus: boolean | null;
  isReservationPossible: boolean;
}

export interface RecentVisitHospital {
  id: number;
  name: string;
  addr: string;
  address: Address;
  visitDate: string;
  visitType: string; // "REVIEW" "RESERVATION"
  image?: string;
}

export interface CreateHospitalRequestParams {
  hospitalName: string;
  addressCode: string;
}

export interface CreateHospitalRequestResponse {}
export interface GetReviewWriteShortcutResponse {
  id: number;
  name: string;
  addr: string;
  hospitalReservationId: number;
  intoLinkStatus: keyof typeof INTO_LINK_STATUS;
  petId: number;
  petName: string;
}

export interface GetHospitalSimpleReservationParams {
  latitude?: number;
  longitude?: number;
}

export interface GetHospitalSimpleReservationResponse {
  id: number;
  name: string;
  address: Address;
  image: string;
  visitCount: number;
  isNew: boolean;
  intoLinkStatus: keyof typeof INTO_LINK_STATUS;
}

export interface GetLiveHospitalIdParams {
  ids: string;
}

export type GetLiveHospitalIdResponse = number[];

export interface GetRecentVisitedHospitalListParams extends SortOptionalPageable {
  visitType?: 'REVIEW' | 'RESERVATION';
  isReservationAvailable?: boolean;
  isWorking?: boolean;
}

export enum HospitalCurationType {
  NEW = 'NEW',
  ALWAYS_VISIT = 'ALWAYS_VISIT', // 언제나 방문 가능
  CAT_FRIENDLY = 'CAT_FRIENDLY', // 고양이 친화
  NEUTRALIZATION = 'NEUTRALIZATION', // 중성화
  HEALTH = 'HEALTH', // 건강검진
  DENTAL = 'DENTAL', // 치과진료
  DOG_GROOMING = 'DOG_GROOMING', // 강아지 미용
  SKIN_CARE = 'SKIN_CARE', // 피부치료
  PATELLA_LUXATION = 'PATELLA_LUXATION', // 슬개골탈구
  CARDIOLOGIST = 'CARDIOLOGIST', // 심장전문
}

export enum HospitalCurationTypeText {
  NEW = '신규입점',
  ALWAYS_VISIT = '언제든지',
  CAT_FRIENDLY = '고양이병원',
  NEUTRALIZATION = '중성화수술',
  HEALTH = '건강검진',
  DENTAL = '치과진료',
  DOG_GROOMING = '강아지미용',
  SKIN_CARE = '피부치료',
  PATELLA_LUXATION = '슬개골탈구',
  CARDIOLOGIST = '심장전문',
}

export interface HospitalCuration {
  curationType: keyof typeof HospitalCurationType;
  imageUrl: string;
  position: number;
  isDeleted: boolean;
}

export type GetHospitalCurationResponse = HospitalCuration[];

export const HospitalApi = {
  getHospitalList: async (params: HospitalListParams) => {
    const queryParams = getQueryParams({
      [PAGEABLE_KEY.PAGE]: params.pageable.page,
      [PAGEABLE_KEY.SIZE]: params.pageable.size,
      [PAGEABLE_KEY.SORT]: params.pageable.sort,
      [TIME_FILTER_KEY.ALLDAY]: params.allDay,
      [TIME_FILTER_KEY.WEEKEND]: params.weekEnd,
      [TIME_FILTER_KEY.OVERNIGHT]: params.overNight,
      currentLatitude: params.currentLatitude,
      currentLongitude: params.currentLongitude,
      addressCode: params.addressCode,
      tagIds: params.tagIds,
      isReservationPossible: params.isReservationPossible,
      isWorking: params.isWorking,
      name: params.name,
      isNew: params.isNew,
    });
    const response = await HTTP.get<HospitalListResponse>(`${BASE_URL}/hospitals?${queryParams}`);
    return response.data;
  },
  getHospitalDetail: async (hospitalId: number) => {
    const response = await HTTP.get<HospitalDetail>(`${BASE_URL}/hospitals/${hospitalId}`);
    return response.data;
  },
  getHospitalFilterCount: async (params: HospitalFilterCountParams) => {
    const queryParams = getQueryParams({
      [TIME_FILTER_KEY.ALLDAY]: params.allDay,
      [TIME_FILTER_KEY.WEEKEND]: params.weekEnd,
      [TIME_FILTER_KEY.OVERNIGHT]: params.overNight,
      currentLatitude: params.currentLatitude,
      currentLongitude: params.currentLongitude,
      addressCode: params.addressCode,
      tagIds: params.tagIds,
      isReservationPossible: params.isReservationPossible,
      isWorking: params.isWorking,
      name: params.name,
      radius: params.radius,
    });
    const response = await HTTP.get<{ hospitalCount: number }>(`${BASE_URL}/hospitals/filter-count?${queryParams}`);
    return response.data;
  },
  getHospitalSymptoms: async () => {
    const response = await HTTP.get<SymptomCategory[]>(`${BASE_URL}/hospitals/symptoms`);
    return response.data;
  },
  getHospitalDiseases: async () => {
    const response = await HTTP.get<DiseaseCategory[]>(`${BASE_URL}/hospitals/diseases`);
    return response.data;
  },
  getHospitalExistSymptoms: async () => {
    const response = await HTTP.get<HospitalSymptomsAnimal[]>(`${BASE_URL}/hospitals/home/symptoms`);
    return response.data;
  },
  getHospitalExistDiseases: async () => {
    const response = await HTTP.get<HospitalDiseasesAnimal[]>(`${BASE_URL}/hospitals/home/diseases`);
    return response.data;
  },
  getHospitalFilterList: async (addressCode: string) => {
    const queryParams = getQueryParams({
      addressCode,
    });
    const response = await HTTP.get<HospitalFilterList>(`${BASE_URL}/hospitals/hospital-filters?${queryParams}`);
    return response.data;
  },
  getHospitalSearchList: async (params: HospitalSearchParam) => {
    const queryParams = getQueryParams({
      addressCode: params.addressCode,
      searchWord: params.searchWord,
      sort: params.sort,
      size: params.size,
    });
    const response = await HTTP.get<HospitalSearchResponse>(`${BASE_URL}/hospitals/search-hospital?${queryParams}`);
    return response.data;
  },
  createHospitalScrap: async (hospitalId: number) => {
    const response = await HTTP.post<number, ScrapResponse>(`${BASE_URL}/hospitals/hospital-scrap/${hospitalId}`);
    return response.data;
  },
  getRecommendHospitalList: async (params: HospitalRecommendParams) => {
    const queryParams = getQueryParams(params);
    const response = await HTTP.get<RecommendHospital[]>(`${BASE_URL}/hospitals/recommend?${queryParams}`);
    return response.data;
  },
  getHospitalScrapId: async () => {
    const response = await HTTP.get<number[]>(`${BASE_URL}/hospitals/my-scrap-id`);
    return response.data;
  },
  getHospitalMapList: async (params: HospitalMapListParams) => {
    const queryParams = getQueryParams(params);
    const response = await HTTP.get<HospitalListResponse>(`${BASE_URL}/hospitals/map?${queryParams}`);
    return response.data;
  },
  getHospitalScrapList: async (params: SortOptionalPageable) => {
    const queryParams = getQueryParams(params);
    const response = await HTTP.get<HospitalListResponse>(`${BASE_URL}/hospitals/my-scrap?${queryParams}`);
    return response.data;
  },
  createHospitalRequest: async (params: CreateHospitalRequestParams) => {
    const response = await HTTP.post<CreateHospitalRequestParams, CreateHospitalRequestResponse>(
      `${BASE_URL}/zendesk/ticket`,
      params,
    );
    return response.data;
  },
  getRecentVisitHospitalList: async () => {
    const response = await HTTP.get<RecentVisitHospital[]>(`${BASE_URL}/hospitals/recent`);
    return response.data;
  },
  getRecentVisitedHospitalList: async (params: GetRecentVisitedHospitalListParams) => {
    const queryParams = getQueryParams(params);
    const response = await HTTP.get<GetRecentVisitedHospitalListResponse>(
      `${BASE_URL}/hospitals/my/recently-visited-or-review-wrote?${queryParams}`,
    );
    return response.data;
  },
  getAllianceHospitalList: async () => {
    const response = await HTTP.get<HospitalListResponse>(
      `${BASE_URL}/hospitals?isReservationAvailable=true&radius=10000000&sort=name,ASC&size=1000`,
    );
    return response.data;
  },
  getReviewWriteShortcut: async () => {
    const response = await HTTP.get<GetReviewWriteShortcutResponse | null>(`${BASE_URL}/hospitals/review-write`);
    return response;
  },
  getHospitalSimpleReservation: async (params: GetHospitalSimpleReservationParams) => {
    const queryParams = getQueryParams({ lat: params.latitude, lng: params.longitude });
    const response = await HTTP.get<GetHospitalSimpleReservationResponse[]>(
      `${BASE_URL}/hospitals/simple-reservation?${queryParams}`,
    );
    return response;
  },
  getLiveHospitalId: async (params: GetLiveHospitalIdParams) => {
    const response = await HTTP.get<GetLiveHospitalIdResponse | null>(`${BASE_URL}/hospitals/live?ids=${params.ids}`);
    return response;
  },
  getHospitalCuration: async () => {
    const response = await HTTP.get<GetHospitalCurationResponse>(`${BASE_URL}/hospitals/curation`);
    return response;
  },
};
