import { PADDING } from '@/constants/common';
import { toRem } from '@/utils/commonUtils';

export type Size = number | string | { width: number | string; height: number | string };

export const toRemIfNeed = (value: number | string): string => {
  return typeof value === 'number' ? toRem(value) : value;
};

export const paddingToString = (padding: PADDING) => {
  try {
    if (typeof padding === 'object') {
      if (padding.length === 2) {
        return `${toRemIfNeed(padding[0])} ${toRemIfNeed(padding[1])}`;
      }
      if (padding.length === 4) {
        return `${toRemIfNeed(padding[0])} ${toRemIfNeed(padding[1])} ${toRemIfNeed(padding[0])} ${toRemIfNeed(
          padding[1],
        )}`;
      }
    }
    return toRemIfNeed(padding);
  } catch (e) {
    console.log('paddingToString', e);
  }
  return undefined;
};

export const getWidthHeight = (size: Size) => {
  if (typeof size === 'number') {
    return [toRem(size), toRem(size)];
  }
  if (typeof size === 'string') {
    return [size, size];
  }
  const width = toRemIfNeed(size.width);
  const height = toRemIfNeed(size.height);
  return [width, height];
};
