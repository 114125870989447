import React from 'react';
import { ImageIconProps } from '@/constants/icon';
import ImageIconList from '@/constants/imageIconList';
import { getWidthHeight } from '@/utils/layoutUtils';

const ImageIcon = ({ iconType, size = 16 }: ImageIconProps) => {
  const SelectedIcon = ImageIconList[iconType];
  const [width, height] = getWidthHeight(size);

  return (
    <i style={{ display: 'flex', width, height }}>
      {/* @ts-ignore */}
      {SelectedIcon && <SelectedIcon width={width} height={height} style={{ overflow: 'visible' }} />}
    </i>
  );
};

export default ImageIcon;
