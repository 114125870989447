import { LabelType } from '@/constants/common';

export enum FILTER_TYPE_KEY {
  TIME = 'time',
  EXPERTISE = 'expertise',
  SERVICE = 'service',
}

export enum MAP_FILTER_TYPE_KEY {
  TIME_FILTER = 'time_filter',
  SPECIALTY_FILTER = 'specialty_filter',
  SERVICE_FILTER = 'service_filter',
}

export enum SORT_LIST_KEY {
  RANKING = 'isFitpetRanking',
  DISTANCE = 'distance,ASC&sort=name,ASC',
  TOTAL_SCORE = 'totalScore,DESC&sort=name,ASC',
  REVIEW_COUNT = 'reviewCount,DESC&sort=name,ASC',
  RECENT_ORDER = 'createdAt,DESC',
  HELPFUL_COUNT = 'likeCount,DESC&sort=createdAt,DESC',
  HIGHEST_SCORE = 'score,DESC&sort=createdAt,DESC',
  LOWEST_SCORE = 'score,ASC&sort=createdAt,DESC',
  RECOMMENDED = 'isTop,DESC&sort=createdAt,DESC',
  FITPETRECOMMENDED = 'recommand,DESC&sort=createdAt,DESC',
  REVIEW_RECENT_ORDER = 'createdAt,DESC&sort=recommand,DESC',
  REVIEW_HELPFUL_COUNT = 'likeCount,DESC&sort=recommand,DESC',
  ALL_ANIMAL = '',
  DOG = 'D',
  CAT = 'C',
}

export enum TIME_FILTER_KEY {
  OVERNIGHT = 'overNight',
  ALLDAY = 'allDay',
  WEEKEND = 'weekEnd',
}

export const SORT_FILTER: Sort[] = [
  { value: SORT_LIST_KEY.RANKING, label: '핏펫랭킹 순' },
  { value: SORT_LIST_KEY.DISTANCE, label: '가까운 순' },
  { value: SORT_LIST_KEY.REVIEW_COUNT, label: '리뷰 많은 순' },
  { value: SORT_LIST_KEY.TOTAL_SCORE, label: '평점 높은 순' },
];

export const SORT_FILTER_NATIONWIDE: Sort[] = [
  { value: SORT_LIST_KEY.REVIEW_COUNT, label: '리뷰 많은 순' },
  { value: SORT_LIST_KEY.TOTAL_SCORE, label: '평점 높은 순' },
];

export const SORT_FILTER_REVIEW_LIST: Sort[] = [
  { value: SORT_LIST_KEY.RECOMMENDED, label: '관련도 순' },
  { value: SORT_LIST_KEY.RECENT_ORDER, label: '최신 순' },
  { value: SORT_LIST_KEY.HELPFUL_COUNT, label: '도움 많은 순' },
];

export const SORT_FILTER_LIST: Sort[] = [
  { value: SORT_LIST_KEY.HELPFUL_COUNT, label: '도움 많은 순' },
  { value: SORT_LIST_KEY.RECENT_ORDER, label: '최신 순' },
];

export const SORT_FILTER_LIST_FOR_HOSPITAL_REVIEW: Sort[] = [
  { value: SORT_LIST_KEY.RECENT_ORDER, label: '최신 순' },
  { value: SORT_LIST_KEY.HELPFUL_COUNT, label: '도움 많은 순' },
];

export const SORT_FILTER_LIST_FOR_SYMPTOM_SEARCH_NOTAG: Sort[] = [
  { value: SORT_LIST_KEY.FITPETRECOMMENDED, label: '핏펫추천 순' },
  { value: SORT_LIST_KEY.REVIEW_RECENT_ORDER, label: '최신 순' },
  { value: SORT_LIST_KEY.REVIEW_HELPFUL_COUNT, label: '도움 많은 순' },
];

export const SORT_FILTER_LIST_FOR_ANIMAL: Sort[] = [
  { value: SORT_LIST_KEY.ALL_ANIMAL, label: '동물 전체' },
  { value: SORT_LIST_KEY.DOG, label: '강아지' },
  { value: SORT_LIST_KEY.CAT, label: '고양이' },
];

export const SORT_FILTER_CURATION_LIST: Sort[] = [
  { value: SORT_LIST_KEY.RANKING, label: '핏펫랭킹 순' },
  { value: SORT_LIST_KEY.REVIEW_COUNT, label: '리뷰 많은 순' },
  { value: SORT_LIST_KEY.TOTAL_SCORE, label: '평점 높은 순' },
];

export const FILTER_LIST = [
  { value: FILTER_TYPE_KEY.TIME, label: '진료시간' },
  { value: FILTER_TYPE_KEY.EXPERTISE, label: '전문분야' },
  { value: FILTER_TYPE_KEY.SERVICE, label: '서비스' },
];

export const TIME_FILTER = [
  { value: TIME_FILTER_KEY.ALLDAY, label: '24시간' },
  { value: TIME_FILTER_KEY.WEEKEND, label: '주말진료' },
  { value: TIME_FILTER_KEY.OVERNIGHT, label: '야간진료' },
];

export interface Filter {
  value: TIME_FILTER_KEY | number | undefined;
  label: string;
  filterType?: FILTER_TYPE_KEY;
}

export interface DeepLinkHospitalFilter {
  addressCode?: string;
  allDay?: boolean;
  overNight?: boolean;
  weekEnd?: boolean;
  isReservationPossible?: boolean;
  isWorking?: boolean;
  tagIds?: string | string[];
  sort?: string;
}

export type DeepLinkHospitalFilterType = keyof DeepLinkHospitalFilter;

export interface FilterHeader extends LabelType<FILTER_TYPE_KEY> {}

export interface Sort extends LabelType<SORT_LIST_KEY> {}
