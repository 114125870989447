import { useCallback, useRef } from 'react';
import { OptionList } from '@/components/search/types';
import { DEVICE_PLATFORM } from '@/constants/common';
import { useInAppBridge } from '@/context/InAppProvider';
import { MAP_TYPE } from '@/utils/bridge/constants';
import { convertPetTypeForSymptomSearch } from '@/utils/commonUtils';
import { getDevicePlatform, isInApp } from '@/utils/deviceUtils';
import { PetType } from '@/utils/tracker/constants';

const useNativeBridges = () => {
  const bridges = useInAppBridge();
  const petTypeRef = useRef<OptionList | undefined>(undefined);

  const completeLayout = useCallback(() => {
    bridges?.completeLayout();
  }, [bridges]);

  const routerPathTracking = useCallback(() => {
    bridges?.routerPathTracking({
      path: window.location.href,
    });
  }, [bridges]);

  const openWebview = (url: string) => {
    if (isInApp()) {
      bridges?.routerPush(url);
    } else {
      window.open(url);
    }
  };

  const openNativePopup = (url: string) => {
    if (isInApp()) {
      bridges?.openPopupOutsideLink({ path: url });
    } else {
      window.open(url);
    }
  };

  const copyClipBoard = useCallback(
    (address: string) => {
      if (isInApp()) {
        bridges?.copyClipBoard({ address });
      }
    },
    [bridges],
  );

  const isAndroid = () => {
    return getDevicePlatform() === DEVICE_PLATFORM.ANDROID;
  };

  const isIos = () => {
    return getDevicePlatform() === DEVICE_PLATFORM.IOS;
  };

  const versionSplitDotToNumber = (version: string) => {
    return Number(version.split('.').join(''));
  };

  const isRenewalVersionCheck = () => {
    const AOS_APP_VERSION = '4.2.14'; // 병원 리뉴얼 이후 버전 체크
    const IOS_APP_VERSION = '4.2.13'; // 병원 리뉴얼 이후 버전 체크

    if (isInApp()) {
      const appVersion = versionSplitDotToNumber(window.getToken().version);

      if (isAndroid()) {
        return appVersion >= versionSplitDotToNumber(AOS_APP_VERSION);
      }

      if (isIos()) {
        return appVersion >= versionSplitDotToNumber(IOS_APP_VERSION);
      }
    }

    return true;
  };

  const isAvailableHomepageModal = () => {
    return isRenewalVersionCheck();
  };

  const isAvailableClipboard = () => {
    if (isInApp() && isAndroid()) {
      return isRenewalVersionCheck();
    }

    return true;
  };

  const isAvailableShareLink = useCallback(() => {
    const APP_VERSION = '4.2.18'; // 12.29일 원링크 배포 버전

    if (isInApp()) {
      const appVersion = versionSplitDotToNumber(window.getToken().version);
      return appVersion >= versionSplitDotToNumber(APP_VERSION);
    }

    return true;
  }, []);

  const setIsStatusBarColorWhite = useCallback(
    (isWhite: boolean) => {
      bridges?.statusBarColorWhite({ isWhite });
    },
    [bridges],
  );

  const aosOpenMap = useCallback(
    (appName: MAP_TYPE) => {
      return bridges?.goMap({ appName })?.then(({ status }) => {
        if (!status) {
          bridges?.goToStore({ appName });
        }
      });
    },
    [bridges],
  );

  const isAvailableAosMultipleMap = useCallback(() => {
    const AOS_APP_VERSION = '4.2.18'; // 병원 리뉴얼 이후 버전 체크
    const appVersion = versionSplitDotToNumber(window.getToken().version);

    return isAndroid() && appVersion >= versionSplitDotToNumber(AOS_APP_VERSION);
  }, []);

  const getLocationPermission = useCallback(() => {
    return bridges?.checkLocationPermission();
  }, [bridges]);

  const getCurrentPosition = useCallback(() => {
    return bridges?.getCurrentPosition();
  }, [bridges]);

  // 특정 버전 이하일 경우 동작하지 않도록 배포 전 확인 및 수정 필요
  const getUserPetType = useCallback(async () => {
    await bridges?.getUserPetType()?.then(({ petType }) => {
      petTypeRef.current = convertPetTypeForSymptomSearch(petType);
    });

    return petTypeRef.current;
  }, [bridges]);

  // 특정 버전 이하일 경우 동작하지 않도록 배포 전 확인 및 수정 필요
  const setUserPetType = useCallback(
    (petType: PetType) => {
      return bridges?.setUserPetType({ petType });
    },
    [bridges],
  );

  const isAvailableUserPetTypeBridges = useCallback(() => {
    const APP_VERSION = '4.2.20'; // 01.25일 동물필터 동기화

    if (isInApp()) {
      const appVersion = versionSplitDotToNumber(window.getToken().version);
      return appVersion >= versionSplitDotToNumber(APP_VERSION);
    }

    return true;
  }, []);

  const isAvailableAheadScreenBridges = useCallback(() => {
    const APP_VERSION = '4.2.35'; // 2023.08.10 어헤드 검사하기 화면 연결 배포 버전

    if (isInApp()) {
      const userAppVersion = versionSplitDotToNumber(window.getToken().version);
      return userAppVersion >= versionSplitDotToNumber(APP_VERSION);
    }

    return true;
  }, []);

  return {
    completeLayout,
    routerPathTracking,
    openWebview,
    openNativePopup,
    copyClipBoard,
    isAvailableHomepageModal,
    isAndroid,
    isIos,
    isRenewalVersionCheck,
    isAvailableClipboard,
    isAvailableShareLink,
    setIsStatusBarColorWhite,
    aosOpenMap,
    isAvailableAosMultipleMap,
    getLocationPermission,
    getCurrentPosition,
    getUserPetType,
    setUserPetType,
    isAvailableUserPetTypeBridges,
    isAvailableAheadScreenBridges,
  };
};

export default useNativeBridges;
