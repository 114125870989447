import { useInfiniteQuery, useQuery } from 'react-query';
import {
  CategoryReviewsParams,
  GetMyReviewDetailParams,
  GetTagRecommendParams,
  HOSPITAL_REVIEW_QUERY_TYPE,
  HospitalPhotoReview,
  HospitalPhotoReviewsParams,
  HospitalReviewsParams,
  Review,
  ReviewApi,
  ReviewDetail,
  ReviewProductParams,
  ReviewRelationHospitalParams,
  ReviewScrapListResponse,
  ReviewsParams,
} from '@/api/review';
import { ResponseList, ResponsePagingList, SortOptionalPageable } from '@/constants/common';
import { QUERY_KEYS } from '@/constants/queryKey';

interface GetHospitalReviewsParams {
  queryKey: HOSPITAL_REVIEW_QUERY_TYPE;
  params: HospitalReviewsParams;
}

export const useGetHospitalReviews = ({ queryKey, params }: GetHospitalReviewsParams) => {
  const result = useInfiniteQuery(
    queryKey === HOSPITAL_REVIEW_QUERY_TYPE.HOSPITAL_DETAIL
      ? QUERY_KEYS.HOSPITAL.REVIEW.DETAIL_LIST(params)
      : QUERY_KEYS.HOSPITAL.REVIEW.LIST(params),
    async ({ pageParam: page = 0 }) => {
      const response = ReviewApi.getHospitalReviews({ ...params, page });
      return response;
    },
    {
      getNextPageParam: (lastPage: ResponseList<Review>, allPages: ResponseList<Review>[]) => {
        if (lastPage.number >= lastPage.totalPages) {
          return undefined;
        }
        return allPages.reduce((acc) => acc + 1, 0);
      },
      notifyOnChangeProps: 'tracked',
      enabled: !!params.hospitalId && params.hospitalId > 0,
    },
  );
  return result;
};

export const useGetHospitalPhotoReviews = (params: HospitalPhotoReviewsParams) => {
  const result = useInfiniteQuery(
    QUERY_KEYS.HOSPITAL.REVIEW.LIST_BY_PHOTO(params),
    async ({ pageParam: page = 0 }) => {
      const response = ReviewApi.getHospitalPhotoReviews({ ...params, page });
      return response;
    },
    {
      getNextPageParam: (
        lastPage: ResponseList<HospitalPhotoReview>,
        allPages: ResponseList<HospitalPhotoReview>[],
      ) => {
        if (lastPage.number >= lastPage.totalPages) {
          return undefined;
        }
        return allPages.reduce((acc) => acc + 1, 0);
      },
      notifyOnChangeProps: 'tracked',
      enabled: !!params.hospitalId && params.hospitalId > 0,
    },
  );
  return result;
};

export const useReviewDetail = (params: ReviewDetail) => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.REVIEW.DETAIL(params.reviewId),
    async () => {
      const response = ReviewApi.getHospitalReviewDetail(params);
      return response;
    },
    { notifyOnChangeProps: 'tracked', enabled: params.reviewId > 0 },
  );
  return result;
};

export const useGetPopularityReviews = (params: ReviewsParams) => {
  const result = useQuery(
    QUERY_KEYS.REVIEW.POPULARITY_REVIEW(params),
    async () => {
      const response = ReviewApi.getReviews(params);
      return response;
    },
    { notifyOnChangeProps: 'tracked', enabled: params.keyword !== '' },
  );
  return result;
};

export const useGetLatelyReviews = (params: ReviewsParams) => {
  const result = useQuery(
    QUERY_KEYS.REVIEW.POPULARITY_REVIEW(params),
    async () => {
      const response = ReviewApi.getLatelyReviews(params);
      return response;
    },
    { notifyOnChangeProps: 'tracked', enabled: !!params.keyword },
  );
  return result;
};

export const useGetReviewRelationHospital = (params: ReviewRelationHospitalParams) => {
  const result = useQuery(
    QUERY_KEYS.REVIEW.REVIEW_RELATION_HOSPITAL(params),
    async () => {
      const response = ReviewApi.getReviewRelationHospital(params);
      return response;
    },
    { notifyOnChangeProps: 'tracked', enabled: params.keyword !== '' && params.addressCode !== '' },
  );
  return result;
};

export const useGetCategoryReviews = (params: CategoryReviewsParams) => {
  const result = useInfiniteQuery(
    QUERY_KEYS.REVIEW.CATEGORY_REVIEW(params),
    async ({ pageParam = 0 }) => {
      const response = await ReviewApi.getCategoryReviews({
        ...params,
        pageable: { ...params.pageable, page: pageParam },
      });
      return response;
    },
    {
      getNextPageParam: (lastPage: ResponsePagingList<Review>, allPages: ResponsePagingList<Review>[]) => {
        if (lastPage.pageable.pageNumber >= lastPage.totalPages) {
          return undefined;
        }
        return allPages.reduce((acc) => acc + 1, 0);
      },
      notifyOnChangeProps: 'tracked',
      enabled: params.diseaseIds.length > 0 || params.symptomIds.length > 0,
    },
  );
  return result;
};

export const useGetMyScrapReviewIdList = (enabled: boolean = true) => {
  const result = useQuery(
    QUERY_KEYS.MY.SCRAP_REVIEW_ID_LIST(),
    async () => {
      const response = ReviewApi.getMyScrapReviewIdList();
      return response;
    },
    { notifyOnChangeProps: 'tracked', enabled },
  );
  return result;
};

export const useGetMyLikeReviewIdList = (enabled: boolean = true) => {
  const result = useQuery(
    QUERY_KEYS.MY.LIKE_REVIEW_ID_LIST(),
    async () => {
      const response = ReviewApi.getMyLikeReviewIdList();
      return response;
    },
    { notifyOnChangeProps: 'tracked', enabled },
  );
  return result;
};

export const useGetReviewProduct = (params: ReviewProductParams) => {
  const result = useQuery(
    QUERY_KEYS.REVIEW.REVIEW_PRODUCT(params),
    async () => {
      const response = ReviewApi.getReviewProduct(params);
      return response;
    },
    { notifyOnChangeProps: 'tracked', enabled: !!params.keyword },
  );
  return result;
};

export const useGetMyReviewDetail = (params: GetMyReviewDetailParams) => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.REVIEW.DETAIL(params.reviewId),
    async () => {
      const response = await ReviewApi.getMyReviewDetail({ reviewId: params.reviewId });
      return response;
    },
    { notifyOnChangeProps: 'tracked', enabled: params.reviewId > 0 },
  );
  return result;
};

export const useGetReviewScrapList = (params: SortOptionalPageable) => {
  const result = useInfiniteQuery(
    QUERY_KEYS.REVIEW.SCRAP_LIST(params),
    async ({ pageParam = 0 }) => {
      const response = await ReviewApi.getReviewScrapList({ ...params, page: pageParam });
      return response;
    },
    {
      getNextPageParam: (lastPage: ReviewScrapListResponse, allPages: ReviewScrapListResponse[]) => {
        if (lastPage.pageable.pageNumber >= lastPage.totalPages) {
          return undefined;
        }
        return allPages.reduce((acc) => acc + 1, 0);
      },
      retry: false,
    },
  );
  return result;
};

export const useGetReviewTotalCount = () => {
  const result = useQuery(QUERY_KEYS.REVIEW.TOTAL_COUNT(), async () => {
    const response = await ReviewApi.getReviewTotalCount();
    return response;
  });
  return result;
};

export const useGetSymptomQuestions = () => {
  const result = useQuery(QUERY_KEYS.REVIEW.SYMPTOM_QUESTIONS(), async () => {
    const response = await ReviewApi.getSymptomQuestions();
    return response.data;
  });
  return result;
};

export const useGetDiseaseQuestions = () => {
  const result = useQuery(QUERY_KEYS.REVIEW.DISEASE_QUESTIONS(), async () => {
    const response = await ReviewApi.getDiseaseQuestions();
    return response.data;
  });
  return result;
};

export const useGetTagRecommend = (params: GetTagRecommendParams) => {
  const result = useQuery(
    QUERY_KEYS.REVIEW.TAG_RECOMMEND(params),
    async () => {
      const response = await ReviewApi.getTagRecommend({ keyword: params.keyword, animal: params.animal });
      return response;
    },
    { notifyOnChangeProps: 'tracked', enabled: !!params.keyword },
  );
  return result;
};
