import { decamelize } from '@ridi/object-case-converter';
import { EventHandler } from './handlers/base';
import { EventType } from './model';
import { getPlatform, PlatformDetail } from '@/utils/deviceUtils';
import { PetTypeMall, Service } from '@/utils/tracker/constants';
import { HospitalEvent, HospitalEventPropertiesType } from '@/utils/tracker/event/hospital';
import { AmplitudeHandler } from '@/utils/tracker/handlers/amplitude';
import { GTMHandler } from '@/utils/tracker/handlers/gtm';

export class Tracker {
  private user?: string;

  private userProperties: any = {};

  private platform: PlatformDetail = PlatformDetail.UNKNOWN;

  private petTypeMall?: PetTypeMall;

  private eventHandlers: Array<EventHandler>;

  constructor(props?: { petTypeMall?: PetTypeMall }) {
    this.petTypeMall = props?.petTypeMall;
    this.eventHandlers = [GTMHandler, new AmplitudeHandler()];
  }

  initialize = () => {
    this.eventHandlers.forEach((handler) => handler.initialize());
  };

  setUser = (user: string) => {
    this.user = user;
    this.eventHandlers.forEach((handler) => handler.setUserID(user));
  };

  setUserProperties = (properties: any) => {
    this.userProperties = { ...this.userProperties, ...properties };
    this.eventHandlers.forEach((handler) => handler.setUserProperties(this.userProperties));
  };

  sendPageview = (url: string) => {
    this.eventHandlers.forEach((handler) => handler.handlePageviewEvent(url));
  };

  sendHospitalEvent = (event: HospitalEvent, properties: HospitalEventPropertiesType = {}) => {
    try {
      const eventProperties = this.getHospitalEventProperties(event, properties);
      this.sendEvent(event, eventProperties || {});
    } catch (e) {
      console.error(e);
    }
  };

  private sendEvent = (event: EventType, properties: HospitalEventPropertiesType) => {
    if (this.platform === PlatformDetail.UNKNOWN) {
      try {
        this.platform = getPlatform();
      } catch (e) {
        console.error(e);
      }
    }
    this.eventHandlers.forEach((handler) => handler.handleEvent(event, properties, this.platform));
  };

  private getHospitalEventProperties = (event: HospitalEvent, properties: HospitalEventPropertiesType = {}) =>
    decamelize(
      {
        platformDetail: this.platform,
        service: Service.HOSPITAL,
        ...properties,
      },
      { recursive: true },
    );
}
