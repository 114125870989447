import styled from '@emotion/styled';
import { toRem } from '@/utils/commonUtils';

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${toRem(16)};
  padding: ${toRem(40)} ${toRem(32)} ${toRem(24)} ${toRem(32)};
`;

export default BodyContainer;
