import { useCallback } from 'react';
import { appBridgeLogin } from '@/utils/utilBridge';
import { debounce } from '@/utils/commonUtils';
import { isInApp } from '@/utils/deviceUtils';

const useCheckLogin = () => {
  const afterLoginFunction = useCallback(
    (isLogin: boolean) => (callbackFn?: () => void) => {
      debounce(() => {
        // 로그인이 상태가 아니라면
        if (!isLogin) {
          // 앱브리지
          appBridgeLogin()?.then(() => {});

          window.onforeground = () => {
            if (isInApp()) {
              if (callbackFn && !!window.getToken().apiToken.token) {
                callbackFn();
                window.onforeground = null;
              }
            }
          };
          return;
        }
        // 로그인 상태면 콜백 함수 실행
        callbackFn?.();
      }, 100)();
    },
    [],
  );

  const isLogin = useCallback(() => {
    try {
      return typeof window !== 'undefined' && window.getToken?.()?.apiToken?.token !== '';
      // TODO: 웹에서 비로그인 상태일 경우, 토큰 검증을 위한 코드 (PC웹 노출하는 시점에 코드 추가 필요함)
      // return typeof window !== 'undefined' && !!window.getToken?.()?.apiToken?.token;
    } catch (e) {
      console.error('isLogin, e: ', e);
      return false;
    }
  }, []);

  return {
    afterLoginFunction,
    isLogin,
  };
};

export default useCheckLogin;
