import styled from '@emotion/styled';
import { FC } from 'react';
import { StyledTypeFaceBodyProps, TypeFaceBodyProps } from './typeface.type';
import { FONT_STYLE } from '@/constants/typeface';

export const Body4: FC<TypeFaceBodyProps> = ({ children, fontWeight, fontColor, className }) => {
  return (
    <StyledText fontWeight={fontWeight} fontColor={fontColor} className={className}>
      {children}
    </StyledText>
  );
};

const StyledText = styled.p<StyledTypeFaceBodyProps>`
  ${FONT_STYLE.BODY_4}
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.fontColor};
`;
