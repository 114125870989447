import create from 'zustand';

interface UseHospitalReviewDetailStore {
  ignoreReviewIdList: Set<number>;
  initIgnoreReviewIdList: () => void;
  getIgnoreReviewIdList: () => number[];
  addIgnoreReviewIdList: (reviewId: number) => void;
}

const useHospitalReviewDetailStore = create<UseHospitalReviewDetailStore>((set, get) => ({
  ignoreReviewIdList: new Set(),
  initIgnoreReviewIdList: () => set(() => ({ ignoreReviewIdList: new Set() })),
  getIgnoreReviewIdList: () => Array.from(get().ignoreReviewIdList),
  addIgnoreReviewIdList: (reviewId: number) => {
    if (reviewId < 1 || get().ignoreReviewIdList.has(reviewId)) {
      return;
    }

    const tmpIgnoreReviewIdList = new Set(get().ignoreReviewIdList);
    tmpIgnoreReviewIdList.add(reviewId);

    set(() => ({
      ignoreReviewIdList: tmpIgnoreReviewIdList,
    }));
  },
}));

export default useHospitalReviewDetailStore;
