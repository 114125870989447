import { Router } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import useEventTrackingStore from '@/store/useEventTrackingStore';
import { Tracker } from '@/utils/tracker';
import { PetTypeMall } from '@/utils/tracker/constants';

const useEventTracking = () => {
  const petTypeFromUserInfo = useMemo(
    () => (process.browser && window?.getToken()?.userInfo?.displayPetType) || '',
    [],
  );

  const petTypeMall = useMemo(
    () => (petTypeFromUserInfo.toLowerCase() === PetTypeMall.CAT ? PetTypeMall.CAT : PetTypeMall.DOG),
    [petTypeFromUserInfo],
  );

  const eventTracker = useMemo(
    () =>
      new Tracker({
        petTypeMall,
      }),
    [petTypeMall],
  );

  const setEventTracking = useEventTrackingStore(useCallback((state) => state.setEventTracking, []));

  useEffect(() => {
    if (process.browser) {
      eventTracker.initialize();
      setEventTracking(eventTracker);
    }
  }, [eventTracker, setEventTracking]);

  const tracker = useMemo(() => eventTracker, [eventTracker]);

  useEffect(() => {
    // handle virtual page view
    const handleRouteChange = (url: string) => {
      tracker.sendPageview(url);
    };

    Router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [tracker]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const user = window?.getToken()?.userInfo;
      if (user?.id) {
        tracker.setUser(`${user.id}`);
      }
      if (user?.id && !petTypeFromUserInfo) {
        tracker.setUserProperties({ displayPetType: petTypeMall.toUpperCase() });
      }
    }
  }, [petTypeMall, petTypeFromUserInfo, tracker]);

  return {};
};

export default useEventTracking;
