import { ReactNode } from 'react';
import create from 'zustand';

export interface FloatingMessageType {
  id: number;
  content: ReactNode | string;
  onClose: () => void;
  margin?: number;
  allowedPaths?: string[]; // floating 이 보여지는 경로
}

interface FloatingStore {
  floatingList: FloatingMessageType[];
  addFloatingList: (floatingMessage: FloatingMessageType) => void;
  clearAllFloatingList: () => void;
  closeFloating: () => void;
}

const useFloatingStore = create<FloatingStore>((set, get) => ({
  floatingList: [],
  addFloatingList: (floatingMessage) => {
    const { floatingList } = get();
    const hasDuplicateId = floatingList.some((floating) => floating.id === floatingMessage.id);
    if (hasDuplicateId) {
      return;
    }
    const newFloatingList = [...floatingList, floatingMessage];
    set((state) => ({ ...state, floatingList: newFloatingList }));
  },
  clearAllFloatingList: () => {
    const { floatingList } = get();
    if (!floatingList?.length) {
      return;
    }
    set((state) => ({ ...state, floatingList: [] }));
  },
  closeFloating: () => {
    const { floatingList } = get();
    if (!floatingList?.length) {
      return;
    }
    set((state) => ({ ...state, floatingList: floatingList.slice(1) }));
  },
}));

export default useFloatingStore;
