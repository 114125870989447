import { EventType } from '@/utils/tracker/model';

export interface EventHandler {
  initialize: () => void;
  setUserID: (userID: string) => void;
  handleEvent: (event: EventType, properties: any, ...args: any) => void;
  handlePageviewEvent: (url: string) => void;
  setUserProperties: (properties: any) => void;
}

export class EventLogger {
  private name: string;

  constructor(name: string) {
    this.name = name;
  }

  info = (...args: any) => {
    if (process.env.NEXT_PUBLIC_MODE !== 'production') {
      // eslint-disable-next-line no-console
      console.log(`[${this.name}] `, ...args);
    }
  };
}
