export const Z_INDEX = {
  RESERVATION_TIME_HIGHLIGHT: -1,
  // 1 ~ 99. input / button / image / etc
  SCRAP: 1,
  LABEL_CARD: 1,
  RESERVATION_TIME_TEXT: 1,
  INPUT_ICON: 1,
  INPUT_HASHTAG: 1,
  REVIEW_CARD: 1,
  HOSPITAL_FILTER: 1,
  MAP_INFO_CONTAINER: 2,
  SELECT_LAYER_TOGGLE_BUTTON: 2,
  DIM_SPINNER: 2,
  HOSPITAL_MAP_SEARCH_BUTTON: 2,
  IMAGE_COVER: 2,
  REVIEW_FILTER: 2,
  HOSPITAL_DETAIL_INFO_TAB: 3,
  MAP_INFO_MAP_COVER: 3,
  SELECT_LAYER: 4,
  BANNER_SPINNER: 5,
  INPUT_NAVIGATION: 5,
  HOSPITAL_SUB_FILTER: 6,
  SCROLL_ABLE_SELECT: 7,
  TOOLTIP: 99,
  RESERVATION_LIST_FILTER: 99,
  // 100 ~ 199. navi / header / footer
  BASE_HEADER: 100,
  DETAIL_HEADER: 100,
  DETAIL_FOOTER: 100,
  SEARCH_HEADER: 100,
  REVIEW_HEADER: 100,
  IMAGE_MODAL_HEADER: 100,
  SCRAP_HEADER: 100,
  // 200 ~ 299. floating Element
  HEADER_TOOLTIP: 200,
  FLOAT_BUTTON: 200,
  // 300 ~ 399. modal
  MODAL_BACKGROUND: 300,
  MODAL_CONTAINER: 300,
  NOTIFICATIONS: 300,
  BOTTOM_MODAL: 301,
  MODAL_TOOLTIP: 301,
  MODAL_CONTENT: 301,
  // 400 ~ 499. loading, toast
  LOADING_SPINNER: 400,
  TOAST: 401,
};
