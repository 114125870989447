import { ParsedUrlQuery } from 'node:querystring';
import { Url, UrlObject } from 'url';
import { NextRouter, useRouter } from 'next/router';
import { isPathnameHome, ROUTES } from '@/constants/routes';
import { useGetHasPrevPage } from '@/context/RouteStackProvider';
// import useScrollInfoStore from '@/store/useScrollInfoStore';
import { appBridgeRouter, isInAppFlag } from '@/utils/utilBridge';
import WebStorage from '@/utils/webStorage';

interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
}

export type UseAppRouterType = Omit<NextRouter, 'push' | 'replace'> & {
  push: (
    url: Url | UrlObject | string,
    as?: Url | undefined,
    options?: TransitionOptions | undefined,
  ) => Promise<boolean> | undefined;
  replace: (url: any, as?: Url | undefined, options?: TransitionOptions | undefined) => Promise<boolean> | void;
  goRoot: (step?: number) => Promise<boolean> | void;
  goHome: () => void;
  isCurrentPathname: (pathname: string) => boolean;
};

const addQueryToUrl = (url: Url | UrlObject | string, query: ParsedUrlQuery): Url => {
  const isUrlString = typeof url === 'string';
  const isParsedUrlQuery = !isUrlString && typeof url.query === 'object';
  const addedUrl = {
    ...(isUrlString ? { pathname: url } : url),
    query: {
      ...(isParsedUrlQuery ? (url.query as object) : {}),
      ...query,
    },
  };
  return addedUrl as Url;
};

const useAppRouter = (): UseAppRouterType => {
  const hasPrevPage = useGetHasPrevPage();
  const nextRouter = useRouter();
  const bridgeRouter = appBridgeRouter();
  // const { addScrollInfo } = useScrollInfoStore();

  const push = (
    url: Url | UrlObject | string,
    as?: Url | undefined,
    options?: TransitionOptions | undefined,
  ): Promise<boolean> | undefined => {
    WebStorage.setPageScrollYPosition(nextRouter.pathname, window.scrollY);
    // todo : 추후 에러 확인 후 주석 수정 예정
    // if (isScrollInfoSavePath(nextRouter.pathname)) {
    //   addScrollInfo({
    //     path: nextRouter.pathname,
    //     asPath: nextRouter.asPath,
    //     scrollPosition: window.scrollY,
    //   });
    // }

    if (isPathnameHome(nextRouter.pathname)) {
      const newUrl = addQueryToUrl(url, { pushPageUrl: nextRouter.asPath });
      if (isInAppFlag) {
        return bridgeRouter.push(newUrl, as, options);
      }
      return nextRouter.push(newUrl, as, options);
    }
    return nextRouter.push(url, as, options);
  };

  const back = (): void => {
    if (isInAppFlag) {
      bridgeRouter.back();
      return;
    }
    if (hasPrevPage < 1) {
      nextRouter.push(
        typeof nextRouter.query.pushPageUrl === 'string' ? (nextRouter.query.pushPageUrl as string) : ROUTES.HOME,
      );
      return;
    }
    nextRouter.back();
  };

  const goHome = () => {
    if (isInAppFlag) {
      bridgeRouter.go(-99999);
      return;
    }
    nextRouter.push(ROUTES.HOME);
  };

  const goRoot = (step: number = -9999) => {
    if (!isInAppFlag) {
      return;
    }
    bridgeRouter.go(step);
  };

  const replace = (
    url: Url | string,
    as?: Url | undefined,
    options?: TransitionOptions | undefined,
  ): Promise<boolean> | void => {
    const urlPath = typeof url === 'string' ? url : url.pathname;

    if (
      !isInAppFlag ||
      (isPathnameHome(nextRouter.pathname) && urlPath && isPathnameHome(urlPath)) ||
      !isPathnameHome(nextRouter.pathname)
    ) {
      return nextRouter.replace(url, as, options);
    }

    return new Promise<boolean>((resolve) => {
      console.error(
        'Failed to replace url at HOME. current pathname',
        nextRouter.pathname,
        'current query',
        nextRouter.query,
        'replace url',
        url,
      );
      resolve(false);
    });
  };

  const isCurrentPathname = (pathname: string) => {
    return nextRouter.pathname === pathname;
  };

  return {
    ...nextRouter,
    push,
    back,
    replace,
    goRoot,
    goHome,
    isCurrentPathname,
  };
};

export default useAppRouter;
