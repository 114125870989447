import dayjs from 'dayjs';
import React from 'react';
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from './webStorage';
import { INTO_LINK_STATUS, SelectedDiseases, SelectedSymptoms } from '@/api/hospital';
import { GetSymptomDiseaseQuestions } from '@/api/review';
import { ReviewImage } from '@/api/reviewWrite';
import { OPTION_LIST } from '@/components/search/types';
import {
  ANIMAL,
  DEFAULT_LOCATION,
  Padding,
  REVIEW_TYPE,
  SetSelectedAddressToLocalStorageParams,
} from '@/constants/common';
import { SORT_FILTER_LIST_FOR_ANIMAL } from '@/constants/filter';
import {
  BASIC_NO_CAT_IMAGE_URL,
  BASIC_NO_DOG_IMAGE_URL,
  CURRENT_REVIEW_REWARD_PRICE,
  MAX_REVIEW_IMAGE_COUNT,
  PREVIOUS_REVIEW_REWARD_PRICE,
} from '@/constants/policy';
import { SelectedSymptomDiseaseField } from '@/constants/review';
import { ROUTES } from '@/constants/routes';
import { WEB_STORAGE_KEY } from '@/constants/webStorageKey';
import { useInAppBridge } from '@/context/InAppProvider';
// eslint-disable-next-line import/no-cycle
import { EventTrackerPathLocation } from '@/hooks/useEventTrackerHospital';
import { GLOBAL_COLOR } from '@/styles/colors';
import { BASE_FONT_SIZE } from '@/styles/sizes';
import { isInApp } from '@/utils/deviceUtils';
import { PetType } from '@/utils/tracker/constants';

export type ValueOf<T> = T[keyof T];

export interface PromotionEventHospital {
  id: number;
  listPromotionText: string;
  detailPromotionText: string;
  tooltipText: string;
  tooltipTextNoReservation: string;
  isPromotion: boolean;
  hasRightIcon: boolean;
}

interface GetHospitalCardBadgeParams {
  visitCount: number;
  isNew: boolean;
}

interface GetEventTrackerPopupLocationParams {
  pathname: string;
}

interface GetPromotionEventHospitalParams {
  hospitalList: PromotionEventHospital[];
  hospitalId: number;
}

interface GetHospitalLabelsParams {
  isIntolinkHospital: boolean;
  isReservationPossible: boolean;
  isAlliance: boolean;
  isPromotion: boolean;
}

export const isServerSide = () => typeof window === 'undefined';

export const isLastItem = (item: any[], index: number) => item.length - 1 === index;

export const getListItemCenterOffset = (list: HTMLElement, item: HTMLElement) =>
  item.offsetLeft + item.offsetWidth / 2 - list.offsetLeft - list.offsetWidth / 2;

// add declarations to the global scope from inside a module
declare global {
  interface Document {
    documentMode?: any;
  }
}

export const debounce = (func: (...params: any) => void, wait: number) => {
  // TODO: resolve no-undef NodeJS.Timeout
  // eslint-disable-next-line no-undef
  let timer: NodeJS.Timeout | undefined;

  return (...params: any) => {
    if (timer) {
      clearTimeout(timer);
      timer = undefined;
    }

    timer = setTimeout(() => {
      func(...params);
      timer = undefined;
    }, wait);
  };
};

export const openKaKaoMap = (lat: number, lon: number, name: string, address: string) => {
  const url = `/providers/maps?lat=${lat}&lon=${lon}&name=${name}&address=${address}`;
  openWebview(url);
};

export const openWebview = (url: string) => {
  if (isInApp()) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const inAppBridge = useInAppBridge();
    inAppBridge?.routerPush(url);
  } else {
    window.open(url);
  }
};

export const toRem = (px: number): string => {
  return `${px / BASE_FONT_SIZE}rem`;
};

export const parseToArray = (item: string) => {
  if (!item) {
    return [];
  }
  return JSON.parse(item);
};

export const parseToString = <T>(item: T[]): string => {
  if (!item) {
    return '';
  }
  return JSON.stringify(item);
};

export const getAssetFullUrl = (url: string): string => {
  const FITPET_ENV = process.env.NEXT_PUBLIC_FITPET_ENV || 'development';
  return `${process.env.NEXT_PUBLIC_IMAGE_BASE_URL}hospital/${FITPET_ENV}/assets/${url}`;
};

export const getImageFullUrl = (url: string): string => {
  const IS_STAGE_MY_PET_BASE_URL = process.env.NEXT_PUBLIC_FITPET_ENV === 'stage' && url.includes('images/mypet/thumb');
  if (IS_STAGE_MY_PET_BASE_URL) {
    return `${process.env.NEXT_PUBLIC_STAGE_MY_PET_IMAGE_BASE_URL}/${url}`;
  }

  const prefix =
    url && (url.startsWith('https://') || url.startsWith('http://')) ? '' : `${process.env.NEXT_PUBLIC_IMAGE_BASE_URL}`;
  return `${prefix}${url}`;
};

export const getNoPetImageUrl = (petType: string | undefined) => {
  if (petType === ANIMAL.DOG) {
    return BASIC_NO_DOG_IMAGE_URL;
  }
  return BASIC_NO_CAT_IMAGE_URL;
};

export const getPetImageUrl = (imageUrl: string | undefined, petType: string | undefined) => {
  if (imageUrl) {
    return getImageFullUrl(imageUrl);
  }
  return getNoPetImageUrl(petType);
};

export const getImageResizeUrl = (url: string): string => {
  const prefix =
    url && (url.startsWith('https://') || url.startsWith('http://')) ? '' : `${process.env.NEXT_PUBLIC_IMAGE_BASE_URL}`;
  const imageFilePosition = url.lastIndexOf('/');
  const resizedUrl = `${url.substring(0, imageFilePosition)}/resize/360${url.substring(imageFilePosition)}`;
  return `${prefix}${resizedUrl}`;
};

export const checkTotalSymptomCount = (selectedSymptomField: SelectedSymptomDiseaseField) => {
  return Object.keys(selectedSymptomField).reduce((previousValue, currentValue) => {
    return previousValue + selectedSymptomField[currentValue].length;
  }, 0);
};

export const checkTotalDiseaseCount = (selectedDiseaseField: SelectedSymptomDiseaseField) => {
  return Object.keys(selectedDiseaseField).reduce((previousValue, currentValue) => {
    return previousValue + selectedDiseaseField[currentValue].length;
  }, 0);
};

export const generateUUID = () => {
  // Public Domain/MIT
  let d: number = new Date().getTime();
  let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    let r: number = Math.random() * 16; // random number between 0 and 16
    if (d > 0) {
      // eslint-disable-next-line no-bitwise
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      // eslint-disable-next-line no-bitwise
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    // eslint-disable-next-line no-bitwise
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
};

const DEFAULT_STANDARD_MONTH = 12;
const PET_MAX_AGE = 30;
export const calcAge = (petAgeMonth: number) => {
  if (petAgeMonth < 1) {
    return '';
  }

  if (petAgeMonth <= DEFAULT_STANDARD_MONTH) {
    return `${petAgeMonth}개월`;
  }

  const year = petAgeMonth / DEFAULT_STANDARD_MONTH;
  const calcYear = (Math.floor(year * 10) / 10).toString();

  if (Number(calcYear) > PET_MAX_AGE) {
    return '';
  }

  const [petYear, petMonth] = calcYear.split('.');
  return `${petYear}년 ${petMonth ? `${petMonth}개월` : ''}`;
};

export const getMyUserId = () => {
  if (isInApp() && window.getToken().userInfo.id) {
    const userId = atob(window.getToken().userInfo.id).split(':')[1];
    return Number(userId);
  }

  return 0;
};

export const limitMaxLength = (event: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
  const { target } = event;

  if (target.value.length > target.maxLength) {
    target.value = target.value.slice(0, target.maxLength);
  }
};

export const checkOldReview = (createdAt: string, previousDate: string) => {
  const previousReviewDate = new Date(previousDate);
  const reviewCreatedAt = new Date(createdAt);
  return previousReviewDate > reviewCreatedAt;
};

export const getSymptomQuestions = (
  questionsData: GetSymptomDiseaseQuestions,
  selectedSymptoms: SelectedSymptoms[] | [] | undefined,
) => {
  const result: string[] = [];
  const selectedSymptomQuestionArray = selectedSymptoms?.map((item, index) => {
    const selectedSymptomName = selectedSymptoms[index]?.name as string;
    return questionsData[selectedSymptomName];
  });
  if (!selectedSymptomQuestionArray) {
    return [];
  }
  for (let i = 0; i < 3; i += 1) {
    for (let j = 0; j < selectedSymptomQuestionArray.length; j += 1) {
      result.push(selectedSymptomQuestionArray[j][i]);
    }
  }
  return result;
};

export const getSymptomDiseaseQuestions = (
  questionsData: GetSymptomDiseaseQuestions,
  selectedItems: SelectedSymptoms[] | SelectedDiseases[] | [] | undefined,
) => {
  const result: string[] = [];
  const selectedQuestions = selectedItems?.map((item, index) => {
    const selectedSymptomName = selectedItems[index]?.name as string;
    return questionsData[selectedSymptomName];
  });
  if (!selectedQuestions) {
    return [];
  }
  for (let i = 0; i < 3; i += 1) {
    for (let j = 0; j < selectedQuestions.length; j += 1) {
      result.push(selectedQuestions[j][i]);
    }
  }
  return result;
};

export const convertPetTypeForBridge = (animal: string) => {
  if (animal === ANIMAL.DOG) {
    return PetType.DOG;
  }
  if (animal === ANIMAL.CAT) {
    return PetType.CAT;
  }
  return PetType.ETC;
};

export const convertPetTypeForSymptomSearch = (petType: PetType) => {
  if (petType === PetType.DOG) {
    return OPTION_LIST[0];
  }

  if (petType === PetType.CAT) {
    return OPTION_LIST[1];
  }

  return undefined;
};

export const convertAnimalTypeToSortListType = (animal: ANIMAL) => {
  if (animal === ANIMAL.DOG) {
    return SORT_FILTER_LIST_FOR_ANIMAL[1];
  }
  if (animal === ANIMAL.CAT) {
    return SORT_FILTER_LIST_FOR_ANIMAL[2];
  }
  return SORT_FILTER_LIST_FOR_ANIMAL[0];
};

export const getPadding = ({ top = 0, right = 0, bottom = 0, left = 0 }: Padding) => {
  return `${toRem(top)} ${toRem(right)} ${toRem(bottom)} ${toRem(left)}`;
};

export const sliceReviewImages = (reviewImages: ReviewImage[] | undefined) => {
  const result =
    reviewImages && reviewImages.length > MAX_REVIEW_IMAGE_COUNT
      ? reviewImages.slice(0, MAX_REVIEW_IMAGE_COUNT)
      : reviewImages;
  return result;
};

export const checkTodayDate = (date: number) => {
  const todayDate = dayjs().date();
  return todayDate === date;
};

export const replaceBrokenThumbnail = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
  event.currentTarget.src = '/images/thumbnail.svg';
};

export const replaceBrokenPetImage = (event: React.SyntheticEvent<HTMLImageElement, Event>) => {
  event.currentTarget.src = BASIC_NO_DOG_IMAGE_URL;
};

export const getReviewRewardPrice = (createdAt: string) => {
  const reviewCreatedAt = new Date(createdAt);
  const deadlineDate = new Date('2023-03-20T00:00:00');
  const afterReviewPolicyChangeDate = reviewCreatedAt > deadlineDate;

  if (afterReviewPolicyChangeDate) {
    return CURRENT_REVIEW_REWARD_PRICE;
  }
  return PREVIOUS_REVIEW_REWARD_PRICE;
};

export const getSelectedAddressToLocalStorage = () => {
  const latitude = Number(getLocalStorageItem(WEB_STORAGE_KEY.SELECTED_ADDRESS_LATITUDE)) || DEFAULT_LOCATION.LAT;
  const longitude = Number(getLocalStorageItem(WEB_STORAGE_KEY.SELECTED_ADDRESS_LONGITUDE)) || DEFAULT_LOCATION.LNG;
  return { latitude, longitude };
};

export const setSelectedAddressToLocalStorage = ({ latitude, longitude }: SetSelectedAddressToLocalStorageParams) => {
  if (!latitude || !longitude) {
    return;
  }
  setLocalStorageItem(WEB_STORAGE_KEY.SELECTED_ADDRESS_LATITUDE, String(latitude));
  setLocalStorageItem(WEB_STORAGE_KEY.SELECTED_ADDRESS_LONGITUDE, String(longitude));
};

export const removeSelectedAddressToLocalStorage = () => {
  removeLocalStorageItem(WEB_STORAGE_KEY.SELECTED_ADDRESS_LATITUDE);
  removeLocalStorageItem(WEB_STORAGE_KEY.SELECTED_ADDRESS_LONGITUDE);
};

export const getParsedTotalScore = (score: number) => {
  const result = (Math.floor(score * 10) / 10).toFixed(1);
  return result;
};

export const checkReviewPrescripType = (reviewType: REVIEW_TYPE) => {
  const isReviewPrescripType = reviewType === REVIEW_TYPE.PRESCRIP;
  return isReviewPrescripType;
};

export const removeFirstHashFromText = (text: string) => {
  return text.replace('#', '');
};

export const checkIsIntolinkHospital = (intoLinkStatus: keyof typeof INTO_LINK_STATUS | undefined) => {
  return intoLinkStatus === INTO_LINK_STATUS.LINK;
};

export const getBrandTypeValue = (encodedText: string) => {
  const decodedText = Buffer.from(encodedText, 'base64').toString('utf-8');
  const brandTypeValue = Number(decodedText.split(':')[1]);
  return brandTypeValue;
};

export const getHospitalCardBadge = ({ visitCount, isNew }: GetHospitalCardBadgeParams) => {
  if (visitCount >= 10) {
    return { text: '10회 이상 방문', backgroundColor: GLOBAL_COLOR.GRAY_700 };
  }
  if (visitCount && visitCount < 10) {
    return { text: `${visitCount}회 방문`, backgroundColor: GLOBAL_COLOR.GRAY_700 };
  }
  if (visitCount === 0 && isNew) {
    return { text: 'NEW', backgroundColor: GLOBAL_COLOR.RED_500 };
  }
  return { text: '', backgroundColor: '' };
};

export const getEventTrackerPopupLocation = ({ pathname }: GetEventTrackerPopupLocationParams) => {
  if (pathname === ROUTES.HOME) {
    return EventTrackerPathLocation.HOSPITAL_SHORTCUT;
  }
  if (pathname === ROUTES.HOSPITALS.DETAIL) {
    return EventTrackerPathLocation.HOSPITAL_DETAIL;
  }
  if (pathname === ROUTES.RESERVATIONS.LIST) {
    return EventTrackerPathLocation.RESERVATION_LIST;
  }
  if (pathname === ROUTES.RESERVATIONS.DETAIL) {
    return EventTrackerPathLocation.RESERVATION_DETAIL;
  }
  return '';
};

export const getPromotionEventHospital = ({ hospitalList, hospitalId }: GetPromotionEventHospitalParams) => {
  return hospitalList.find((item) => item.id === hospitalId) || null;
};

export const getHospitalLabels = ({
  isIntolinkHospital,
  isReservationPossible,
  isAlliance,
  isPromotion,
}: GetHospitalLabelsParams) => {
  const labels = [];

  if (isReservationPossible && !isIntolinkHospital) {
    labels.push('간편예약');
  }
  if (isAlliance && !isIntolinkHospital) {
    labels.push('핏펫제휴');
  }
  if (isPromotion) {
    labels.push('프로모션');
  }

  return labels;
};

export const shuffleArray = (array: any[]) => {
  return array.sort(() => Math.random() - 0.5);
};

export const checkIsLargeDeviceWidth = (deviceWidth: number) => {
  return window.innerWidth >= deviceWidth;
};
