import { SEARCH_POSITION, SEARCH_POSITION_TYPE } from '@/api/review';
import { ANIMAL, Pageable, PAGINATION_SIZE } from '@/constants/common';
import { SORT_LIST_KEY } from '@/constants/filter';

export interface OptionItem {
  value: string;
  label: string;
}

export interface SelectOption {
  value: ANIMAL;
  label: string;
}

export interface SymptomItem {
  category: string;
  symptom: string;
}

export interface SymptomSearchParams {
  animal: ANIMAL;
  addressCode?: string;
  isPhotoReview: boolean;
  keyword: string;
  searchPosition: SEARCH_POSITION_TYPE;
  pageable: Pageable;
}

export interface SymptomItemWithCount {
  category: string;
  symptom: string;
  reviewCount: number;
}

export interface OptionList {
  value: ANIMAL;
  label: string;
}

export const OPTION_LIST = [
  { value: ANIMAL.DOG, label: '🐶' },
  { value: ANIMAL.CAT, label: '🐱' },
];

export const INITIAL_PARAMS = {
  animal: ANIMAL.DOG,
  isPhotoReview: false,
  keyword: '',
  searchPosition: SEARCH_POSITION.TAG,
  pageable: {
    page: 1,
    size: PAGINATION_SIZE,
    sort: SORT_LIST_KEY.RECENT_ORDER,
  },
} as SymptomSearchParams;
