import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useModalHistoryStore from '@/store/useModalHistoryStore';
// eslint-disable-next-line import/no-cycle
import { generateUUID } from '@/utils/commonUtils';

export interface UseModalProps {
  beforeModalHideAction?: () => void;
  initIsShowing?: boolean;
}

export interface ModalProps<D = unknown> extends UseModalProps {
  isShowing: boolean;
  data: D | undefined;

  setData(data: D): void;
  showModal(): void;
  hideModal(): void;
  setHideModalAfterCallbackFn(callbackFn: () => void): void;
}

export const useModal = <D>(props?: UseModalProps) => {
  const pushOrUpdateModalHistory = useModalHistoryStore(useCallback((state) => state.pushOrUpdate, []));
  const removeModalHistory = useModalHistoryStore(useCallback((state) => state.remove, []));
  const [modalHistoryKey] = useState<string>(generateUUID());
  const hideModalAfterCallbackFn = useRef<() => void>();
  const beforeModalHideAction = useCallback(() => props?.beforeModalHideAction?.(), [props]);
  const { initIsShowing } = props || {};
  const [isShowing, setIsShowing] = useState(!!initIsShowing);
  const [data, setData] = useState<D>();

  const hideModal = useCallback(() => {
    if (isShowing) {
      setIsShowing(false);
      beforeModalHideAction?.();
    }
  }, [beforeModalHideAction, isShowing]);

  const setHideModalAfterCallbackFn = useCallback((callbackFn: () => void) => {
    hideModalAfterCallbackFn.current = callbackFn;
  }, []);

  const showModal = useCallback(() => {
    if (!isShowing) {
      setIsShowing(true);
    }
  }, [isShowing]);

  useEffect(() => {
    if (isShowing) {
      pushOrUpdateModalHistory({ key: modalHistoryKey, hideModal });
    } else {
      removeModalHistory(modalHistoryKey);
      if (hideModalAfterCallbackFn.current) {
        hideModalAfterCallbackFn.current();
        hideModalAfterCallbackFn.current = undefined;
      }
    }
  }, [hideModal, isShowing, modalHistoryKey, pushOrUpdateModalHistory, removeModalHistory]);

  useEffect(() => {
    return () => {
      removeModalHistory(modalHistoryKey);
    };
    // Do not add dependency. Because clean up function is must be called when component unmount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const modalProps: ModalProps<D> = useMemo(() => {
    return {
      isShowing,
      data,
      setData,
      showModal,
      hideModal,
      setHideModalAfterCallbackFn,
    };
  }, [data, hideModal, isShowing, setHideModalAfterCallbackFn, showModal]);

  return modalProps;
};
