import styled from '@emotion/styled';
import { FC } from 'react';
import { StyledTypeFaceHeadingProps, TypeFaceHeadingProps } from './typeface.type';
import { FONT_STYLE } from '@/constants/typeface';

export const H4: FC<TypeFaceHeadingProps> = ({ children, fontColor, className }) => {
  return (
    <StyledText fontColor={fontColor} className={className}>
      {children}
    </StyledText>
  );
};

const StyledText = styled.h4<StyledTypeFaceHeadingProps>`
  ${FONT_STYLE.H4}
  color: ${(props) => props.fontColor};
`;
