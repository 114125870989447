import create from 'zustand';

interface UseReviewLikeIdListStore {
  myReviewLikedIdList: Set<number>;
  setMyReviewLikedIdList: (idList: number[]) => void;
  isContainsReviewLikedId: (reviewId: number) => boolean;
  addReviewLiked: (reviewId: number) => void;
  removeReviewLiked: (reviewId: number) => boolean;
  clearReviewLikedList: () => void;
}

const useReviewLikeIdListStore = create<UseReviewLikeIdListStore>((set, get) => ({
  myReviewLikedIdList: new Set(),
  setMyReviewLikedIdList: (idList: number[]) =>
    set(() => {
      const reviewLikeIdList = new Set<number>();

      idList.map((id: number) => reviewLikeIdList.add(id));
      return {
        myReviewLikedIdList: reviewLikeIdList,
      };
    }),
  isContainsReviewLikedId: (reviewId: number) => get().myReviewLikedIdList.has(reviewId),
  addReviewLiked: (reviewId: number) => {
    const temp = new Set(get().myReviewLikedIdList);
    temp.add(reviewId);
    set(() => ({
      myReviewLikedIdList: temp,
    }));
  },
  removeReviewLiked: (reviewId: number) => {
    try {
      const temp = new Set(get().myReviewLikedIdList);
      temp.delete(reviewId);
      set(() => ({
        myReviewLikedIdList: temp,
      }));
      return true;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      return false;
    }
  },
  clearReviewLikedList: () => set(() => ({ myReviewLikedIdList: new Set() })),
}));

export default useReviewLikeIdListStore;
