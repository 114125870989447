import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useQueryClient } from 'react-query';
import { GetChangedReservationResponse, SERVICE_STATUS_VALUE } from '@/api/reservation';
import ConfirmModal from '@/components/modal/ConfirmModal';
import UpdatedReservationModalContent from '@/components/reservation/screen/UpdatedReservationModalContent';
import { QUERY_KEYWORD } from '@/constants/queryKey';
import { ROUTE_QUERY_KEYS, ROUTES } from '@/constants/routes';
import useAppRouter from '@/hooks/useAppRouter';
import useEventTrackerHome from '@/hooks/useEventTrackerHome';
import { useModal } from '@/hooks/useModal';
import { useGetReservationChangedStatus } from '@/queries/query/useReservationQuery';
import useCheckLogin from '@/utils/bridge/useCheckLogin';
import { RESERVATION_TYPE } from '@/utils/tracker/constants';
import { isInAppFlag } from '@/utils/utilBridge';
import webStorage from '@/utils/webStorage';

interface ReservationProviderProps {
  children: ReactNode;
}

interface ReservationProviderContextProps {
  reservationData: GetChangedReservationResponse[];
}

/**
 * @description
 * 예약 상태에 따라 업데이트된 내역이 있는지 분류한다.
 * 리스트 탭의 뱃지를 표시하기 위해 분류한다.
 * @param reservations
 */
const sortByServiceStatus = (reservations: GetChangedReservationResponse[]): { [key: string]: boolean } => {
  const analysis = {
    [SERVICE_STATUS_VALUE.APPLIED]: false,
    [SERVICE_STATUS_VALUE.CONFIRMED]: false,
    [SERVICE_STATUS_VALUE.VISITED]: false,
    [SERVICE_STATUS_VALUE.CANCELLED]: false,
    [SERVICE_STATUS_VALUE.NO_SHOW]: false,
  };

  reservations.forEach((reservation) => {
    const { serviceStatus } = reservation;
    if (serviceStatus === SERVICE_STATUS_VALUE.APPLIED) {
      analysis[SERVICE_STATUS_VALUE.APPLIED] = true;
    }
    if (serviceStatus === SERVICE_STATUS_VALUE.CONFIRMED) {
      analysis[SERVICE_STATUS_VALUE.CONFIRMED] = true;
    }
    if (serviceStatus === SERVICE_STATUS_VALUE.VISITED) {
      analysis[SERVICE_STATUS_VALUE.VISITED] = true;
    }
    if (serviceStatus === SERVICE_STATUS_VALUE.CANCELLED) {
      analysis[SERVICE_STATUS_VALUE.CANCELLED] = true;
    }
    if (serviceStatus === SERVICE_STATUS_VALUE.NO_SHOW) {
      analysis[SERVICE_STATUS_VALUE.CANCELLED] = true;
      analysis[SERVICE_STATUS_VALUE.NO_SHOW] = true;
    }
  });

  return analysis;
};

const getReservationStatus = (reservation: GetChangedReservationResponse): RESERVATION_TYPE | undefined => {
  const { serviceStatus, isChangedApprove } = reservation;
  if (serviceStatus === SERVICE_STATUS_VALUE.CONFIRMED) {
    if (isChangedApprove?.isChangedClinic || isChangedApprove?.isChangedDate || isChangedApprove?.isChangedTime) {
      return RESERVATION_TYPE.RESERVATION_MODIFY;
    }
    return RESERVATION_TYPE.RESERVATION_CONFIRM;
  }
  if (serviceStatus === SERVICE_STATUS_VALUE.CANCELLED) {
    return RESERVATION_TYPE.RESERVATION_CANCEL;
  }
  return undefined;
};

const ReservationProviderContext = createContext<ReservationProviderContextProps>({
  reservationData: [],
});

const ReservationProvider = ({ children }: ReservationProviderProps) => {
  const queryClient = useQueryClient();
  const checkReservationModalProps = useModal();
  const router = useAppRouter();
  const { isLogin } = useCheckLogin();
  const isHandleBackKey = useRef<boolean>(true);
  const {
    data: reservationChangedStatusData,
    refetch: refetchReservationChangedStatus,
    isSuccess: isReservationChangedStatusDataSuccess,
  } = useGetReservationChangedStatus(isLogin());
  const { trackingViewHospitalReviewReservationPopup, trackingClickHospitalReviewReservationPopup } =
    useEventTrackerHome();

  const [reservationProviderState, setReservationProviderState] = useState<ReservationProviderContextProps>({
    reservationData: [],
  } as ReservationProviderContextProps);
  const [updatedReservations, setUpdatedReservations] = useState<GetChangedReservationResponse[]>([]);
  const [currentModalData, setCurrentModalData] = useState<GetChangedReservationResponse>();

  const modalConfirmText = useMemo(() => {
    return currentModalData?.serviceStatus === SERVICE_STATUS_VALUE.CANCELLED ? '자세히' : '예약 내역 보기';
  }, [currentModalData?.serviceStatus]);

  /**
   * @description
   * 모달의 데이터 및 액션 등을 초기화 한다.
   */
  const handleResetModal = useCallback(() => {
    window.onbackkey = window.onbackkeyDefault;
    checkReservationModalProps.hideModal();
    setCurrentModalData({} as GetChangedReservationResponse);
  }, [checkReservationModalProps]);

  /**
   * @description
   * 모달에 표시될 다음 데이터가 없다면 모달을 초기화 하고 있다면 첫번째 데이터를 모달에 넣어준다.
   */
  const handleSetNextModalData = useCallback(() => {
    if (!updatedReservations?.[0]) {
      handleResetModal();
      return;
    }

    const reservationData = updatedReservations?.[0];

    setCurrentModalData(reservationData);
    trackingViewHospitalReviewReservationPopup({
      locationId: reservationData?.hospitalId,
      locationName: reservationData?.hospitalName,
      popupType: getReservationStatus(reservationData),
      popupText: reservationData?.reason,
      petType: reservationData?.petType,
    });
    checkReservationModalProps.showModal();
  }, [updatedReservations]);

  /**
   * @description
   * reservation status 가 cancelled 일 경우와 아닐 경우에 대한 확인 버튼 클릭 시의 동작을 정의한다.
   * @param currentReservationData: 현재 modal 의 reservation data
   */
  const handleCheckReservationModalConfirm = (currentReservationData: GetChangedReservationResponse) => {
    setUpdatedReservations([]);
    handleResetModal();

    trackingClickHospitalReviewReservationPopup({
      locationId: currentReservationData?.hospitalId,
      locationName: currentReservationData?.hospitalName,
      popupType: getReservationStatus(currentReservationData),
      popupText: currentReservationData?.reason,
      petType: currentReservationData?.petType,
      buttonText: modalConfirmText,
    });

    // 취소 상태일 경우, 상세 페이지로 이동한다.
    if (currentReservationData.serviceStatus === SERVICE_STATUS_VALUE.CANCELLED) {
      router.push({
        pathname: ROUTES.RESERVATIONS.DETAIL,
        query: {
          [ROUTE_QUERY_KEYS.RESERVATION_ID]: Number(currentReservationData.id),
        },
      });
      return;
    }

    // 취소 상태가 아닐 경우, 내역페이지로 이동한다.
    router.push({
      pathname: ROUTES.RESERVATIONS.LIST,
      query: { ...router.query, tabName: currentReservationData.serviceStatus },
    });
  };

  /**
   * @description
   * 변경된 예약 데이터가 남아 있을 경우 남은 예약 데이터를 state 에 업데이트 시키고, 없을 경우에는 모달을 초기화 한다.
   */
  const handleCheckReservationModalCancel = useCallback(
    (currentReservationData?: GetChangedReservationResponse) => {
      if (currentReservationData) {
        trackingClickHospitalReviewReservationPopup({
          locationId: currentReservationData?.hospitalId,
          locationName: currentReservationData?.hospitalName,
          popupType: getReservationStatus(currentReservationData),
          popupText: currentReservationData?.reason,
          petType: currentReservationData?.petType,
          buttonText: '닫기',
        });
      }

      const [, ...remainingReservations] = updatedReservations || [];

      if (!updatedReservations?.length) {
        handleResetModal();
        return;
      }

      setUpdatedReservations(remainingReservations);
    },
    [checkReservationModalProps, updatedReservations],
  );

  /**
   * 모바일에서 물리 백 키를 눌렀을 때에 대한 핸들링 함수
   */
  const handleBackKey = useCallback(() => {
    if (!isHandleBackKey.current) {
      return false;
    }

    handleCheckReservationModalCancel(currentModalData);
    return true;
  }, [isHandleBackKey, handleCheckReservationModalCancel]);

  /**
   * @description
   * api 로 변경된 예약 데이터가 받아올 때 실행.
   * webStorage 를 이용하여 변경된 예약 데이터를 저장한다.
   */
  useEffect(() => {
    const reservationData = reservationChangedStatusData?.data || ([] as GetChangedReservationResponse[]);

    if (!isReservationChangedStatusDataSuccess) {
      return;
    }

    setReservationProviderState((prevState) => ({
      ...prevState,
      reservationData,
    }));

    // 초기 상태라면 reservation data 를 담기만 하고 종료
    if (!webStorage.getReservationProviderInitialized()) {
      webStorage.setReservationProviderInitialized(true);
      webStorage.setLastChangedReservations(JSON.stringify(reservationData));
      return;
    }

    // 이전에 저장해 놓은 변경된 예약 데이터를 가져온다.
    const lastChangedReservations = JSON.parse(
      webStorage.getLastChangedReservations() || '[]',
    ) as GetChangedReservationResponse[];

    // 이전에 저장된 변경 예약들과 새로 변경된 예약 데이터를 비교하여 새로 변경된 사항들만 담는 array 를 만듬 (비회원 마이그레이션 내용은 제외)
    const newUpdatedReservations = reservationData?.filter((newData) => {
      const previousItem = lastChangedReservations?.find((prevData) => prevData.id === newData.id);
      return (
        (!previousItem || JSON.stringify(previousItem) !== JSON.stringify(newData)) &&
        [SERVICE_STATUS_VALUE.CONFIRMED, SERVICE_STATUS_VALUE.CANCELLED].includes(
          SERVICE_STATUS_VALUE[newData.serviceStatus],
        ) &&
        !newData.isChangedApprove?.isChangedNonUser
      );
    });

    // 새로 변경된 데이터를 이전의 데이터와 합쳐서 webStorage 에 저장하고 state 를 업데이트 한다.
    webStorage.setLastChangedReservations(JSON.stringify(reservationData));
    setUpdatedReservations([...(updatedReservations || []), ...newUpdatedReservations]);
  }, [reservationChangedStatusData?.data]);

  /**
   * @description
   * 변경된 예약 데이터 state 가 업데이트 되었을 때 실행.
   * 변경된 예약 데이터가 없다면 모달을 초기화하고 예약 내역 리스트를 새로 불러온다.
   * 앱일 경우에는 Back Key 이벤트 추가.
   * 새로운 변경 내역이 있다면 다음 모달을 세팅하는 함수를 호출한다..
   */
  useEffect(() => {
    if (!updatedReservations.length) {
      queryClient.refetchQueries([QUERY_KEYWORD.MY, QUERY_KEYWORD.RESERVATION, QUERY_KEYWORD.LIST]);
      refetchReservationChangedStatus();
      handleResetModal();
      return;
    }
    if (isInAppFlag) {
      window.onbackkey = handleBackKey;
    }
    handleSetNextModalData();
  }, [updatedReservations]);

  /**
   * 페이지가 변경될 때 api 를 새로 요청하여 데이터 상태를 체크하고 뱃지를 업데이트한다.
   */
  useEffect(() => {
    if (isLogin()) {
      refetchReservationChangedStatus();
    }
  }, [router.pathname, refetchReservationChangedStatus, isLogin]);

  return (
    <ReservationProviderContext.Provider value={reservationProviderState}>
      {children}
      {currentModalData && checkReservationModalProps.isShowing && (
        <ConfirmModal
          modalProps={checkReservationModalProps}
          contentsOutSideTouchClosed={false}
          contents={<UpdatedReservationModalContent content={currentModalData} />}
          confirmText={modalConfirmText}
          cancelText={'닫기'}
          onConfirm={() => handleCheckReservationModalConfirm(currentModalData)}
          onCancel={() => handleCheckReservationModalCancel(currentModalData)}
        />
      )}
    </ReservationProviderContext.Provider>
  );
};

export default ReservationProvider;

export const useUpdatedReservationData = () => {
  const { reservationData = [] } = useContext(ReservationProviderContext);

  return {
    reservationData,
    changedStatus: sortByServiceStatus(reservationData),
  };
};
