import { useRouter } from 'next/router';
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { isPathnameHome } from '@/constants/routes';
// import useScrollInfoStore from '@/store/useScrollInfoStore';

type RouteStackProviderState = {
  beforeUrl?: string;
  hasPrevPage: number;
};

const getUrlPath = (url: string) => {
  const queryStringIndex = url.indexOf('?');
  if (queryStringIndex < 0) {
    return url;
  }
  return url.slice(0, queryStringIndex);
};

const RouteStackStateContext = createContext<RouteStackProviderState>({ hasPrevPage: 0 });

export const RouteStackProvider = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const [state, setState] = useState<RouteStackProviderState>({ hasPrevPage: 0 });
  // const { initScrollInfo, removeScrollInfo } = useScrollInfoStore();

  // 이동하는 nextUrl의 pathname이 동일(페이지 내에서 파라미터가 달라지는 탭을 클릭한 경우 등)하면 페이지 이동이 없었다고 간주한다.
  const isNeedAddPageCount = useCallback(
    (nextUrl: string) => {
      const nextUrlPath = getUrlPath(nextUrl);
      return !isPathnameHome(nextUrlPath) && nextUrlPath !== router.pathname;
    },
    [router.pathname],
  );

  useEffect(() => {
    // 페이지를 뒤로 이동할 경우 실행되는 이벤트
    // 뒤로 이동하는 경우에도 '페이지 이동'이므로 handleRouteChange에서 state가 +1이 된다. 그러므로 -2를 해야 숫자가 줄어든다.
    router.beforePopState(() => {
      // if (isScrollInfoSavePath(router.pathname)) {
      //   // 뒤로 이동시
      //   removeScrollInfo(router.pathname);
      // }

      setState((prev) => ({ ...prev, hasPrevPage: prev.hasPrevPage - 2 }));
      return true;
    });
  }, [router]);

  useEffect(() => {
    const handleRouteChange = (nextUrl: string, options: { shallow: boolean }) => {
      if (isPathnameHome(nextUrl)) {
        setState((prev) => {
          return { ...prev, beforeUrl: router.asPath, hasPrevPage: 0 };
        });
        // initScrollInfo(); // 홈으로 이동 시 스크롤 스택 초기화
      } else if (!options.shallow && nextUrl !== router.asPath) {
        setState((prev) => ({
          ...prev,
          beforeUrl: router.asPath,
          hasPrevPage: isNeedAddPageCount(nextUrl) ? prev.hasPrevPage + 1 : prev.hasPrevPage,
        }));
      }
    };

    router.events.on('beforeHistoryChange', handleRouteChange);

    return () => {
      router.events.off('beforeHistoryChange', handleRouteChange);
    };
  }, [isNeedAddPageCount, router]);

  return <RouteStackStateContext.Provider value={state}>{children}</RouteStackStateContext.Provider>;
};

export const useGetBeforeUrl = () => {
  const { beforeUrl } = useContext(RouteStackStateContext);
  return beforeUrl;
};

export const useGetHasPrevPage = () => {
  const { hasPrevPage } = useContext(RouteStackStateContext);
  return hasPrevPage;
};
