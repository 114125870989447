export const OutlinkPattern = /(http(s?)):\/\//i;
export const KoreanElementPattern = /([ㄱ-ㅎㅏ-ㅣ])/;
export const ParsePathPattern = /([^\s]+)((\/[\s]+)*(\/[a-z][^/?]*))/;
export const NumberRegex = /^[0-9]+$/;
export const PhoneNumberRegex = /^0[0-9]+$/;
// note: a=Avalue; b=bValue; c=cValue -> GetCookieString(a) -> a=Avalue
// note: a=Avalue; b=bValue; c=cValue -> GetCookieString(b) -> ; b=Avalue
export const getCookieStringPattern = (key: string) =>
  new RegExp(`(?:^|; )${key.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`);

// 행의 첫 공백 없애기 : '_안녕' -> '안녕'
export const removeFirstSpace = (string: string) => string.replace(/^\s/gm, '');
// 더블 스페이스를 한 개로 만들기 : '안녕__하세요' -> '안녕_하세요'
export const removeDoubleSpace = (string: string) => string.replace(/ {2}/g, ' ');
export const textRegExp = /([{}[\]/?.,;:|)*~`!^\-_+<>@#$%&\\=('"])/g;
export const textExceptHashRegExp = /([{}[\]/?.,;:|)*~`!^\-_+<>@$%&\\=('"])/g;
export const hashRegExp = /#/g;

export const PlatformPattern = {
  RN_iOS: /fitpetApp_ios/,
  RN_Android: /fitpetApp_android/,
  iOS: /fitpetIosApp/,
  Android: /fitpetAndroidApp/,
  AndroidWeb: /Android/,
  iOSWeb: /iPhone/,
  MobileWeb: /Mobile|mobile/,
};
