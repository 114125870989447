import styled from '@emotion/styled';
import { FC } from 'react';
import { StyledTypeFaceCaptionProps, TypeFaceCaptionProps } from './typeface.type';
import { FONT_STYLE } from '@/constants/typeface';

export const Caption2: FC<TypeFaceCaptionProps> = ({ children, fontWeight, fontColor, className }) => {
  return (
    <StyledText fontWeight={fontWeight} fontColor={fontColor} className={className}>
      {children}
    </StyledText>
  );
};

const StyledText = styled.span<StyledTypeFaceCaptionProps>`
  ${FONT_STYLE.CAPTION_2}
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.fontColor};
`;
