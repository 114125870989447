import React from 'react';
import FlatIconList from '@/constants/flatIconList';
import { FlatIconProps } from '@/constants/icon';
import { GLOBAL_COLOR } from '@/styles/colors';
import { getWidthHeight } from '@/utils/layoutUtils';

const FlatIcon = ({ iconType, size = 16, color = GLOBAL_COLOR.GRAY_500 }: FlatIconProps) => {
  const SelectedIcon = FlatIconList[iconType];
  const [width, height] = getWidthHeight(size);

  return (
    <i style={{ color, display: 'flex', width, height }}>
      {/* @ts-ignore */}
      {SelectedIcon && <SelectedIcon width={width} height={height} style={{ overflow: 'visible' }} />}
    </i>
  );
};

export default React.memo(FlatIcon);
