import { ReactNode } from 'react';
import { InAppProvider } from './InAppProvider';
import ReservationProvider from '@/context/ReservationProvider';
import { RouteStackProvider } from '@/context/RouteStackProvider';
import useEventTracking from '@/hooks/useEventTracking';

interface FitpetHospitalProviderProps {
  children: ReactNode;
}

export const FitpetReservationProvider = ({ children }: FitpetHospitalProviderProps) => {
  useEventTracking();
  return (
    <RouteStackProvider>
      <InAppProvider>
        <ReservationProvider>{children}</ReservationProvider>
      </InAppProvider>
    </RouteStackProvider>
  );
};
