import TagManager, { DataLayerArgs, TagManagerArgs } from 'react-gtm-module';
import { isInApp } from '@/utils/deviceUtils';
import { EventLogger } from '@/utils/tracker/handlers/base';
import { EventType } from '@/utils/tracker/model';

type GtmEventMapType = Record<string, string>;

export class GTMHandler {
  private static isInitialized = false;

  private static logger: EventLogger = new EventLogger('GTMHandler');

  private static tagManagerArgs: TagManagerArgs = {
    gtmId: 'GTM-NG3RD96',
    auth: process.env.NEXT_PUBLIC_GTM_AUTH,
    preview: process.env.NEXT_PUBLIC_GTM_PREVIEW,
  };

  private static gtmEventMap: GtmEventMapType = {
    // [FitpetMallEvent.VIEW_HOME]: 'view_home',
    // [FitpetMallEvent.VIEW_PRODUCTDETAIL]: 'view_productdetail',
    // [FitpetMallEvent.VIEW_CART]: 'view_cart',
    // [FitpetMallEvent.CLICK_PRODUCTDETAIL_ADDTOCART_BUTTON]: 'add_to_cart',
    // [FitpetMallEvent.CLICK_CART_PAYMENT_BUTTON]: 'init_checkout',
    // [FitpetMallEvent.VIEW_SECTION_LIST_ITEMS]: 'view_productlist',
    // [FitpetMallEvent.COMPLETE_SIGNUP]: 'join_complete',
  };

  private static eventList: Array<EventType> = [
    // FitpetMallEvent.VIEW_HOME,
    // FitpetMallEvent.VIEW_PRODUCTDETAIL,
    // FitpetMallEvent.VIEW_CART,
    // FitpetMallEvent.CLICK_PRODUCTDETAIL_ADDTOCART_BUTTON,
    // FitpetMallEvent.CLICK_CART_PAYMENT_BUTTON,
    // FitpetMallEvent.VIEW_SECTION_LIST_ITEMS,
    // FitpetMallEvent.COMPLETE_SIGNUP,
  ];

  static initialize = () => {
    if (!GTMHandler.isInitialized) {
      TagManager.initialize(GTMHandler.tagManagerArgs);
      GTMHandler.logger.info(`TagManager initialized: ${JSON.stringify(GTMHandler.tagManagerArgs)}`);
    }
    GTMHandler.isInitialized = true;
  };

  static setUserID = (userID: string) => {
    GTMHandler.logger.info(`set userId datalayer variable: ${userID}`);
    const dataLayerArgs: DataLayerArgs = {
      dataLayer: { userId: userID },
    };
    TagManager.dataLayer(dataLayerArgs);
  };

  static setUserProperties = (/* properties: any */) => {};

  static handlePageviewEvent = (url: string) => {
    GTMHandler.logger.info(`trigger 'virtual_pageview' event for url: ${url}`);
    const dataLayerArgs: DataLayerArgs = {
      dataLayer: { event: 'virtual_pageview' },
    };
    TagManager.dataLayer(dataLayerArgs);
  };

  static handleEvent = (event: EventType, properties: any) => {
    if (GTMHandler.eventList.includes(event)) {
      GTMHandler.triggerEvent(event, properties);
    } else {
      GTMHandler.logger.info(`will not handle '${event}', for is not in ${GTMHandler.eventList}`);
    }
  };

  private static triggerEvent(event: EventType, eventParams: Record<string, any>): void {
    const gtmEvent = this.gtmEventMap[event];
    const dataLayerArgs: DataLayerArgs = {
      dataLayer: {
        event: gtmEvent,
        eventParams,
        inapp: isInApp(),
      },
    };
    TagManager.dataLayer(dataLayerArgs);
    this.logger.info(`'${gtmEvent}' triggered: ${JSON.stringify(dataLayerArgs)}`);
  }
}
