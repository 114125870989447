import { DEVICE_PLATFORM, DEVICE_PLATFORM_TYPE } from '@/constants/common';
import { PlatformPattern } from '@/utils/regex';

export const isClient = () => typeof window !== 'undefined';

/**
 * IE 브라우저 판별 함수. document.documentMode는 IE에만 존재한다
 *
 * @deprecated
 * 더이상 iE를 쓰지 않으므로 deprecated
 */
export const isIE = () => !!window.document.documentMode;

/**
 * @deprecated
 * `utils/device`로 대체
 */
export enum PlatformDetail {
  ANDROID_APP = 'android_app',
  IOS_APP = 'ios_app',
  ANDROID_WEB = 'android_web',
  IOS_WEB = 'ios_web',
  PC_WEB = 'pc_web',
  ETC = 'etc',
  UNKNOWN = 'unknown',
}

/**
 * @deprecated
 * `utils/device`로 대체
 */
export const getPlatform = (userAgent: string = navigator?.userAgent) => {
  if (PlatformPattern.iOS.test(userAgent) || PlatformPattern.RN_iOS.test(userAgent)) {
    return PlatformDetail.IOS_APP;
  }
  if (PlatformPattern.Android.test(userAgent) || PlatformPattern.RN_Android.test(userAgent)) {
    return PlatformDetail.ANDROID_APP;
  }
  if (PlatformPattern.AndroidWeb.test(userAgent)) {
    return PlatformDetail.ANDROID_WEB;
  }
  if (PlatformPattern.iOSWeb.test(userAgent)) {
    return PlatformDetail.IOS_WEB;
  }
  if (!PlatformPattern.MobileWeb.test(userAgent)) {
    return PlatformDetail.PC_WEB;
  }
  // PC 구분 어떻게?
  return PlatformDetail.ETC;
};

const DEVICE_PLATFORM_MAPPING = {
  [PlatformDetail.IOS_APP]: DEVICE_PLATFORM.IOS,
  [PlatformDetail.ANDROID_APP]: DEVICE_PLATFORM.ANDROID,
  [PlatformDetail.PC_WEB]: DEVICE_PLATFORM.WEB,
  [PlatformDetail.IOS_WEB]: DEVICE_PLATFORM.IOS_WEB,
  [PlatformDetail.ANDROID_WEB]: DEVICE_PLATFORM.ANDROID_WEB,
  [PlatformDetail.ETC]: DEVICE_PLATFORM.UNKNOWN,
};

/**
 * @deprecated
 * `utils/device`로 대체
 */
export const getDevicePlatform = (): DEVICE_PLATFORM_TYPE => {
  const platform = getPlatform();
  return platform in DEVICE_PLATFORM_MAPPING ? DEVICE_PLATFORM_MAPPING[platform] : DEVICE_PLATFORM.UNKNOWN;
};

/**
 * @deprecated
 * `utils/device`로 대체
 */
export const isInApp = () => isClient() && [PlatformDetail.ANDROID_APP, PlatformDetail.IOS_APP].includes(getPlatform());
/**
 * @deprecated
 * `utils/device`로 대체
 */
export const isInAndroid = () => isClient() && [PlatformDetail.ANDROID_APP].includes(getPlatform());
/**
 * @deprecated
 * `utils/device`로 대체
 */
export const isInIos = () => isClient() && [PlatformDetail.IOS_APP].includes(getPlatform());
