import { camelize, decamelize } from '@ridi/object-case-converter';
import { PAGEABLE_KEY } from '@/constants/common';

export enum PARAM_CASE_TYPE {
  CAMEL_CASE = 'camelCase',
  SNAKE_CASE = 'snakeCase',
}

const appendQueryString = (queryString: string, key: string, value: string): string => {
  return `${queryString}${queryString.length > 0 ? '&' : ''}${key}=${value}`;
};
export const getQueryParams = (params: { [key: string]: any }, paramCaseType?: PARAM_CASE_TYPE): string => {
  let queryString = '';
  Object.entries(params).forEach(([key, value]) => {
    if (value) {
      let paramKey = key;
      if (paramCaseType === PARAM_CASE_TYPE.CAMEL_CASE) {
        paramKey = camelize(key);
      } else if (paramCaseType === PARAM_CASE_TYPE.SNAKE_CASE) {
        paramKey = decamelize(key);
      }
      if (paramKey === PAGEABLE_KEY.PAGEABLE && typeof value === 'object') {
        queryString = appendQueryString(queryString, PAGEABLE_KEY.PAGE, value[PAGEABLE_KEY.PAGE]);
        queryString = appendQueryString(queryString, PAGEABLE_KEY.SIZE, value[PAGEABLE_KEY.SIZE]);
        queryString = appendQueryString(queryString, PAGEABLE_KEY.SORT, value[PAGEABLE_KEY.SORT]);
      } else {
        queryString = appendQueryString(queryString, paramKey, value);
      }
    }
  });
  return encodeURI(queryString);
};
