import create from 'zustand';
import { Tracker } from '@/utils/tracker';

interface EventTrackingType {
  tracker: Tracker;
  setEventTracking: (newTracker: Tracker) => void;
}

const useEventTrackingStore = create<EventTrackingType>((set) => ({
  tracker: new Tracker({}),
  setEventTracking: (newTracker: Tracker) => {
    set((state) => ({ ...state, tracker: newTracker }));
  },
}));

export default useEventTrackingStore;
