import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React, { MouseEventHandler, ReactNode, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useBodyScrollLockEffect } from '@/hooks/useBodyScrollLockEffect';
import { ModalProps } from '@/hooks/useModal';
import { MODAL_BACKGROUND } from '@/styles/colors';
import { Z_INDEX } from '@/styles/zIndex';

export enum PortalTarget {
  BOTTOM_SHEET_ROOT = 'bottom-sheet-root',
  LOGIN_MODAL_ROOT = 'login-modal-root',
  MODAL_ROOT = 'modal-root',
}

interface BaseModalProps<D> {
  modalProps: ModalProps<D>;
  children?: ReactNode;
  duration?: number;
  backgroundColor?: string;
  portalTarget?: PortalTarget;
  contentsOutSideTouchClosed?: boolean;
  isAutoHeight?: boolean;
  onContentsOutSideClick?: () => void;
}

const BaseModal = <D extends any>({
  modalProps,
  children,
  duration = 0,
  backgroundColor,
  portalTarget,
  contentsOutSideTouchClosed = false,
  isAutoHeight = false,
  onContentsOutSideClick,
}: BaseModalProps<D>) => {
  useBodyScrollLockEffect(modalProps.isShowing);
  const wrapperRef = useRef(null);
  const [portalElement, setPortalElement] = useState<HTMLElement | null>(null);

  useEffect(() => {
    setPortalElement(document.getElementById(portalTarget || PortalTarget.MODAL_ROOT));
  }, [portalTarget]);

  if (!portalElement) {
    return <></>;
  }

  const handleContentOutsideClick: MouseEventHandler = (e) => {
    if (e.target === wrapperRef.current) {
      if (contentsOutSideTouchClosed) {
        modalProps.hideModal();

        if (onContentsOutSideClick) {
          onContentsOutSideClick();
        }
      }
    }
  };

  return ReactDOM.createPortal(
    <BaseContainer isShow={modalProps.isShowing} duration={duration} isAutoHeight={isAutoHeight}>
      <Background isShow={modalProps.isShowing} backgroundColor={backgroundColor} duration={duration} />
      <Wrapper ref={wrapperRef} onClick={handleContentOutsideClick}>
        {children}
      </Wrapper>
    </BaseContainer>,
    portalElement,
  );
};

export default BaseModal;

interface StyleParams {
  isShow: boolean;
  duration: number;
  backgroundColor?: string;
  isAutoHeight?: boolean;
}

const BaseContainer = styled.section(
  ({ isShow, isAutoHeight }: StyleParams) => css`
    position: fixed;
    width: 100%;
    ${isAutoHeight ? { bottom: 0, height: 'auto' } : { top: 0, height: '100vh' }}
    left: 0;
    z-index: ${Z_INDEX.MODAL_CONTAINER};
    display: ${isShow ? 'block' : 'none'};
  `,
);

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: ${Z_INDEX.MODAL_CONTENT};
`;

const Background = styled.div(
  ({ isShow, backgroundColor, duration }: StyleParams) => css`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: ${backgroundColor || MODAL_BACKGROUND};
    z-index: ${Z_INDEX.MODAL_BACKGROUND};
    animation: ${duration}ms ${showBackground};

    ${!isShow &&
    css`
      animation: ${duration}ms ${hideBackground} forwards;
    `}
  `,
);

const showBackground = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const hideBackground = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;
