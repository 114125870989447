import styled from '@emotion/styled';
import Text from '@/components/typeface';

const TextTitle = styled(Text.H6)`
  text-align: center;
  word-break: keep-all;
  white-space: pre-wrap;
`;

export default TextTitle;
