// This file configures the initialization of Sentry on the server.
// The config you add here will be used whenever the server handles a request.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import { CaptureConsole, ExtraErrorData } from '@sentry/integrations';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://259580dd86c64773993f70eba3c91760@o356934.ingest.sentry.io/4503965024059392',
  environment: process.env.NODE_ENV,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,
  ignoreErrors: [
    'Loading initial props cancelled',
    'Failed to load script',
    'Failed to load static props',
    'Abort fetching component for route',
    'Request failed with status code 400',
    'Request failed with status code 401',
    'Request failed with status code 555',
  ],
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  attachStacktrace: true,
  sendDefaultPii: true,
  integrations: [new CaptureConsole({ levels: ['error'] }), new ExtraErrorData({ depth: 3 })],
});
