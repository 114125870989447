import { getQueryParams } from './apiUtils';
import { BASE_URL, HTTP } from '@/api/http';
import { ReservationTimeTableKey } from '@/components/reservation/common/ReservationTimeTableCard';
import { LabelType, Pageable } from '@/constants/common';
import { PetType } from '@/utils/tracker/constants';

const SERVICE_TYPE = {
  VACCINATION: '예방접종',
  HEALTH_SCREENING: '건강검진',
  DIAGNOSIS: '일반진료',
  CONSULTING: '상담진료',
};

export const SERVICE_STATUS = {
  APPLIED: '예약 대기',
  CONFIRMED: '예약 확정',
  VISITED: '방문 완료',
  CANCELLED: '예약 취소',
  NO_SHOW: '미방문',
};

export enum SERVICE_STATUS_VALUE {
  APPLIED = 'APPLIED', // 예약 대기
  CONFIRMED = 'CONFIRMED', // 예약 확정
  VISITED = 'VISITED', // 방문 완료
  CANCELLED = 'CANCELLED', // 예약 취소
  NO_SHOW = 'NO_SHOW', // 미방문
}

export const RESERVATION_TAB_TYPE: LabelType[] = [
  { label: '예약 대기', value: SERVICE_STATUS_VALUE.APPLIED },
  { label: '예약 확정', value: SERVICE_STATUS_VALUE.CONFIRMED },
  { label: '방문 완료', value: SERVICE_STATUS_VALUE.VISITED },
  { label: '취소/미방문', value: SERVICE_STATUS_VALUE.CANCELLED || SERVICE_STATUS_VALUE.NO_SHOW },
];

export type ServiceType = keyof typeof SERVICE_TYPE;
export type ServiceStatus = keyof typeof SERVICE_STATUS;
export type ReservationStatus =
  | SERVICE_STATUS_VALUE.CONFIRMED
  | SERVICE_STATUS_VALUE.APPLIED
  | SERVICE_STATUS_VALUE.VISITED
  | SERVICE_STATUS_VALUE.CANCELLED
  | SERVICE_STATUS_VALUE.NO_SHOW;

export type ReservationUpdateType = 'APP' | 'WEB' | 'SYSTEM';

export interface ReservationIsChangedApprove {
  id: number;
  isChangedClinic?: boolean;
  isChangedDate?: boolean;
  isChangedTime?: boolean;
  isChangedStatus?: boolean;
  isChangedNonUser?: boolean;
}

export interface GetHospitalAvailableDate {
  hospitalId: number;
  startDate: string;
  endDate: string;
  memberId?: number; // TODO: 현재는 옵셔널하게 설정해놓았지만 추후에 인투링크 예약 백엔드, 프론트 모두 배포 후에는 필수값으로 바꿔야 함
}

export interface GetHospitalMembersParams {
  hospitalId: number | undefined;
  desiredDate: string | Date | undefined;
}

export interface GetHospitalAvailableHoursParams extends GetHospitalMembersParams {
  memberId?: number;
}

export interface GetReservationDetailParams {
  reservationId: number;
}

export interface GetMyReservationsParams {
  userId?: number;
  petIds?: number[];
  serviceStatus?: ServiceStatus;
  reservationTime?: string;
  page?: number;
  size?: number;
  sort?: string[];
}

export interface CreateReservationParams {
  petId: number | undefined;
  hospitalId: number | undefined;
  hospitalMemberId: number | undefined;
  reservationTime: string | undefined;
  userRequest: string | undefined;
  reserveType: string | undefined;
  isKeepRequest: boolean | undefined;
  messageToUser?: string;
}

export interface CreateReservationResponse {
  id: number;
  petId: number;
  serviceType: string;
  serviceStatus: string;
  reservationTime: string;
  hospitalMemberId: string;
  memberName: string;
  userRequest: string;
  petImage: string;
  petName: string;
  petType: string;
  hospitalId: number;
  hospitalName: string;
  messageToUser: string;
  createdAt: string;
  reason: string;
  isKeepRequest: boolean;
}

export interface UpdateReservationParams {
  petId: number | undefined;
  hospitalId: number | undefined;
  hospitalMemberId: number | undefined;
  reservationTime: string | undefined;
  userRequest: string | undefined;
  reserveType: string | undefined;
  isKeepRequest: boolean | undefined;
  messageToUser?: string;
}

export interface UpdateReservationResponse {
  id: number;
  serviceType: string;
  serviceStatus: string;
  reservationTime: string;
  hospitalMemberId: number;
  memberName: string;
  userRequest: string;
  petId: number;
  petImage: string;
  petName: string;
  petType: string;
  hospitalId: number;
  hospitalName: string;
  hospitalImages: [{ file: string; order: number }];
  totalScore: number;
  hospitalReviewCount: number;
  hospitalTel: string;
  messageToUser: string;
  createdAt: string;
  reason: string;
  isKeepRequest: boolean;
}

export interface CancelReservationParams {
  reason: string;
}

export interface ApproveReservationParams {
  isChangedClinic: boolean;
  isChangedDate: boolean;
  isChangedTime: boolean;
  isChangedStatus: boolean;
  isChangedNonUser: boolean;
}

export interface ApproveReservationNotificationParams {
  notificationId: number;
}

export interface HospitalMemberModel {
  id: number;
  name: string;
  major: string;
}

export interface AvailableTimeModel {
  time: ReservationTimeTableKey;
  isAvailable: boolean;
}

interface HospitalThumbnailType {
  file: string;
  order?: number;
}

export interface ReservationResponseModel {
  id: number;
  hospitalId: number;
  hospitalName: string;
  hospitalMemberId: number;
  isIntoLink: boolean;
  petId: number;
  petImage: string;
  petName: string;
  petType: string;
  reason: string;
  reservationTime: string;
  reserveType: string;
  serviceStatus: ServiceStatus;
  hospitalImages?: HospitalThumbnailType[];
  serviceType: string;
  memberName: string;
  userRequest: string;
  messageToUser: string;
  createdAt: string;
  isKeepRequest: boolean;
  reviewId: number | null;
  isChangedApprove: ReservationIsChangedApprove;
  isReservationPossible: boolean;
  hospitalIsExpose: boolean;
}

interface Sort {
  sorted: boolean;
  unsorted: boolean;
  empty: boolean;
}

interface ReservationListPagenation {
  sort: Sort;
  pageNumber: number | undefined;
  pageSize: number | undefined;
  offset: number | undefined;
  paged: boolean;
  unpaged: boolean;
}

export interface ReservationListResponseModel {
  content: ReservationResponseModel[];
  pageable: ReservationListPagenation;
  sort: Sort;
  last: boolean;
  first: boolean;
  empty: boolean;
  totalElements: number;
  totalPages: number;
  numberOfElements: number;
  size: number;
  number: number;
}

export interface GetReservationDetailResponse {
  id: number;
  hospitalId: number;
  hospitalName: string;
  hospitalMemberId: number;
  isKeepRequest: boolean;
  isMoved: boolean;
  memberName: string;
  memberMajor: string;
  petId: number;
  petImage: string;
  petName: string;
  petType: string;
  reason: string;
  reservationTime: string;
  reserveType: string;
  reviewId: number | null;
  serviceType: ServiceType;
  serviceStatus: ServiceStatus;
  userRequest: string;
  createdAt: string;
  updateType: ReservationUpdateType;
  isIntoLink: boolean;
  isChangedApprove: ReservationIsChangedApprove;
}

export interface GetMyNonUserReservationsParams {
  mobileNo: string;
}

export interface MigrateReservationsParams extends GetMyNonUserReservationsParams {
  name: string;
}

export interface MigrateReservationsResponse {
  size: number;
  status: SERVICE_STATUS_VALUE | null;
}

export interface GetUserInfoResType {
  id: number | undefined;
  mileageId: number | undefined;
  email: string | undefined;
  mobileNumber: string | undefined;
  displayPetType: string | undefined;
  userName: string | undefined;
  name: string | undefined;
  userStatus: string | undefined;
}

export interface GetHospitalReservationMembersParams {
  hospitalId: number;
}

export interface GetHospitalReservationMembersResponse extends HospitalMemberModel {}

export interface GetHospitalReserveTypesParams {
  hospitalId: number;
}

export interface GetHospitalReserveTypesResponse {
  id: number;
  reserveType: string;
}

export interface GetLatestPetReservationParams {
  hospitalId: number;
  petId: number;
}

export interface GetLatestPetReservationResponse {
  petId: number;
  hospitalId: number;
  hospitalMemberId: number;
  reservationTime: string;
  userRequest: string;
  serviceType: string;
  reserveType: string;
  isKeepRequest: boolean;
  id: number;
  createdAt: string;
  serviceStatus: string;
  petName: string;
  petImage: string | null;
  petType: string;
  hospitalName: string;
  memberName: string;
  memberMajor: string;
  reason: string | null;
  updateType: ReservationUpdateType;
  isMoved: boolean;
  reviewId: number | null;
  isChangedApprove: ReservationIsChangedApprove;
}

export enum RESERVATION_API_ERROR_CODE {
  RESERVATION_NOT_USED = 'RESERVATION_NOT_USED',
  CANCEL_EXCEED = 'CANCEL_EXCEED',
  RESERVATION_EXCEED = 'RESERVATION_EXCEED',
  RESERVATION_DEADLINE = 'RESERVATION_DEADLINE',
  RESERVATION_CHECK_ERROR = 'RESERVATION_CHECK_ERROR',
  RESERVATION_TIME_ERROR = 'RESERVATION_TIME_ERROR',
  RESERVATION_CLINIC_INFO_ERROR = 'RESERVATION_CLINIC_INFO_ERROR',
  RESERVATION_ERROR = 'RESERVATION_ERROR',
  ALREADY_RESERVATION_EXISTS = 'ALREADY_RESERVATION_EXISTS',
  RESERVATION_MEMBER_INFO_ERROR = 'RESERVATION_MEMBER_INFO_ERROR',
  RESERVATION_TIME_FINISH = 'RESERVATION_TIME_FINISH',
}

export interface GetChangedReservationResponse {
  id: number;
  hospitalId: number;
  hospitalName: string;
  serviceStatus: ServiceStatus;
  reserveType: string;
  reservationTime: Date | string;
  reason: string;
  isIntoLink: boolean;
  petType: PetType | 'C' | 'D';
  isChangedApprove?: ReservationIsChangedApprove;
}

export interface GetReservationNotificationsResponse {
  id: number;
  hospitalId: number;
  hospitalName: string;
  reservationId: number;
  petId: number;
  petName: string;
  petType: PetType | 'C' | 'D';
  reservationTime: Date | string;
  isIntoLink: boolean;
  exposeType?: string;
  isApprove: boolean;
}

export interface ReservationNotificationProps extends GetReservationNotificationsResponse {
  reservationsCount?: number;
}

export interface GetHasHospitalReservationsParams {
  hospitalId: number;
}

export const ReservationApi = {
  getHospitalAvailableDate: async (params: GetHospitalAvailableDate) => {
    // eslint-disable-next-line max-len
    const queryString = `hospitalId=${params.hospitalId}&startDate=${params.startDate}&endDate=${params.endDate}&memberId=${params.memberId}`;
    const response = await HTTP.get<string[]>(`${BASE_URL}/hospitals/reservations/available-days?${queryString}`);
    return response;
  },

  // TODO: 삭제 예정
  getHospitalMembers: async (params: GetHospitalMembersParams) => {
    const queryString = `hospitalId=${params.hospitalId}&desiredDate=${params.desiredDate}`;
    const response = await HTTP.get<HospitalMemberModel[]>(
      `${BASE_URL}/hospitals/reservations/available-members?${queryString}`,
    );
    return response;
  },

  getHospitalAvailableHours: async (params: GetHospitalAvailableHoursParams) => {
    const queryString = `hospitalId=${params.hospitalId}&desiredDate=${params.desiredDate}&memberId=${params.memberId}`;
    const response = await HTTP.get<AvailableTimeModel[]>(
      `${BASE_URL}/hospitals/reservations/available-hours?${queryString}`,
    );
    return response;
  },

  getReservationDetail: async (reservationId: number) => {
    const response = await HTTP.get<GetReservationDetailResponse>(
      `${BASE_URL}/hospitals/reservations/my/${reservationId}`,
    );
    return response;
  },

  getMyReservations: async (params: Pageable, status: string | ServiceStatus) => {
    const queryParams = getQueryParams(params);
    const response = await HTTP.get<ReservationListResponseModel>(
      `${BASE_URL}/hospitals/reservations/my?${queryParams}&serviceStatus=${status}`,
    );
    return response.data;
  },

  getMyReservationLength: async (status: string | ServiceStatus) => {
    const response = await HTTP.get<ReservationListResponseModel>(
      `${BASE_URL}/hospitals/reservations/my?serviceStatus=${status}`,
    );
    return response.data.totalElements;
  },

  getMyNonUserReservations: async (params: GetMyNonUserReservationsParams) => {
    const response = await HTTP.get<number>(
      `${BASE_URL}/hospitals/reservations/valid/non-user?mobileNo=${params.mobileNo}`,
    );
    return response;
  },

  getUserInfo: async () => {
    const response = await HTTP.get<GetUserInfoResType>(`${BASE_URL}/fitpet-user/`);
    return response.data;
  },

  migrateReservations: async (params: MigrateReservationsParams) => {
    const response = await HTTP.post<MigrateReservationsParams, MigrateReservationsResponse>(
      `${BASE_URL}/hospitals/reservations/migrate`,
      params,
    );
    return response.data;
  },

  createReservation: async (params: CreateReservationParams) => {
    const response = await HTTP.post<CreateReservationParams, CreateReservationResponse>(
      `${BASE_URL}/hospitals/reservations/`,
      params,
    );
    return response;
  },

  updateReservation: async (reservationId: number, params: UpdateReservationParams) => {
    const response = await HTTP.put<UpdateReservationParams, UpdateReservationResponse>(
      `${BASE_URL}/hospitals/reservations/my/${reservationId}`,
      params,
    );
    return response;
  },

  cancelReservation: async (reservationId: number, params: CancelReservationParams) => {
    const response = await HTTP.patch<CancelReservationParams, number>(
      `${BASE_URL}/hospitals/reservations/my/${reservationId}/cancel`,
      params,
    );
    return response;
  },

  approveReservation: async (reservationId: number, params: ApproveReservationParams) => {
    const response = await HTTP.patch<ApproveReservationParams, string>(
      `${BASE_URL}/hospitals/reservations/my/${reservationId}/approve`,
      params,
    );
    return response;
  },

  createIntolinkReservation: async (params: CreateReservationParams) => {
    const response = await HTTP.post<CreateReservationParams, CreateReservationResponse>(
      `${BASE_URL}/hospitals/reservations/intolink/`,
      params,
    );
    return response;
  },

  cancelIntolinkReservation: async (reservationId: number, params: CancelReservationParams) => {
    const response = await HTTP.patch<CancelReservationParams, number>(
      `${BASE_URL}/hospitals/reservations/intolink/my/${reservationId}/cancel`,
      params,
    );
    return response;
  },

  getHospitalReservationMembers: async (params: GetHospitalReservationMembersParams) => {
    const response = await HTTP.get<GetHospitalReservationMembersResponse[]>(
      `${BASE_URL}/hospitals/reservations/${params.hospitalId}/members`,
    );
    return response;
  },

  getHospitalReserveTypes: async (params: GetHospitalReserveTypesParams) => {
    const response = await HTTP.get<GetHospitalReserveTypesResponse[]>(
      `${BASE_URL}/hospitals/reservations/${params.hospitalId}/reserve-types`,
    );
    return response;
  },

  getLatestPetReservation: async (params: GetLatestPetReservationParams) => {
    const response = await HTTP.get<GetLatestPetReservationResponse>(
      `${BASE_URL}/hospitals/reservations/${params.hospitalId}/pets/${params.petId}/last`,
    );
    return response;
  },

  getIntolinkReservationCancelCount: async () => {
    const response = await HTTP.get<number>(`${BASE_URL}/hospitals/reservations/intolink/my/cancel-count`);
    return response;
  },

  getIntolinkReservationCanceledToday: async () => {
    const response = await HTTP.get<string[]>(`${BASE_URL}/hospitals/reservations/intolink/my/canceled-today`);
    return response;
  },

  getIntolinkOngoingReservationCount: async () => {
    const response = await HTTP.get<number>(`${BASE_URL}/hospitals/reservations/intolink/my/ongoing-reservation-count`);
    return response;
  },

  getReservationChangedStatus: async () => {
    const response = await HTTP.get<GetChangedReservationResponse[]>(
      `${BASE_URL}/hospitals/reservations/status/is-changed`,
    );
    return response;
  },

  getReservationNotifications: async () => {
    const response = await HTTP.get<GetReservationNotificationsResponse[]>(
      `${BASE_URL}/hospitals/reservations/notifications`,
    );
    return response;
  },

  approveReservationNotifications: async (params: ApproveReservationNotificationParams) => {
    const response = await HTTP.patch<ApproveReservationNotificationParams, string>(
      `${BASE_URL}/hospitals/reservations/notifications/${params.notificationId}/approve`,
      params,
    );
    return response;
  },

  getHasHospitalReservations: async (params: GetHasHospitalReservationsParams) => {
    const response = await HTTP.get<boolean>(
      `${BASE_URL}/hospitals/reservations/${params.hospitalId}/has-reservations`,
    );
    return response;
  },

  getMyIntolinkInitReservation: async () => {
    const response = await HTTP.get<boolean>(`${BASE_URL}/hospitals/reservations/intolink/my/init-reservation`);
    return response;
  },

  getMyMonthlyReservations: async () => {
    const response = await HTTP.get<number>(`${BASE_URL}/hospitals/reservations/monthly/my`);
    return response;
  },
};
