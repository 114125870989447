import styled from '@emotion/styled';
import { FC } from 'react';
import { StyledTypeFaceH6Props, TypeFaceH6Props } from './typeface.type';
import { FONT_STYLE } from '@/constants/typeface';

export const H6: FC<TypeFaceH6Props> = ({ children, fontWeight, fontColor, className }) => {
  return (
    <StyledText fontWeight={fontWeight} fontColor={fontColor} className={className}>
      {children}
    </StyledText>
  );
};

const StyledText = styled.h6<StyledTypeFaceH6Props>`
  ${FONT_STYLE.H6}
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.fontColor};
`;
