import { css } from '@emotion/react';
import { toRem } from '@/utils/commonUtils';

export const GLOBAL_LAYOUT_PX = {
  LARGE_MOBILE_DEVICE_WIDTH: 450,
  SCREEN_OFFSET: 576,
  DESKTOP_BANNER_WIDTH: 560,
  DESKTOP_PROVIDE_WIDTH: 576 + 560,
  SCREEN_SIDE_PADDING: 16,
};

export const GLOBAL_LAYOUT_REM = {
  SCREEN_OFFSET: toRem(GLOBAL_LAYOUT_PX.SCREEN_OFFSET),
  DESKTOP_BANNER_WIDTH: toRem(GLOBAL_LAYOUT_PX.DESKTOP_BANNER_WIDTH),
  DESKTOP_PROVIDE_WIDTH: toRem(GLOBAL_LAYOUT_PX.DESKTOP_PROVIDE_WIDTH),
  SCREEN_SIDE_PADDING: toRem(GLOBAL_LAYOUT_PX.SCREEN_SIDE_PADDING),
};

export const GLOBAL_CSS = {
  REMOVE_SCROLLBAR: css`
    -ms-overflow-style: none;
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
    }
  `,
};

export const LIMIT_MEDIA_SCREEN_WIDTH = css`
  @media screen and (min-width: ${GLOBAL_LAYOUT_PX.SCREEN_OFFSET}px) {
    max-width: ${GLOBAL_LAYOUT_PX.SCREEN_OFFSET}px;
  }
`;
export const FixedStyle = css`
  position: fixed;
  width: 100%;
  ${LIMIT_MEDIA_SCREEN_WIDTH}
`;
