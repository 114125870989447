import { MAX_RESERVATION_REWARD_PRICE } from './policy';
import { GLOBAL_COLOR } from '@/styles/colors';

export enum ANIMAL {
  DOG = 'D', // 개
  CAT = 'C', // 고양이
  ETC = 'E', // 기타
}

export const ANIMAL_TYPE_STRING = {
  [ANIMAL.DOG]: '강아지',
  [ANIMAL.CAT]: '고양이',
  [ANIMAL.ETC]: '기타',
};

export enum URL_TARGET {
  REVIEWS = 'reviews',
  PORTFOLIO = 'portFolio',
}

export const MODAL_DURATION = {
  CONFIRM: 200,
  BOTTOM: 350,
};

export enum DAY_TYPE {
  MON = 'MON',
  TUE = 'TUE',
  WED = 'WED',
  THU = 'THU',
  FRI = 'FRI',
  SAT = 'SAT',
  SUN = 'SUN',
}

export const DAY_MAP = {
  [DAY_TYPE.MON]: '월',
  [DAY_TYPE.TUE]: '화',
  [DAY_TYPE.WED]: '수',
  [DAY_TYPE.THU]: '목',
  [DAY_TYPE.FRI]: '금',
  [DAY_TYPE.SAT]: '토',
  [DAY_TYPE.SUN]: '일',
};

export const WEEKDAY = [
  DAY_TYPE.SUN,
  DAY_TYPE.MON,
  DAY_TYPE.TUE,
  DAY_TYPE.WED,
  DAY_TYPE.THU,
  DAY_TYPE.FRI,
  DAY_TYPE.SAT,
];

export const NONE_PADDING = { top: '0', bottom: '0', left: '0', right: '0' };

// 정렬
export interface Sort {
  unsorted: boolean;
  sorted: boolean;
  empty: boolean;
}

// 페이징 관련
export interface Pageable {
  page: number;
  size: number;
  sort: string | string[];
}

export interface SortOptionalPageable {
  page: number;
  size: number;
  sort?: string | string[];
}

export enum PAGEABLE_KEY {
  PAGEABLE = 'pageable',
  PAGE = 'page',
  SIZE = 'size',
  SORT = 'sort',
}

export interface PageableObject {
  sort: Sort;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
  offset: number;
}

/**
 * @deprecated
 * `utils/device`로 대체
 */
export const DEVICE_PLATFORM = {
  IOS: 'IOS',
  ANDROID: 'ANDROID',
  WEB: 'WEB',
  IOS_WEB: 'IOS_WEB',
  ANDROID_WEB: 'ANDROID_WEB',
  UNKNOWN: 'UNKNOWN',
} as const;

/**
 * @deprecated
 * `utils/device`로 대체
 */
export type DEVICE_PLATFORM_TYPE = keyof typeof DEVICE_PLATFORM;

export interface Device {
  normal: boolean;
  mobile: boolean;
  tablet: boolean;
  devicePlatform: DEVICE_PLATFORM_TYPE;
}

// api 통신 시 response 되는 list (response data 중 추가로 필요한 것이 있다면 추가해서 사용하셔도 됩니다)
export interface ResponseList<T> {
  totalPages: number; // 총 페이지
  totalElements: number; // 총 게시물 개수
  first: boolean; // 현재 페이지가 첫페이지인지 여부
  last: boolean; // 현재 페이지가 마지막 페이지인지 여부
  size: number; // 한 페이지에 보여지는 게시물 갯수
  content: T[]; // list content
  number: number; // 현재 페이지
}

export interface ResponsePagingList<T> extends ResponseList<T> {
  pageable: PageableObject;
}

export const DEFAULT_ADDRESS = {
  id: 3556,
  code: '1168000000',
  address1: '서울특별시',
  address1Code: '11',
  address1Shorts: '서울시',
  address2: '강남구',
  address2Code: '680',
  address3: null,
  address3Code: null,
  level: 3,
  lat: 37.5102,
  lng: 127.0656,
  isBeforeDefaultAddressInitialization: true,
};

export const DEFAULT_NATIONWIDE_ADDRESS = {
  code: '1000000000',
  address1: '서울시',
  address1Code: '11',
  address1Shorts: '전국',
  address2: '',
  address2Code: '',
  address3: '',
  address3Code: '',
  level: 1,
};

export const DEFAULT_LOCATION = {
  LAT: 37.5102,
  LNG: 127.0656,
};

export interface LocalTime {
  hour: number;
  minute: number;
  second: number;
  nano: number;
}

export const DIRECTION = {
  VERTICAL: 'VERTICAL',
  HORIZONTAL: 'HORIZONTAL',
} as const;

export type DIRECTION_TYPE = keyof typeof DIRECTION;

export interface LabelType<D = string> {
  label: string;
  value: D;
}

export interface LabelTypeNumber extends LabelType<number> {}

export const PAGINATION_SIZE = 15;

export const HOSPITAL = 'hospital';

export enum REVIEW_TYPE {
  PRESCRIP = 'PRESCRIP', // 진료인증
  RECEIPT = 'RECEIPT', // 방문인증
  NORMAL = 'NORMAL', // 간편리뷰
}

export interface Position {
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}

export type POSITION_TYPE = 'static' | 'relative' | 'fixed' | 'absolute' | 'sticky';

type PADDING_VALUE_TYPE = string | number;
export type PADDING =
  | PADDING_VALUE_TYPE
  | [PADDING_VALUE_TYPE, PADDING_VALUE_TYPE]
  | [PADDING_VALUE_TYPE, PADDING_VALUE_TYPE, PADDING_VALUE_TYPE, PADDING_VALUE_TYPE];

export const AOS_APPLICATION_ID = `kr.co.fitpet`;
export const IOS_BUNDLE_ID = `com.fitpet.inspector`;

export const SCRAP_TAB_INDEX = {
  HOSPITAL: '0',
  REVIEW: '1',
};

export interface Padding {
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
}

export const DEFAULT_PADDING = {
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};

export const HOME_TYPE_A_CATEGORY_NAME_STYLE = {
  fontWeight: 600,
  fontSize: 14,
  lineHeight: 20,
  padding: { ...DEFAULT_PADDING, top: 4 },
  color: GLOBAL_COLOR.GRAY_700,
};

export enum VISIT_TYPE {
  REVIEW = 'REVIEW',
  RESERVATION = 'RESERVATION',
}

// 리뷰 작성 진입 위치
export enum REVIEW_WRITE_LOCATION {
  HOSPITAL_DETAIL = 'hospital_detail', // 병원 상세
  RECENT_LIST = 'recent_list', // 최근 방문한 병원 리스트
  SEARCH_LIST = 'search_list', // 플레이 온보딩 리스트
}

export interface SetSelectedAddressToLocalStorageParams {
  latitude: number | null | undefined;
  longitude: number | null | undefined;
}

export enum RESERVATION_SERVICE_TYPE {
  VACCINATION = 'VACCINATION',
  HEALTH_SCREENING = 'HEALTH_SCREENING',
  DIAGNOSIS = 'DIAGNOSIS',
  CONSULTING = 'CONSULTING',
}

export const RESERVATION_SERVICE_MAP: {
  value: 'VACCINATION' | 'HEALTH_SCREENING' | 'DIAGNOSIS' | 'CONSULTING';
  label: string;
}[] = [
  { value: RESERVATION_SERVICE_TYPE.VACCINATION, label: '예방접종' },
  { value: RESERVATION_SERVICE_TYPE.HEALTH_SCREENING, label: '건강검진' },
  { value: RESERVATION_SERVICE_TYPE.DIAGNOSIS, label: '일반 진료' },
  { value: RESERVATION_SERVICE_TYPE.CONSULTING, label: '상담 진료' },
];

export const PROD_FRONT_BASE_URL = 'https://hospital.fitpetmall.com';
export const PROD_FITPET_MALL_DOMAIN = 'https://www.fitpetmall.com/mall';

export const BASIC_USERNAME = '핏펫회원';

export const FITPET_MALL_BRAND_TYPE = {
  AHEAD: 'QnJhbmRUeXBlOjM',
};

export const IOS_BOUNCE_BOUNDARY = 200;

export const HEALTH_CATEGORY = '검진/접종';

export const BASIC_EVENT_TEXT = `예약하고 방문하면 최대 ${MAX_RESERVATION_REWARD_PRICE}원 드려요`;

export const BASIC_HOSPITAL_RESERVATION_TOOLTIP_TEXT = '예약 방문 시 적립금 최대 2만원!';
