import create from 'zustand';
import FlatIconList from '@/constants/flatIconList';
import ImageIconList from '@/constants/imageIconList';

export interface SnackBarMessageType {
  text: string;
  onClick: () => void;
  onClickText: string;
  secondCol?: string;
  leftIcon?: keyof typeof ImageIconList;
  leftIconSize?: number;
  rightIcon?: keyof typeof FlatIconList;
  rightIconSize?: number;
  margin?: number;
}

interface SnackBarStore {
  snackBarList: SnackBarMessageType[];
  addSnackBarList: (snackBarMessage: SnackBarMessageType) => void;
  clearAllSnackBarList: () => void;
}

const useSnackBarStore = create<SnackBarStore>((set, get) => ({
  snackBarList: [],
  addSnackBarList: (snackBarMessage) => {
    const { snackBarList } = get();
    const newSnackBarList = [...snackBarList, snackBarMessage];
    set((state) => ({ ...state, snackBarList: newSnackBarList }));
  },
  clearAllSnackBarList: () => {
    const { snackBarList } = get();
    if (snackBarList.length === 0) {
      return;
    }
    set((state) => ({ ...state, snackBarList: [] }));
  },
}));

export default useSnackBarStore;
