import create from 'zustand';

export interface ToastMessageType {
  text: string;
  secondCol?: string;
  onClick?: () => void;
  margin?: number;
}

interface ToastStore {
  toastList: ToastMessageType[];
  addToastList: (toastMessage: ToastMessageType) => void;
  clearAllToastList: () => void;
}

const useToastStore = create<ToastStore>((set, get) => ({
  toastList: [],
  addToastList: (toastMessage) => {
    const { toastList } = get();
    const newToastList = [...toastList, toastMessage];
    set((state) => ({ ...state, toastList: newToastList }));
  },
  clearAllToastList: () => {
    const { toastList } = get();
    if (toastList.length === 0) {
      return;
    }
    set((state) => ({ ...state, toastList: [] }));
  },
}));

export default useToastStore;
