import { SearchLocationSymptom } from './tracker/constants';
import { DEFAULT_ADDRESS } from '@/constants/common';
import { IS_FIRST_DATE } from '@/constants/policy';
import { WEB_STORAGE_KEY } from '@/constants/webStorageKey';
import { parseToNumber } from '@/utils/stringUtils';

export const getStorageKey = (key: string, postfix: string) => {
  return `${key}-${postfix}`;
};

const isInitialized = () => {
  try {
    return typeof window !== 'undefined';
  } catch {
    return false;
  }
};

export const setSessionStorageItem = (key: string, value: string) => {
  if (!isInitialized()) {
    return false;
  }
  window.sessionStorage.setItem(key, value);
  return true;
};

export const getSessionStorageItem = (key: string, defaultValue?: string) => {
  if (!isInitialized()) {
    return undefined;
  }
  return window.sessionStorage.getItem(key) || defaultValue;
};

export const removeSessionStorageItem = (key: string) => {
  if (!isInitialized()) {
    return '';
  }
  return window.sessionStorage.removeItem(key);
};

export const setLocalStorageItem = (key: string, value: string | boolean | number) => {
  if (!isInitialized()) {
    return false;
  }
  window.localStorage.setItem(key, `${value}`);
  return true;
};

export const getLocalStorageItem = (key: string, defaultValue?: string): string => {
  if (!isInitialized()) {
    return '';
  }
  return window.localStorage.getItem(key) || defaultValue || '';
};

export const getLocalStorageBooleanItem = (key: string, defaultValue?: boolean): boolean | undefined => {
  const value = getLocalStorageItem(key);
  return value ? Boolean(JSON.parse(value)) : defaultValue;
};

export const getLocalStorageNumberItem = (key: string, defaultValue?: number): number | undefined => {
  const value = getLocalStorageItem(key);
  return value ? parseToNumber(value) : defaultValue;
};

export const removeLocalStorageItem = (key: string) => {
  if (!isInitialized()) {
    return '';
  }
  return window.localStorage.removeItem(key);
};

export const checkAndRemoveLocalStorageItem = (key: string) => {
  const LocalData = getLocalStorageItem(key);
  if (LocalData) {
    removeLocalStorageItem(key);
  }
};

export const checkAndRemoveSessionStorageItem = (key: string) => {
  const sessionData = getSessionStorageItem(key);
  if (sessionData) {
    removeSessionStorageItem(key);
  }
};

export const getSessionStorageBooleanItem = (key: string, defaultValue?: boolean): boolean | undefined => {
  const value = getSessionStorageItem(key);
  return value ? Boolean(JSON.parse(value)) : defaultValue;
};

const WebStorage = {
  getPageScrollYPosition: (pathname: string): number | undefined => {
    const value = getSessionStorageItem(getStorageKey(WEB_STORAGE_KEY.PAGE_SCROLL_Y_POSITION, pathname));
    return value ? parseToNumber(value) : undefined;
  },
  setPageScrollYPosition: (pathname: string, pageScrollYPosition: number) => {
    return setSessionStorageItem(
      getStorageKey(WEB_STORAGE_KEY.PAGE_SCROLL_Y_POSITION, pathname),
      `${pageScrollYPosition}`,
    );
  },
  removePageScrollYPosition: (pathname: string) => {
    const pageScrollYPositionKey = getStorageKey(WEB_STORAGE_KEY.PAGE_SCROLL_Y_POSITION, pathname);
    removeSessionStorageItem(pageScrollYPositionKey);
  },
  getDivScrollYPosition: (pathname: string): number | undefined => {
    const value = getSessionStorageItem(getStorageKey(WEB_STORAGE_KEY.DIV_SCROLL_Y_POSITION, pathname));
    return value ? parseToNumber(value) : undefined;
  },
  setDivScrollYPosition: (pathname: string, pageScrollYPosition: number) => {
    return setSessionStorageItem(
      getStorageKey(WEB_STORAGE_KEY.DIV_SCROLL_Y_POSITION, pathname),
      `${pageScrollYPosition}`,
    );
  },
  getDivScrollXPosition: (pathname: string): number | undefined => {
    const value = getSessionStorageItem(getStorageKey(WEB_STORAGE_KEY.DIV_SCROLL_X_POSITION, pathname));
    return value ? parseToNumber(value) : undefined;
  },
  setDivScrollXPosition: (pathname: string, pageScrollXPosition: number) => {
    return setSessionStorageItem(
      getStorageKey(WEB_STORAGE_KEY.DIV_SCROLL_X_POSITION, pathname),
      `${pageScrollXPosition}`,
    );
  },
  removePageScrollXPosition: (pathname: string) => {
    const pageScrollXPositionKey = getStorageKey(WEB_STORAGE_KEY.DIV_SCROLL_X_POSITION, pathname);
    removeSessionStorageItem(pageScrollXPositionKey);
  },
  isShowReservationTooltip: (): boolean | undefined => {
    const value = getLocalStorageItem(WEB_STORAGE_KEY.SHOW_RESERVATION_TOOLTIP);
    return value ? Boolean(JSON.parse(value)) : true;
  },
  setIsShowReservationTooltip: (value: boolean) => {
    return setLocalStorageItem(WEB_STORAGE_KEY.SHOW_RESERVATION_TOOLTIP, `${value}`);
  },
  getReservationUserRequestMessage: () => {
    const value = getLocalStorageItem(WEB_STORAGE_KEY.RESERVATION_USER_REQUEST_MESSAGE);
    const replacedValue = value?.replace(/"/g, '').replace(/\\n/g, '\n');
    return replacedValue;
  },
  setReservationUserRequestMessage: (value: string) => {
    setLocalStorageItem(WEB_STORAGE_KEY.RESERVATION_USER_REQUEST_MESSAGE, `${JSON.stringify(value)}`);
  },
  setKakaoMapCoordination: (value: string) => {
    setSessionStorageItem(WEB_STORAGE_KEY.KAKAO_MAP_COORDINATION, value);
  },
  getKakaoMapCoordination: (defaultValue?: { lat: number; lon: number }) => {
    const value = getSessionStorageItem(WEB_STORAGE_KEY.KAKAO_MAP_COORDINATION);
    return value ? JSON.parse(value) : defaultValue;
  },
  setKakaoMapRadius: (value: string) => {
    setSessionStorageItem(WEB_STORAGE_KEY.KAKAO_MAP_RADIUS, value);
  },
  getKakaoMapRadius: (defaultValue?: number) => {
    const value = getSessionStorageItem(WEB_STORAGE_KEY.KAKAO_MAP_RADIUS);
    return value ? JSON.parse(value) : defaultValue;
  },
  setReviewDetailTooltipShow: (value: boolean) => {
    return setLocalStorageItem(WEB_STORAGE_KEY.REVIEW_DETAIL_TOOLTIP_SHOW_CHECK, `${value}`);
  },
  getReviewDetailTooltipShowCheck: (): boolean => {
    const value = getLocalStorageItem(WEB_STORAGE_KEY.REVIEW_DETAIL_TOOLTIP_SHOW_CHECK);
    return value ? Boolean(JSON.parse(value)) : true;
  },
  setSymptomSearchData: (params: string) => {
    sessionStorage.setItem(WEB_STORAGE_KEY.SYMPTOM_SEARCH_DATA, params);
  },
  setLocationPermission: (flag: boolean) => {
    sessionStorage.setItem(WEB_STORAGE_KEY.LOCATION_PERMISSION, `${flag}`);
  },
  getLocationPermission: () => {
    const value = sessionStorage.getItem(JSON.stringify(WEB_STORAGE_KEY.LOCATION_PERMISSION));

    return value ? Boolean(value) : false;
  },
  setLocationPermissionFirstOnceCheck: (flag: boolean) => {
    sessionStorage.setItem(WEB_STORAGE_KEY.LOCATION_PERMISSION_FIRST_ONCE_CHECK, `${flag}`);
  },
  getLocationPermissionFirstOnceCheck: () => {
    const value = sessionStorage.getItem(JSON.stringify(WEB_STORAGE_KEY.LOCATION_PERMISSION_FIRST_ONCE_CHECK));

    return value ? Boolean(value) : false;
  },
  setHospitalSearchAddressCode: (code: string) => {
    localStorage.setItem(WEB_STORAGE_KEY.HOSPITAL_SEARCH_ADDRESS_CODE, code);
  },
  getHospitalSearchAddressCode: () => {
    return localStorage.getItem(WEB_STORAGE_KEY.HOSPITAL_SEARCH_ADDRESS_CODE) || `${DEFAULT_ADDRESS.code}`;
  },
  getFilterResetModalNotShowCheckTime: () => {
    return localStorage.getItem(WEB_STORAGE_KEY.CHECK_FILTER_RESET_MODAL_TIME);
  },
  setFilterResetModalNotShowCheckTime: (expiredDate: string) => {
    localStorage.setItem(WEB_STORAGE_KEY.CHECK_FILTER_RESET_MODAL_TIME, expiredDate);
  },
  getReviewWriteTooltipShow: (): boolean => {
    const toolTipShowCheckValue = getLocalStorageItem(WEB_STORAGE_KEY.REVIEW_WRITE_TOOLTIP_SHOW_CHECK);
    const noToolTipShowValue = toolTipShowCheckValue === 'null' || toolTipShowCheckValue === '';

    if (IS_FIRST_DATE && noToolTipShowValue) {
      return true;
    }
    return false;
  },
  setReviewWritelTooltipShow: (value: boolean | null) => {
    return setLocalStorageItem(WEB_STORAGE_KEY.REVIEW_WRITE_TOOLTIP_SHOW_CHECK, `${value}`);
  },
  setPlayReviewOnboarding: (flag: string) => {
    localStorage.setItem(WEB_STORAGE_KEY.PLAY_REVIEW_ONBOARDING, flag);
  },
  getPlayReviewOnboarding: () => {
    const value = localStorage.getItem(WEB_STORAGE_KEY.PLAY_REVIEW_ONBOARDING);
    return value ? Boolean(JSON.parse(value)) : true;
  },
  setIsRouteFromPlay: (flag: string) => {
    sessionStorage.setItem(WEB_STORAGE_KEY.IS_ROUTE_FROM_PLAY, flag);
  },
  getIsRouteFromPlay: () => {
    const value = sessionStorage.getItem(WEB_STORAGE_KEY.IS_ROUTE_FROM_PLAY);
    return value ? Boolean(JSON.parse(value)) : false;
  },
  setIsFoldCategoryList: (flag: boolean) => {
    sessionStorage.setItem(WEB_STORAGE_KEY.IS_FOLD_CATEGORY_LIST, `${flag}`);
  },
  getIsFoldCategoryList: () => {
    const value = sessionStorage.getItem(WEB_STORAGE_KEY.IS_FOLD_CATEGORY_LIST);
    return value ? Boolean(JSON.parse(value)) : false;
  },
  setViewHospitalSymptomSearchResult: ({ type, isHashtag }: { type: SearchLocationSymptom; isHashtag: boolean }) => {
    setLocalStorageItem(WEB_STORAGE_KEY.IS_DIRECT_RESULT, true);
    setLocalStorageItem(WEB_STORAGE_KEY.IS_HASHTAG, isHashtag);
    setLocalStorageItem(WEB_STORAGE_KEY.SEARCH_LOCATION_SYMPTOM, type);
  },
  setReservationProviderInitialized: (isReservationProviderInitialized: boolean = false) => {
    setLocalStorageItem(WEB_STORAGE_KEY.RESERVATION_PROVIDER_INITIALIZED, isReservationProviderInitialized);
  },
  getReservationProviderInitialized: () => {
    const value = getLocalStorageItem(WEB_STORAGE_KEY.RESERVATION_PROVIDER_INITIALIZED);
    return value ? Boolean(JSON.parse(value)) : false;
  },
  setLastChangedReservations: (lastChangedReservations: string = '') => {
    setLocalStorageItem(WEB_STORAGE_KEY.LAST_CHANGED_RESERVATIONS, lastChangedReservations);
  },
  getLastChangedReservations: () => {
    return getLocalStorageItem(WEB_STORAGE_KEY.LAST_CHANGED_RESERVATIONS);
  },
  getReservationPointsTooltipDisplayDate: () => {
    return getLocalStorageItem(WEB_STORAGE_KEY.RESERVATION_POINTS_TOOLTIP_DISPLAY_DATE);
  },
  setReservationPointsTooltipDisplayDate: (value: string) => {
    setLocalStorageItem(WEB_STORAGE_KEY.RESERVATION_POINTS_TOOLTIP_DISPLAY_DATE, value);
  },
  getIsShowCategoryNewLabel: () => {
    const value = getLocalStorageItem(WEB_STORAGE_KEY.IS_SHOW_CATEGORY_NEW_LABEL);
    return value ? JSON.parse(value) : true;
  },
  setIsShowCategoryNewLabel: (value: string) => {
    setLocalStorageItem(WEB_STORAGE_KEY.IS_SHOW_CATEGORY_NEW_LABEL, value);
  },
  getIsShowReservationProcessGuideTooltip: () => {
    const value = getLocalStorageItem(WEB_STORAGE_KEY.IS_SHOW_RESERVATION_PROCESS_GUIDE_TOOLTIP);
    return value ? JSON.parse(value) : true;
  },
  setIsShowReservationProcessGuideTooltip: (value: boolean) => {
    setLocalStorageItem(WEB_STORAGE_KEY.IS_SHOW_RESERVATION_PROCESS_GUIDE_TOOLTIP, value);
  },
};

export default WebStorage;
