import create from 'zustand';
import { DEVICE_PLATFORM, DEVICE_PLATFORM_TYPE } from '@/constants/common';
import { getDevicePlatform } from '@/utils/deviceUtils';

interface DevicePlatformStore {
  devicePlatform: DEVICE_PLATFORM_TYPE;
  setDevicePlatform: (device: DEVICE_PLATFORM_TYPE) => void;
}

const getCurrentDevicePlatform = () => {
  try {
    return getDevicePlatform();
  } catch {
    return DEVICE_PLATFORM.UNKNOWN;
  }
};

const useDevicePlatformStore = create<DevicePlatformStore>((set) => ({
  devicePlatform: getCurrentDevicePlatform(),
  setDevicePlatform: (device: DEVICE_PLATFORM_TYPE) => {
    set((state) => ({ ...state, devicePlatform: device }));
  },
}));

export default useDevicePlatformStore;
