import { useQuery } from 'react-query';
import {
  GetHasHospitalReservationsParams,
  GetHospitalAvailableDate,
  GetHospitalAvailableHoursParams,
  GetHospitalMembersParams,
  GetHospitalReservationMembersParams,
  GetHospitalReserveTypesParams,
  GetLatestPetReservationParams,
  ReservationApi,
} from '@/api/reservation';
import { QUERY_KEYS } from '@/constants/queryKey';

export const useGetHospitalAvailableDate = (params: GetHospitalAvailableDate) => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.RESERVATION.AVAILABLE_DAYS(params),
    async () => {
      const response = await ReservationApi.getHospitalAvailableDate(params);
      return response;
    },
    { enabled: !!params.hospitalId && !!params.memberId },
  );
  return result;
};

export const useGetHospitalMembers = (params: GetHospitalMembersParams) => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.RESERVATION.MEMBER(params),
    async () => {
      const response = await ReservationApi.getHospitalMembers(params);
      return response;
    },
    { refetchOnMount: 'always', enabled: !!params.hospitalId },
  );
  return result;
};

export const useGetHospitalAvailableHours = (params: GetHospitalAvailableHoursParams) => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.RESERVATION.AVAILABLE_HOURS(params),
    async () => {
      const response = await ReservationApi.getHospitalAvailableHours(params);
      return response;
    },
    { refetchOnMount: 'always', enabled: !!params.hospitalId && !!params.desiredDate && !!params.memberId },
  );
  return result;
};

export const useGetReservationDetail = (reservationId: number) => {
  const result = useQuery(
    QUERY_KEYS.RESERVATIONS.DETAIL(reservationId),
    async () => {
      const response = await ReservationApi.getReservationDetail(reservationId);
      return response.data;
    },
    { enabled: !!reservationId },
  );
  return result;
};

export const useGetMyNonUserReservations = (phoneNumber: string) => {
  const result = useQuery(
    QUERY_KEYS.RESERVATIONS.MOBILE_NUMBER(phoneNumber),
    async () => {
      const response = await ReservationApi.getMyNonUserReservations({ mobileNo: phoneNumber });
      return response;
    },
    { enabled: !!phoneNumber },
  );
  return result;
};

export const useGetHospitalReservationMembers = (params: GetHospitalReservationMembersParams) => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.RESERVATION.MEMBERS({ hospitalId: params.hospitalId }),
    async () => {
      const response = await ReservationApi.getHospitalReservationMembers({ hospitalId: params.hospitalId });
      return response;
    },
    { refetchOnMount: 'always', enabled: !!params.hospitalId },
  );
  return result;
};

export const useGetHospitalReserveTypes = (params: GetHospitalReserveTypesParams) => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.RESERVATION.RESERVE_TYPES({ hospitalId: params.hospitalId }),
    async () => {
      const response = await ReservationApi.getHospitalReserveTypes({ hospitalId: params.hospitalId });
      return response;
    },
    { refetchOnMount: 'always', enabled: !!params.hospitalId },
  );
  return result;
};

export const useGetLatestPetReservation = (params: GetLatestPetReservationParams) => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.RESERVATION.LATEST_PET({ hospitalId: params.hospitalId, petId: params.petId }),
    async () => {
      const response = await ReservationApi.getLatestPetReservation({
        hospitalId: params.hospitalId,
        petId: params.petId,
      });
      return response;
    },
    { refetchOnMount: 'always', enabled: !!params.hospitalId && !!params.petId },
  );
  return result;
};

export const useGetIntolinkReservationCancelCount = () => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.RESERVATION.INTOLINK_RESERVATION_CANCEL_COUNT(),
    async () => {
      const response = await ReservationApi.getIntolinkReservationCancelCount();
      return response;
    },
    { refetchOnMount: 'always', refetchOnWindowFocus: 'always' },
  );
  return result;
};

export const useGetIntolinkReservationCanceledToday = () => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.RESERVATION.INTOLINK_RESERVATION_CANCELED_TODAY(),
    async () => {
      const response = await ReservationApi.getIntolinkReservationCanceledToday();
      return response;
    },
    { refetchOnMount: 'always', refetchOnWindowFocus: 'always' },
  );
  return result;
};

export const useGetIntolinkOngoingReservationCount = () => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.RESERVATION.INTOLINK_ONGOING_RESERVATION_COUNT(),
    async () => {
      const response = await ReservationApi.getIntolinkOngoingReservationCount();
      return response;
    },
    { refetchOnMount: 'always', refetchOnWindowFocus: 'always' },
  );
  return result;
};

export const useGetReservationChangedStatus = (isLogin: boolean) => {
  const RESERVATION_CHANGED_STATUS_INTERVAL = 1000 * 4;

  const result = useQuery(
    QUERY_KEYS.HOSPITAL.RESERVATION.CHANGED_STATUS(),
    async () => {
      const response = await ReservationApi.getReservationChangedStatus();
      return response;
    },
    {
      refetchOnMount: isLogin,
      refetchInterval: isLogin ? RESERVATION_CHANGED_STATUS_INTERVAL : false,
      refetchOnWindowFocus: isLogin,
      retry: false,
      enabled: isLogin,
    },
  );
  return result;
};

export const useGetReservationNotifications = (isLogin: boolean) => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.RESERVATION.NOTIFICATIONS(),
    async () => {
      const response = await ReservationApi.getReservationNotifications();
      return response;
    },
    { retry: false, enabled: isLogin },
  );
  return result;
};

export const useGetHasHospitalReservations = (params: GetHasHospitalReservationsParams) => {
  const result = useQuery(
    QUERY_KEYS.HOSPITAL.RESERVATION.HOSPITAL_RESERVATION({ hospitalId: params.hospitalId }),
    async () => {
      const response = await ReservationApi.getHasHospitalReservations({ hospitalId: params.hospitalId });
      return response;
    },
    { refetchOnMount: true, enabled: !!params.hospitalId },
  );
  return result;
};

export const useGetMyIntolinkInitReservation = () => {
  const result = useQuery(QUERY_KEYS.HOSPITAL.RESERVATION.INTOLINK_MY_INIT_RESERVATION(), async () => {
    const response = await ReservationApi.getMyIntolinkInitReservation();
    return response;
  });
  return result;
};

export const useGetMyMonthlyReservations = () => {
  const result = useQuery(QUERY_KEYS.HOSPITAL.RESERVATION.MONTHLY(), async () => {
    const response = await ReservationApi.getMyMonthlyReservations();
    return response;
  });
  return result;
};
