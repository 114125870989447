import styled from '@emotion/styled';
import { DIRECTION, DIRECTION_TYPE } from '@/constants/common';
import { FONT_WEIGHT } from '@/constants/typeface';
import { GLOBAL_COLOR } from '@/styles/colors';
import { toRem } from '@/utils/commonUtils';

export interface BaseButtonContainerProps {
  direction?: DIRECTION_TYPE;
}

const BaseButtonContainer = styled.div<BaseButtonContainerProps>`
  display: flex;
  flex-direction: ${({ direction }) => (direction === DIRECTION.VERTICAL ? 'column-reverse' : 'row')};
  align-items: center;
  width: 100%;
  gap: ${toRem(4)};
  padding: ${({ direction }) =>
    direction === DIRECTION.VERTICAL
      ? `${toRem(16)} ${toRem(16)} ${toRem(8)} ${toRem(16)}`
      : `${toRem(16)} ${toRem(16)} ${toRem(24)} ${toRem(16)}`};
  ${({ direction }) =>
    direction === DIRECTION.VERTICAL &&
    `> button:first-of-type {
      width: fit-content;
      padding: 0 ${toRem(20)};
      border: 0;
      color: ${GLOBAL_COLOR.GRAY_500};
      font-weight: ${FONT_WEIGHT.REGULAR};
    }`}
`;

export default BaseButtonContainer;
