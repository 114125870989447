import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { GetChangedReservationResponse, SERVICE_STATUS_VALUE } from '@/api/reservation';
import { GLOBAL_COLOR } from '@/styles/colors';
import { toRem } from '@/utils/commonUtils';
import { DATE_FORMAT } from '@/utils/dateUtils';

import('dayjs/locale/ko');

interface UpdatedReservationModalContentProps {
  content: GetChangedReservationResponse;
}

const UpdatedReservationModalContent = ({ content }: UpdatedReservationModalContentProps) => {
  dayjs.locale('ko');
  const { hospitalName, serviceStatus, reason, reservationTime, isChangedApprove } = content;

  const showReason = useMemo(() => {
    return /[a-zA-Zㄱ-ㅎㅏ-ㅣ가-힣]/.test(reason);
  }, [reason]);

  const title = useMemo(() => {
    if (serviceStatus === SERVICE_STATUS_VALUE.CANCELLED) {
      return '예약이 취소되었어요';
    }

    if (
      serviceStatus === SERVICE_STATUS_VALUE.CONFIRMED &&
      (isChangedApprove?.isChangedClinic || isChangedApprove?.isChangedTime || isChangedApprove?.isChangedDate)
    ) {
      return '예약이 변경되었어요';
    }

    if (serviceStatus === SERVICE_STATUS_VALUE.CONFIRMED) {
      return '예약이 확정되었어요';
    }

    return '';
  }, [
    isChangedApprove?.isChangedClinic,
    isChangedApprove?.isChangedDate,
    isChangedApprove?.isChangedTime,
    serviceStatus,
  ]);

  const time = useMemo(() => {
    const dateAt = dayjs(reservationTime);
    return dateAt.format(DATE_FORMAT.FULL_DATE_WITH_DOT_TIME);
  }, [reservationTime]);

  return (
    <div>
      <Title>{title}</Title>
      {serviceStatus === SERVICE_STATUS_VALUE.CANCELLED && (
        <>{showReason && <CancelParagraph>취소 사유 : {reason}</CancelParagraph>}</>
      )}
      {serviceStatus !== SERVICE_STATUS_VALUE.CANCELLED && (
        <Paragraph>
          {hospitalName}
          <br />
          {time}
        </Paragraph>
      )}
    </div>
  );
};

export default UpdatedReservationModalContent;

const Title = styled.h1`
  text-align: center;
  color: ${GLOBAL_COLOR.GRAY_900};
  font-weight: 700;
  font-size: ${toRem(18)};
  line-height: ${toRem(25)};
`;

const CancelParagraph = styled.p`
  margin-top: ${toRem(16)};
  color: ${GLOBAL_COLOR.GRAY_700};
  font-weight: 400;
  font-size: ${toRem(14)};
  line-height: ${toRem(20)};
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

const Paragraph = styled.p`
  margin-top: ${toRem(16)};
  color: ${GLOBAL_COLOR.GRAY_700};
  font-weight: 400;
  font-size: ${toRem(14)};
  line-height: ${toRem(20)};
  text-align: center;
`;
