import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { InAppBridge } from '@/utils/bridge';

type InAppState = {
  inAppBridges?: InAppBridge;
};

export const InAppContext = createContext<InAppState>({});

export const InAppProvider = ({ children }: { children: React.ReactNode }) => {
  const [inAppBridges, setBridge] = useState<InAppBridge>();
  const value = useMemo(() => ({ inAppBridges }), [inAppBridges]);

  useEffect(() => {
    const bridges = new InAppBridge();
    bridges.init();
    setBridge(bridges);
    return undefined;
  }, []);
  return <InAppContext.Provider value={value}>{children}</InAppContext.Provider>;
};

export const useInAppBridge = () => {
  const { inAppBridges } = useContext(InAppContext);
  return inAppBridges;
};
