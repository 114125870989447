import { createContext, ReactNode, useContext, useState, useEffect } from 'react';
import useAppRouter from '@/hooks/useAppRouter';
import useDevicePlatformStore from '@/store/useDevicePlatformStore';
import { getDevicePlatform } from '@/utils/deviceUtils';

type CurrentDeviceType = { isDesktop: boolean | null };

const CurrentDeviceContext = createContext<CurrentDeviceType>({ isDesktop: null });

// 현재 장치, 라우팅 상태를 html 엘리먼트에 기록
export const CurrentDeviceProvider = ({ children }: { children: ReactNode }) => {
  const [isDesktop, setIsDesktop] = useState(null);
  const { setDevicePlatform } = useDevicePlatformStore();
  const router = useAppRouter();

  // 데스크탑 환경 감지하여 body에 추가
  useEffect(() => {
    try {
      setDevicePlatform(getDevicePlatform());
      // SSR 환경으로 인한 require 사용
      const {
        default: { desktop },
        // eslint-disable-next-line import/no-unresolved
      } = require('current-device'); // eslint-disable-line global-require

      const currentDesktop = desktop();
      setIsDesktop(currentDesktop);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
    return () => {};
  }, [setDevicePlatform]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <CurrentDeviceContext.Provider value={{ isDesktop }}>
      <div className="current-device-provider" data-pathname={router.pathname}>
        <div className="current-device-background" />
        {children}
      </div>
    </CurrentDeviceContext.Provider>
  );
};

export const useCurrentDevice = () => useContext(CurrentDeviceContext);
