import { REVIEW_TYPE } from '@/constants/common';

export enum Service {
  FITPETMALL = 'fitpetmall',
  HOSPITAL = 'hospital',
  AHEAD = 'ahead',
  COMMON = 'common',
}

export enum PetTypeForTracker {
  DOG = 'DOG',
  CAT = 'CAT',
  ALL = 'ALL',
}

export enum PetType {
  DOG = 'DOG',
  CAT = 'CAT',
  ETC = '',
}

export enum PetTypeSearch {
  DOG = 'DOG',
  CAT = 'CAT',
  ETC = '',
}

export enum PetTypeMall {
  DOG = 'dog',
  CAT = 'cat',
}

export interface PetValue {
  id: number | undefined;
  petName: string | undefined;
  petType: string | undefined;
  imageUrl: string | undefined;
}

export enum SearchLocationSymptom {
  SUBMIT = 'submit', // 직접검색 시
  RECENT_KEYWORD = 'recentkeyword', // 최근검색어 클릭 시
  RECENT_SYMPTOM = 'recentsymptom', // 최근 본 증상/질환 클릭
  RECOMMEND_HASHTAG = 'recommendhashtag', // 검색 중 추천검색어 클릭 시
  RELATED_HASHTAG = 'relatedhashtag', // 리뷰 결과화면에서 연관검색어 클릭 시
  REVIEW_HASHTAG = 'reviewhashtag', // 리뷰에서 해시태그 클릭 시
  MAIN_HASHTAG = 'mainhashtag', // 메인 화면에서 해시태그 클릭 시
}

export enum SortBy {
  RECOMMEND = 'recommend',
  RECENT = 'recent',
  USEFUL = 'useful',
}

export const HOSPITAL_REVIEW_TYPE = {
  [REVIEW_TYPE.PRESCRIP]: 'prescrip', // 진료인증
  [REVIEW_TYPE.RECEIPT]: 'receipt', // 방문인증
  [REVIEW_TYPE.NORMAL]: 'normal', // 간편 리뷰
};

export enum SCRAP_LOCATION {
  SYMPTOM_CATEGORY_REVIEW_LIST = 'symptomcategory_reviewlist', // 증상카테고리_리뷰리스트 페이지에서 스크랩하기
  SYMPTOM_SEARCH_REVIEW_LIST = 'symptomsearch_reviewlist', // 증상검색_결과리스트 페이지에서 스크랩하기
  REVIEW_DETAIL_REVIEW_LIST = 'reviewdetail_reviewlist', // 리뷰 상세 페이지에서 스크랩하기
  HOSPITAL_DETAIL_REVIEW_LIST = 'hospitaldetail_reviewlist', // 병원상세_리뷰리스트 페이지에서 스크랩하기
  USER_REVIEW_LIST = 'user_reviewlist', // 작성자의 다른 리뷰리스트 페이지에서 스크랩하기
  HOSPITAL_INFO_REVIEW_LIST = 'hospitalinfo_reviewlist', // 병원 상세 페이지 내 리뷰 탭에서 스크랩하기
  POPULAR_REVIEW_REVIEW_LIST = 'popularreview_reviewlist', // 리뷰 상세페이지 내 인기리뷰 리스트에서 도움됐어요 클릭
}

export enum LIKE_LOCATION {
  SYMPTOM_CATEGORY_REVIEW_LIST = 'symptomcategory_reviewlist', // 증상카테고리_리뷰리스트 페이지에서 도움됐어요 클릭
  SYMPTOM_SEARCH_REVIEW_LIST = 'symptomsearch_reviewlist', // 증상검색_결과리스트 페이지에서 도움됐어요 클릭
  REVIEW_DETAIL_REVIEW_LIST = 'reviewdetail_reviewlist', // 리뷰 상세 페이지에서 도움됐어요 클릭
  HOSPITAL_DETAIL_REVIEW_LIST = 'hospitaldetail_reviewlist', // 병원상세_리뷰리스트 페이지에서 도움됐어요 클릭
  USER_REVIEW_LIST = 'user_reviewlist', // 작성자의 다른 리뷰리스트 페이지에서 도움됐어요 클릭
  HOME_RECENT_REVIEW_LIST = 'homerecent_reviewlist', // 메인 홈 페이지에서 최근 많이 본 리뷰의 도움됐어요 클릭
  HOSPITAL_INFO_REVIEW_LIST = 'hospitalinfo_reviewlist', // 병원 상세 페이지 내 리뷰 탭에서 스크랩하기
  POPULAR_REVIEW_REVIEW_LIST = 'popularreview_reviewlist', // 리뷰 상세페이지 내 인기리뷰 리스트에서 도움됐어요 클릭
}

export enum REVIEW_LIST_LOCATION {
  SYMPTOM_CATEGORY_REVIEW_LIST = 'symptomcategory_reviewlist', // 증상카테고리_리뷰리스트 페이지에서 리뷰 View
  SYMPTOM_SEARCH_REVIEW_LIST = 'symptomsearch_reviewlist', // 증상검색_결과리스트 페이지에서 리뷰 View
  HOSPITAL_DETAIL_REVIEW_LIST = 'hospitaldetail_reviewlist', // 병원상세_리뷰리스트 페이지에서 리뷰 View
  USER_REVIEW_LIST = 'user_reviewlist', // 작성자의 다른 리뷰리스트 페이지에서 리뷰 View
  HOSPITAL_INFO_REVIEW_LIST = 'hospitalinfo_reviewlist', // 병원상세 페이지에서 리뷰 View
}

export const EVENT_VERSION = 'v3';

export enum LOCATION_TYPE {
  HOSPITAL = '병원',
}

export enum SEARCH_TYPE {
  NORMAL = 'normal',
  KEYWORD = 'keyword',
}

export enum SEARCH_LOCATION_HOSPITAL {
  SUBMIT = 'submit',
  RECENT_KEYWORD = 'recentkeyword',
  RECENT_HOSPITAL = 'recenthospital',
}

export enum ALARM_TYPE {
  TODAY = 'today',
  TOMORROW = 'tomorrow',
}

export enum RESERVATION_TYPE {
  RESERVATION_CONFIRM = 'reservation_confirm',
  RESERVATION_CANCEL = 'reservation_cancel',
  RESERVATION_MODIFY = 'reservation_modify',
}
