import { injectGlobal } from '@emotion/css';
import { css, Global } from '@emotion/react';
import React from 'react';
// import desktopSideBannerImg from 'public/images/desktop-side-x3.png';
import { GLOBAL_COLOR, GLOBAL_COLOR_TYPE } from './colors';
import { GLOBAL_LAYOUT_PX, LIMIT_MEDIA_SCREEN_WIDTH } from './layout';
import fonts from './fonts';
import { BASE_FONT_SIZE } from '@/styles/sizes';
import styled from '@emotion/styled';

export const inheritFont = css`
  color: inherit;
  letter-spacing: inherit;
  font-family: inherit;
`;

export const resetAppearance = css`
  appearance: none;
  background: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
`;

export const resetSpacing = css`
  margin: 0;
  padding: 0;
`;

export const a11y = css`
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  border-radius: 0;
  clip: rect(0, 0, 0, 0);
`;

export const fontFamily = [
  'Pretendard',
  '-apple-system',
  'BlinkMacSystemFont',
  'Segoe UI',
  'Roboto',
  'Oxygen',
  'Ubuntu',
  'Cantarell',
  'Fira Sans',
  'Droid Sans',
  'Helvetica Neue',
  'sans-serif',
].join(', ');

export const resetFont = css`
  color: black;
  font-family: ${fontFamily};
  font-weight: 400;
  letter-spacing: -0.02em;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export const ResetStyles = css`
  * {
    -webkit-tap-highlight-color: transparent;
  }
  html {
    ${css([resetSpacing, resetFont])};
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    line-height: 1.6;
    font-size: calc(min(100vw, 450px) / 375 * ${BASE_FONT_SIZE}); /* 지원하는 최대사이즈를 450px로 지정 */

    @media screen and (min-width: ${GLOBAL_LAYOUT_PX.SCREEN_OFFSET}px) {
      background-color: #fafafa;
    }
  }
  body {
    ${css([resetSpacing])};
    overflow-x: hidden;
    div.current-device-provider {
      margin: 0 auto;
      max-width: ${GLOBAL_LAYOUT_PX.SCREEN_OFFSET}px;
      background-color: ${GLOBAL_COLOR.WHITE};
      > div,
      header,
      header > div,
      header > section,
      footer {
        max-width: ${GLOBAL_LAYOUT_PX.SCREEN_OFFSET}px;
        background-color: ${GLOBAL_COLOR.WHITE};
      }
      @supports (position: fixed) {
        > div,
        header,
        header > section {
          left: auto;
        }
      }
    }
    div#login-modal-root,
    div#modal-root,
    div#bottom-sheet-root {
      > section {
        max-width: inherit;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  hr {
    ${css([resetSpacing])};
  }
  p {
    ${css([resetSpacing])};
  }
  button {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    ${css([inheritFont, resetAppearance, resetSpacing])};
    * {
      position: relative;
    }
    &:focus {
      outline: none;
    }
  }
  input {
    ${inheritFont};
    ${resetAppearance};
    ${resetSpacing};

    &::-ms-clear {
      display: none;
    }
    &::-webkit-search-cancel-button {
      display: none;
    }
    &:focus {
      outline: none;
    }
  }
  ul {
    list-style-type: none;
    ${css([resetSpacing])};
  }
  li {
    line-height: initial;
  }
  em {
    font-style: normal;
  }

  a {
    ${inheritFont};
    &:link,
    &:visited {
      text-decoration: none;
    }
    cursor: pointer;
  }

  h1,
  h2,
  h3,
  h4,
  button,
  input,
  p,
  span {
    letter-spacing: -0.02em;
  }

  * {
    .a11y {
      ${css([a11y])};
    }
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -ms-overflow-style: none;
    font-size: inherit;

    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  *:focus {
    outline: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }

  #__next {
    width: 100%;
    height: 100vh;
  }

  #__next > .current-device-provider {
    position: relative;
    width: 100%;
    height: 100vh;
  }

  #__next main, /* 앱 main */
  #__next aside.main /* 메인 흉내를 내야 하는 뷰 */ {
    ${LIMIT_MEDIA_SCREEN_WIDTH};
    position: relative;
    width: 100%;
    min-height: 100%;
    box-sizing: content-box;
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);

    /* SCREEN_OFFSET 초과시 */
    @media screen and (min-width: ${GLOBAL_LAYOUT_PX.SCREEN_OFFSET}px) {
    }
  }

  #__next aside.main {
    min-height: 100vh;
  }

  html.desktop #__next [data-pathname='/mypage'] main {
    background-color: ${GLOBAL_COLOR_TYPE.DESKTOP_MYPAGE_BG};
  }

  html.desktop {
    background-color: ${GLOBAL_COLOR_TYPE.DESKTOP_BACKGROUND};
  }

  html.desktop body > #__next > .current-device-provider {
    @media screen and (min-width: ${GLOBAL_LAYOUT_PX.DESKTOP_PROVIDE_WIDTH}px) {
      width: ${GLOBAL_LAYOUT_PX.DESKTOP_PROVIDE_WIDTH}px;
      position: relative;
      right: initial;
      margin: 0 auto;
      margin-bottom: 0;
    }
  }

  html.desktop body > #__next > .current-device-provider > .current-device-background {
    position: fixed;

    @media screen and (min-width: ${GLOBAL_LAYOUT_PX.DESKTOP_PROVIDE_WIDTH}px) {
      // TODO: resolve image url
      // eslint-disable-next-line no-undef
      // background-image: url(/*$desktopSideBannerImg);
      background-repeat: no-repeat;
      background-size: 520px 573px;
      background-position: -1px center;
      width: ${GLOBAL_LAYOUT_PX.DESKTOP_PROVIDE_WIDTH}px;
      height: 100%;
      height: 100vh;
    }
  }

  html.desktop body > #__next > .current-device-provider > main,
  html.desktop body > #__next > .current-device-provider aside.main {
    @media screen and (min-width: ${GLOBAL_LAYOUT_PX.DESKTOP_PROVIDE_WIDTH}px) {
      margin: 0 0 0 auto;
    }
  }

  img {
    border: none;
  }
`;

// eslint-disable-next-line @typescript-eslint/no-unused-expressions,no-unused-expressions
injectGlobal`
  ${fonts};
  :root {
    --safe-area-inset-top: env(safe-area-inset-top);
    --safe-area-inset-bottom: calc(env(safe-area-inset-bottom) * 0.5);
    --screen-height: calc(100vh - var(--safe-area-inset-bottom));
  }
`;

const GlobalStyle = () => (
  <>
    <Global styles={ResetStyles} />
    <StyledBackgroundSide />
    <StyledBackgroundCenter id="styled-outside-background" />
  </>
);
export default GlobalStyle;

const StyledBackgroundSide = styled.div`
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(255, 247, 237);
`;

const StyledBackgroundCenter = styled.div`
  display: block;
  position: fixed;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  max-width: ${GLOBAL_LAYOUT_PX.SCREEN_OFFSET}px;
  background-color: ${GLOBAL_COLOR.WHITE};
`;
