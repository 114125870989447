import { AddressGeoParams, AddressListPrams, GetAddressByHospitalTagParams } from '@/api/address';
import { BannerParams } from '@/api/banner';
import {
  GetHospitalSimpleReservationParams,
  GetLiveHospitalIdParams,
  GetRecentVisitedHospitalListParams,
  HospitalFilterCountParams,
  HospitalListParams,
  HospitalMapListParams,
  HospitalRecommendParams,
  HospitalSearchParam,
} from '@/api/hospital';
import { MemberReviewParams } from '@/api/member';
import { GetMyReceiptsParams } from '@/api/receipt';
import {
  GetHasHospitalReservationsParams,
  GetHospitalAvailableDate,
  GetHospitalAvailableHoursParams,
  GetHospitalMembersParams,
  GetHospitalReservationMembersParams,
  GetHospitalReserveTypesParams,
  GetLatestPetReservationParams,
} from '@/api/reservation';
import {
  CategoryReviewsParams,
  GetTagRecommendParams,
  HospitalPhotoReviewsParams,
  HospitalReviewsParams,
  HospitalSearchReviewParams,
  ReviewProductParams,
  ReviewRelationHospitalParams,
  ReviewsParams,
} from '@/api/review';
import { GetMyModifyReviewParams, GetMyReviewsParams } from '@/api/reviewWrite';
import { SortOptionalPageable } from '@/constants/common';

/* eslint sort-keys: ["error", "asc", {"caseSensitive": true, "natural": false, "minKeys": 2}] */
export const QUERY_KEYWORD = {
  ADDRESS: 'ADDRESS',
  ALLIANCE: 'ALLIANCE',
  AVAILABLE: 'AVAILABLE',
  BANNER: 'BANNER',
  BY_CODE: 'BY_CODE',
  BY_HOSPITAL_TAG: 'BY_HOSPITAL_TAG',
  CANCELED_TODAY: 'CANCELED_TODAY',
  CANCEL_COUNT: 'CANCEL_COUNT',
  CATEGORY: 'CATEGORY',
  CHANGED_STATUS: 'CHANGED_STATUS',
  COUNT: 'COUNT',
  CURATION: 'CURATION',
  DAY: 'DAY',
  DETAIL: 'DETAIL',
  DISEASE: 'DISEASE',
  EVENT: 'EVENT',
  EXIST: 'EXIST',
  FILTER: 'FILTER',
  GEO: 'GEO',
  HOSPITAL: 'HOSPITAL',
  HOSPITAL_RESERVATION: 'HOSPITAL_RESERVATION',
  HOUR: 'HOUR',
  ID: 'ID',
  INTOLINK: 'INTOLINK',
  LATELY: 'LATELY',
  LATEST_PET: 'LATEST_PET',
  LIKE: 'LIKE',
  LIST: 'LIST',
  LIVE: 'LIVE',
  MAP: 'MAP',
  MEMBER: 'MEMBER',
  MEMBERS: 'MEMBERS',
  MOBILE_NUMBER: 'MOBILE_NUMBER',
  MODIFY: 'MODIFY',
  MONTHLY: 'MONTHLY',
  MY: 'MY',
  MY_INIT_RESERVATION: 'MY_INIT_RESERVATION',
  NOTIFICATIONS: 'NOTIFICATIONS',
  ONGOING_RESERVATION_COUNT: 'ONGOING_RESERVATION_COUNT',
  PET: 'PET',
  PHOTO: 'PHOTO',
  POPULARITY: 'POPULARITY',
  PRODUCT: 'PRODUCT',
  QUESTIONS: 'QUESTIONS',
  RECEIPT: 'RECEIPT',
  RECENT: 'RECENT',
  RECOMMEND: 'LIST',
  RELATION: 'RELATION',
  RESERVATION: 'RESERVATION',
  RESERVE_TYPES: 'RESERVE_TYPES',
  REVIEW: 'REVIEW',
  SCRAP: 'SCRAP',
  SEARCH: 'SEARCH',
  SHORTCUT: 'SHORTCUT',
  SIMPLE_RESERVATION: 'SIMPLE_RESERVATION',
  SYMPTOM: 'SYMPTOM',
  TAG: 'TAG',
  TOTAL: 'TOTAL',
  USERINFO: 'INFO',
};

export const QUERY_BASE_KEYS = {
  HOSPITAL: {
    RESERVATION: () => [QUERY_KEYWORD.HOSPITAL, QUERY_KEYWORD.RESERVATION],
    REVIEW: () => [QUERY_KEYWORD.HOSPITAL, QUERY_KEYWORD.REVIEW],
    SCRAPS: () => [QUERY_KEYWORD.HOSPITAL, QUERY_KEYWORD.SCRAP],
  },
  REVIEW: {
    SCRAP: () => [QUERY_KEYWORD.REVIEW, QUERY_KEYWORD.SCRAP],
  },
};

export const QUERY_KEYS = {
  ADDRESS: {
    BY_CODE: (code?: string) => [QUERY_KEYWORD.ADDRESS, QUERY_KEYWORD.BY_CODE, code],
    BY_HOSPITAL_TAG: (params?: GetAddressByHospitalTagParams) => [
      QUERY_KEYWORD.ADDRESS,
      QUERY_KEYWORD.BY_HOSPITAL_TAG,
      params,
    ],
    GEO: (params: AddressGeoParams) => [QUERY_KEYWORD.ADDRESS, QUERY_KEYWORD.GEO, params],
    LIST: (params: AddressListPrams) => [
      QUERY_KEYWORD.ADDRESS,
      QUERY_KEYWORD.LIST,
      params.level,
      params.address1Code,
      params.address2Code,
    ],
  },
  BANNER: {
    DETAIL: (id: number) => [QUERY_KEYWORD.BANNER, QUERY_KEYWORD.DETAIL, id],
    LIST: (params: BannerParams) => [QUERY_KEYWORD.BANNER, QUERY_KEYWORD.LIST, params],
  },
  CATEGORY: {
    LATELY: () => [QUERY_KEYWORD.CATEGORY, QUERY_KEYWORD.LATELY],
    LIST: () => [QUERY_KEYWORD.CATEGORY, QUERY_KEYWORD.LIST],
  },
  HOSPITAL: {
    ALLIANCE: () => [QUERY_KEYWORD.HOSPITAL, QUERY_KEYWORD.ALLIANCE],
    CURATION: () => [QUERY_KEYWORD.HOSPITAL, QUERY_KEYWORD.CURATION],
    DETAIL: (id: number) => [QUERY_KEYWORD.HOSPITAL, QUERY_KEYWORD.DETAIL, id],
    DISEASES: () => [QUERY_KEYWORD.HOSPITAL, QUERY_KEYWORD.DISEASE, QUERY_KEYWORD.LIST],
    EXIST_DISEASES: () => [QUERY_KEYWORD.HOSPITAL, QUERY_KEYWORD.EXIST, QUERY_KEYWORD.DISEASE, QUERY_KEYWORD.LIST],
    EXIST_SYMPTOMS: () => [QUERY_KEYWORD.HOSPITAL, QUERY_KEYWORD.EXIST, QUERY_KEYWORD.SYMPTOM, QUERY_KEYWORD.LIST],
    FILTER_LIST: (addressCode: string) => [
      QUERY_KEYWORD.HOSPITAL,
      QUERY_KEYWORD.FILTER,
      QUERY_KEYWORD.LIST,
      addressCode,
    ],
    LIST: (params: HospitalListParams) => [QUERY_KEYWORD.HOSPITAL, QUERY_KEYWORD.LIST, params],
    LIST_FILTER_COUNT: (params: HospitalFilterCountParams) => [
      QUERY_KEYWORD.HOSPITAL,
      QUERY_KEYWORD.LIST,
      QUERY_KEYWORD.FILTER,
      QUERY_KEYWORD.COUNT,
      params,
    ],
    LIVE: (params: GetLiveHospitalIdParams) => [QUERY_KEYWORD.HOSPITAL, QUERY_KEYWORD.LIVE, params],
    MAP_LIST: (params: HospitalMapListParams) => [
      QUERY_KEYWORD.HOSPITAL,
      QUERY_KEYWORD.MAP,
      QUERY_KEYWORD.LIST,
      params,
    ],
    RECENT_VISITED_LIST: (params: GetRecentVisitedHospitalListParams) => [
      QUERY_KEYWORD.HOSPITAL,
      QUERY_KEYWORD.RECENT,
      QUERY_KEYWORD.LIST,
      params,
    ],
    RECENT_VISIT_LIST: () => [QUERY_KEYWORD.HOSPITAL, QUERY_KEYWORD.RECENT, QUERY_KEYWORD.LIST],
    RECOMMEND_LIST: (params: HospitalRecommendParams) => [QUERY_KEYWORD.RECOMMEND, QUERY_KEYWORD.LIST, params],
    RECOMMEND_REVIEWS: (params: { pageable: { size: number; page: number; sort: string | string[] } }) => [
      QUERY_KEYWORD.HOSPITAL,
      QUERY_KEYWORD.REVIEW,
      QUERY_KEYWORD.RECOMMEND,
      QUERY_KEYWORD.LIST,
      params,
    ],
    RESERVATION: {
      AVAILABLE_DAYS: (params: GetHospitalAvailableDate) => [
        QUERY_KEYWORD.HOSPITAL,
        QUERY_KEYWORD.RESERVATION,
        QUERY_KEYWORD.AVAILABLE,
        QUERY_KEYWORD.DAY,
        params,
      ],
      AVAILABLE_HOURS: (params: GetHospitalAvailableHoursParams) => [
        QUERY_KEYWORD.HOSPITAL,
        QUERY_KEYWORD.RESERVATION,
        QUERY_KEYWORD.AVAILABLE,
        QUERY_KEYWORD.HOUR,
        params,
      ],
      CHANGED_STATUS: () => [QUERY_KEYWORD.HOSPITAL, QUERY_KEYWORD.RESERVATION, QUERY_KEYWORD.CHANGED_STATUS],
      HOSPITAL_RESERVATION: (params: GetHasHospitalReservationsParams) => [
        QUERY_KEYWORD.HOSPITAL,
        QUERY_KEYWORD.RESERVATION,
        QUERY_KEYWORD.HOSPITAL_RESERVATION,
        params,
      ],
      INTOLINK_MY_INIT_RESERVATION: () => [
        QUERY_KEYWORD.HOSPITAL,
        QUERY_KEYWORD.RESERVATION,
        QUERY_KEYWORD.INTOLINK,
        QUERY_KEYWORD.MY_INIT_RESERVATION,
      ],
      INTOLINK_ONGOING_RESERVATION_COUNT: () => [
        QUERY_KEYWORD.HOSPITAL,
        QUERY_KEYWORD.RESERVATION,
        QUERY_KEYWORD.INTOLINK,
        QUERY_KEYWORD.ONGOING_RESERVATION_COUNT,
      ],

      INTOLINK_RESERVATION_CANCELED_TODAY: () => [
        QUERY_KEYWORD.HOSPITAL,
        QUERY_KEYWORD.RESERVATION,
        QUERY_KEYWORD.INTOLINK,
        QUERY_KEYWORD.CANCELED_TODAY,
      ],
      INTOLINK_RESERVATION_CANCEL_COUNT: () => [
        QUERY_KEYWORD.HOSPITAL,
        QUERY_KEYWORD.RESERVATION,
        QUERY_KEYWORD.INTOLINK,
        QUERY_KEYWORD.CANCEL_COUNT,
      ],

      LATEST_PET: (params: GetLatestPetReservationParams) => [
        QUERY_KEYWORD.HOSPITAL,
        QUERY_KEYWORD.RESERVATION,
        QUERY_KEYWORD.LATEST_PET,
        params,
      ],
      // TODO: 삭제 예정
      MEMBER: (params: GetHospitalMembersParams) => [
        ...QUERY_BASE_KEYS.HOSPITAL.RESERVATION(),
        QUERY_KEYWORD.AVAILABLE,
        QUERY_KEYWORD.MEMBER,
        params,
      ],
      MEMBERS: (params: GetHospitalReservationMembersParams) => [
        QUERY_KEYWORD.HOSPITAL,
        QUERY_KEYWORD.RESERVATION,
        QUERY_KEYWORD.MEMBERS,
        params,
      ],
      MONTHLY: () => [QUERY_KEYWORD.HOSPITAL, QUERY_KEYWORD.RESERVATION, QUERY_KEYWORD.MONTHLY],
      NOTIFICATIONS: () => [QUERY_KEYWORD.HOSPITAL, QUERY_KEYWORD.RESERVATION, QUERY_KEYWORD.NOTIFICATIONS],
      RESERVE_TYPES: (params: GetHospitalReserveTypesParams) => [
        QUERY_KEYWORD.HOSPITAL,
        QUERY_KEYWORD.RESERVATION,
        QUERY_KEYWORD.RESERVE_TYPES,
        params,
      ],
    },
    REVIEW: {
      DETAIL: (reviewId: number) => [...QUERY_BASE_KEYS.HOSPITAL.REVIEW(), QUERY_KEYWORD.DETAIL, reviewId],
      DETAIL_LIST: (params: HospitalReviewsParams) => [
        ...QUERY_BASE_KEYS.HOSPITAL.REVIEW(),
        QUERY_KEYWORD.LIST,
        QUERY_KEYWORD.DETAIL,
        params,
      ],
      LIST: (params: HospitalReviewsParams) => [...QUERY_BASE_KEYS.HOSPITAL.REVIEW(), QUERY_KEYWORD.LIST, params],
      LIST_BY_MEMBER: (params: MemberReviewParams) => [
        ...QUERY_BASE_KEYS.HOSPITAL.REVIEW(),
        QUERY_KEYWORD.LIST,
        QUERY_KEYWORD.MEMBER,
        params,
      ],
      LIST_BY_PHOTO: (params: HospitalPhotoReviewsParams) => [
        ...QUERY_BASE_KEYS.HOSPITAL.REVIEW(),
        QUERY_KEYWORD.LIST,
        QUERY_KEYWORD.PHOTO,
        params,
      ],
    },
    SCRAP: {
      ID_LIST: () => [...QUERY_BASE_KEYS.HOSPITAL.SCRAPS(), QUERY_KEYWORD.ID, QUERY_KEYWORD.LIST],
      LIST: (params: SortOptionalPageable) => [...QUERY_BASE_KEYS.HOSPITAL.SCRAPS(), QUERY_KEYWORD.LIST, params],
    },
    SEARCH_LIST: (params: HospitalSearchParam) => [
      QUERY_KEYWORD.HOSPITAL,
      QUERY_KEYWORD.LIST,
      QUERY_KEYWORD.SEARCH,
      params.searchWord,
      params.addressCode,
    ],
    SEARCH_REVIEWS: (params: HospitalSearchReviewParams) => [
      QUERY_KEYWORD.HOSPITAL,
      QUERY_KEYWORD.REVIEW,
      QUERY_KEYWORD.SEARCH,
      QUERY_KEYWORD.LIST,
      params,
    ],
    SHORTCUT: {
      REVIEW: () => [QUERY_KEYWORD.HOSPITAL, QUERY_KEYWORD.SHORTCUT, QUERY_KEYWORD.REVIEW],
    },
    SIMPLE_RESERVATION_LIST: (params: GetHospitalSimpleReservationParams) => [
      QUERY_KEYWORD.HOSPITAL,
      QUERY_KEYWORD.SIMPLE_RESERVATION,
      QUERY_KEYWORD.LIST,
      params,
    ],
    SYMPTOMS: () => [QUERY_KEYWORD.HOSPITAL, QUERY_KEYWORD.SYMPTOM, QUERY_KEYWORD.LIST],
    TAG: () => [QUERY_KEYWORD.HOSPITAL, QUERY_KEYWORD.TAG],
  },
  MY: {
    LIKE_REVIEW_ID_LIST: () => [
      QUERY_KEYWORD.MY,
      QUERY_KEYWORD.LIKE,
      QUERY_KEYWORD.REVIEW,
      QUERY_KEYWORD.ID,
      QUERY_KEYWORD.LIST,
    ],
    MODIFY_REVIEW: (params: GetMyModifyReviewParams) => [
      QUERY_KEYWORD.MY,
      QUERY_KEYWORD.REVIEW,
      QUERY_KEYWORD.MODIFY,
      QUERY_KEYWORD.REVIEW,
      params.reviewId,
    ],
    PET: () => [QUERY_KEYWORD.MY, QUERY_KEYWORD.PET],
    RECEIPT: (receiptId: number) => [QUERY_KEYWORD.MY, QUERY_KEYWORD.RECEIPT, receiptId],
    RECEIPTS: (params: GetMyReceiptsParams) => [QUERY_KEYWORD.MY, QUERY_KEYWORD.RECEIPT, QUERY_KEYWORD.LIST, params],
    RESERVATIONS: (status: string) => [QUERY_KEYWORD.MY, QUERY_KEYWORD.RESERVATION, QUERY_KEYWORD.LIST, status],
    REVIEWS: (params: GetMyReviewsParams) => [QUERY_KEYWORD.MY, QUERY_KEYWORD.REVIEW, QUERY_KEYWORD.LIST, params],
    SCRAP_REVIEW_ID_LIST: () => [
      QUERY_KEYWORD.MY,
      QUERY_KEYWORD.REVIEW,
      QUERY_KEYWORD.SCRAP,
      QUERY_KEYWORD.ID,
      QUERY_KEYWORD.LIST,
    ],
    USERINFO: () => [QUERY_KEYWORD.MY, QUERY_KEYWORD.USERINFO],
  },
  RESERVATIONS: {
    DETAIL: (reservationId: number) => [QUERY_KEYWORD.RESERVATION, reservationId],
    MOBILE_NUMBER: (mobileNumber: string) => [QUERY_KEYWORD.RESERVATION, QUERY_KEYWORD.MOBILE_NUMBER, mobileNumber],
  },
  REVIEW: {
    CATEGORY_REVIEW: (params: CategoryReviewsParams) => [QUERY_KEYWORD.REVIEW, QUERY_KEYWORD.CATEGORY, params],
    DISEASE_QUESTIONS: () => [QUERY_KEYWORD.REVIEW, QUERY_KEYWORD.DISEASE, QUERY_KEYWORD.QUESTIONS],
    POPULARITY_REVIEW: (params: ReviewsParams) => [QUERY_KEYWORD.REVIEW, QUERY_KEYWORD.POPULARITY, params],
    REVIEW_PRODUCT: (params: ReviewProductParams) => [QUERY_KEYWORD.REVIEW, QUERY_KEYWORD.PRODUCT, params],
    REVIEW_RELATION_HOSPITAL: (params: ReviewRelationHospitalParams) => [
      QUERY_KEYWORD.REVIEW,
      QUERY_KEYWORD.RELATION,
      QUERY_KEYWORD.HOSPITAL,
      params,
    ],
    SCRAP_LIST: (params: SortOptionalPageable) => [
      QUERY_KEYWORD.REVIEW,
      QUERY_KEYWORD.SCRAP,
      QUERY_KEYWORD.LIST,
      params,
    ],
    SYMPTOM_QUESTIONS: () => [QUERY_KEYWORD.REVIEW, QUERY_KEYWORD.SYMPTOM, QUERY_KEYWORD.QUESTIONS],
    TAG_RECOMMEND: (params: GetTagRecommendParams) => [
      QUERY_KEYWORD.REVIEW,
      QUERY_KEYWORD.TAG,
      QUERY_KEYWORD.RECOMMEND,
      params,
    ],
    TOTAL_COUNT: () => [QUERY_KEYWORD.REVIEW, QUERY_KEYWORD.TOTAL, QUERY_KEYWORD.COUNT],
  },
};
