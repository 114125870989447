import { HospitalCurationType } from '@/api/hospital';
import { hashRegExp, textExceptHashRegExp, textRegExp } from '@/utils/regex';

export const comma = (number: number) => {
  if (!number) {
    return 0;
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const parseToNumber = (value: string | undefined | null): number | undefined => {
  try {
    const num = Number(value);
    return !Number.isNaN(num) ? num : undefined;
  } catch {
    return undefined;
  }
};

export const textClean = (text: string | undefined | null) => {
  if (!text) {
    return '';
  }
  return text?.replace(textRegExp, '');
};

export const textExceptHashClean = (text: string | undefined | null) => {
  if (!text) {
    return '';
  }
  return text?.replace(textExceptHashRegExp, '');
};

export const hashClean = (text: string | undefined | null) => {
  if (!text) {
    return '';
  }
  return text?.replace(hashRegExp, '');
};

export const phoneNumberParser = (phone: string) => {
  return phone.replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, '$1-$2-$3').replace(/(-{1,2})$/g, '');
};

export const checkTextInclusion = (text: string, includedText: string) => {
  return text.includes(includedText);
};

export const addCommaToNumber = (totalNumber: number | undefined) => {
  if (totalNumber) {
    return totalNumber.toLocaleString('ko-KR');
  }
  return 0;
};

export const parseJSONSafe = (jsonString: string) => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    console.error(e);
  }
  return '';
};

export const convertUpperCaseCurationType = (curationType: keyof typeof HospitalCurationType) => {
  return curationType.toLowerCase().replace(/_/g, '-');
};
