import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { isInAppFlag } from '@/utils/utilBridge';

export const BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL || 'https://hospital-api.fitpet.dev/user/api';

export interface ResponseErrorData {
  errorCode?: string;
  errorMessage?: string;
  timestamp?: Date;
}

export interface ResponseError extends AxiosError<ResponseErrorData> {}

export enum API_ERROR_CODE {
  FORBIDDEN_WORDS_CONTAINS = 'FORBIDDEN_WORDS_CONTAINS',
}

export const instance = axios.create({
  headers: { 'content-type': 'application/json' },
});

export const setToken = (token: string) => {
  instance.defaults.headers.common.Authorization = token;
};

export const getToken = () => {
  return instance.defaults.headers.common.Authorization;
};

instance.interceptors.request.use(
  (config) => {
    const { token } = isInAppFlag ? window.getToken().apiToken : { token: undefined };
    if (token && config.headers) {
      if (!config.headers.Authorization) {
        config.headers.Authorization = token;
      }
    }
    return config;
  },
  (error: AxiosError) => Promise.reject(error),
);

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error: ResponseError) => {
    return Promise.reject(error);
  },
);

export const HTTP = {
  get: <ResponseType,>(url: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ResponseType>> =>
    instance.get(url, options),
  post: <ParamType, ResponseType>(
    url: string,
    param?: ParamType,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<ResponseType>> => instance.post(url, param, options),
  patch: <ParamType, ResponseType>(
    url: string,
    param?: ParamType,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<ResponseType>> => instance.patch(url, param, options),
  delete: <ResponseType,>(url: string, options?: AxiosRequestConfig): Promise<AxiosResponse<ResponseType>> =>
    instance.delete(url, options),
  put: <ParamType, ResponseType>(
    url: string,
    param?: ParamType,
    options?: AxiosRequestConfig,
  ): Promise<AxiosResponse<ResponseType>> => instance.put(url, param, options),
};
