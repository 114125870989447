import qs from 'qs';
import {
  BrandResponse,
  Bridge,
  EventTrackingOption,
  GetCurrentPositionResponse,
  InspectionBasicResponse,
  InspectionDentalResponse,
  InspectionHistoryResponse,
  SetPetInfoResponse,
  ShareType,
} from './bridge';
import { MAP_TYPE } from '@/utils/bridge/constants';
import { isInApp } from '@/utils/deviceUtils';
import { PetType } from '@/utils/tracker/constants';

const ONELINK_HOST = process.env.NEXT_PUBLIC_ONELINK_HOST;

export class InAppBridge implements Bridge {
  private bridge?: Bridge;

  init = (): void => {
    if (isInApp()) {
      // eslint-disable-next-line no-underscore-dangle
      if (window.__FitPetBridge__) this.bridge = window.__FitPetBridge__;
    }
  };

  intro = (): Promise<object> | undefined => {
    return this.bridge?.intro();
  };

  // IOS 결제시 에서 login_type : APPLE  이고 phone 이 없는 경우는 로그인 대신 호출해야 함
  authentication = (): Promise<{ status: boolean }> | undefined => {
    return this.bridge?.authentication();
  };

  login = (): Promise<object> | undefined => {
    return this.bridge?.login();
  };

  logout = (): Promise<object> | undefined => {
    return this.bridge?.logout();
  };

  acceptTerms = (): Promise<object> | undefined => {
    return this.bridge?.acceptTerms();
  };

  goAccountsRegister = (): void => {
    return this.bridge?.goAccountsRegister();
  };

  picture = (): Promise<object> | undefined => {
    return this.bridge?.picture();
  };

  isInstalledCheckIosApp = () => {
    return this.bridge?.isInstalledCheckIosApp();
  };

  goToKakaoMap = (latitude: number | undefined, longitude: number | undefined): void => {
    this.bridge?.goToKakaoMap(latitude, longitude);
  };

  goToNaverMap = (url: string): void => {
    this.bridge?.goToNaverMap(url);
  };

  goToTMap = (url: string): void => {
    this.bridge?.goToTMap(url);
  };

  goToGoogleMap = (params: { lat: number; lon: number; locationName: string }): void => {
    this.bridge?.goToGoogleMap(params);
  };

  getCurrentPosition = (): Promise<GetCurrentPositionResponse> | undefined => {
    return this.bridge?.getCurrentPosition();
  };

  setPetInfo = (): Promise<SetPetInfoResponse> | undefined => {
    return this.bridge?.setPetInfo();
  };

  goAppUpdateAction = () => {
    return this.bridge?.goAppUpdateAction();
  };

  openBrowser = (href: string, type?: string) => {
    this.bridge?.openBrowser(href, type);
  };

  amplitudeEvent = (options: EventTrackingOption) => {
    this.bridge?.amplitudeEvent(options);
  };

  appsFlyerEvent = (options: EventTrackingOption) => {
    this.bridge?.appsFlyerEvent(options);
  };

  brazeEvent = (options: EventTrackingOption) => {
    this.bridge?.brazeEvent(options);
  };

  brazeRevenueEvent = (options: EventTrackingOption) => {
    this.bridge?.brazeRevenueEvent(options);
  };

  amplitudeRevenueEvent = (options: EventTrackingOption) => {
    this.bridge?.amplitudeRevenueEvent(options);
  };

  share = ({
    title,
    link,
    deepLink,
  }: {
    title: string;
    link: string;
    deepLink: string;
  }): Promise<object> | undefined => {
    return this.bridge?.share({ title, link, deepLink });
  };

  linkShare = (param: ShareType): Promise<object> => {
    // 공유시 삭제 해야 할키
    // @ts-ignore
    delete param?.query?.productId;

    // link 설정
    const link = param?.link || ONELINK_HOST;
    // @ts-ignore
    // param.query.location = ''
    // 이벤트 파라미터 추가
    return this.bridge?.linkShare({
      title: param.title,
      link: `${link}?${qs.stringify(param.query)}`,
      event: param.event,
    });
  };

  goDeepLink = (serviceType: string, path?: string) => {
    this.bridge?.goDeepLink(serviceType, path);
  };

  setting = (param: object): Promise<object> | undefined => {
    return this.bridge?.setting(param);
  };

  storageGetItem = (keyName: string) => {
    return this.bridge?.storageGetItem(keyName);
  };

  storageSetItem = (keyName: string, param: object | string) => {
    return this.bridge?.storageSetItem(keyName, param);
  };

  storageRemoveItem = (keyName: string) => {
    return this.bridge?.storageRemoveItem(keyName);
  };

  storageClear = () => {
    return this.bridge?.storageClear();
  };

  routerPush = (url: string, data?: object): Promise<object> | undefined => {
    return this.bridge?.routerPush(url, data);
  };

  routerGo = (step: number): Promise<object> | undefined => {
    return this.bridge?.routerGo(step);
  };

  routerReplace = (url: string, data?: object): Promise<object> | undefined => {
    return this.bridge?.routerReplace(url, data);
  };

  completeLayout = (): Promise<object> | undefined => {
    // console.log('[App Bridge] completeLayout::', 'completeLayout');
    // return this.setCallbackStack('routerGo', (callbackName: string) => this.bridge?.routerGo(step, callbackName))
    return this.bridge?.completeLayout();
  };

  routerPathTracking = ({ path }: { path: string }): Promise<object> | undefined => {
    // console.log('[App Bridge] routerPathTracking::', 'routerPathTracking');
    // return this.setCallbackStack('routerGo', (callbackName: string) => this.bridge?.routerGo(step, callbackName))
    return this.bridge?.routerPathTracking({ path });
  };

  openPopupOutsideLink = ({ path }: { path: string }): Promise<object> | undefined => {
    // console.log('[App Bridge] openPopupOutsideLink::', 'openPopupOutsideLink');
    // return this.setCallbackStack('routerGo', (callbackName: string) => this.bridge?.routerGo(step, callbackName))
    return this.bridge?.openPopupOutsideLink({ path });
  };

  copyClipBoard = ({ address }: { address: string }): Promise<object> | undefined => {
    // eslint-disable-next-line no-console
    console.log('[App Bridge] copyClipBoard::', 'copyClipBoard');
    // return this.setCallbackStack('routerGo', (callbackName: string) => this.bridge?.routerGo(step, callbackName))
    return this.bridge?.copyClipBoard({ address });
  };

  getAuthStatePicture = ({ limit }: { limit: number }): Promise<object> | undefined => {
    // eslint-disable-next-line no-console
    console.log('[App Bridge] getAuthStatePicture::', 'getAuthStatePicture');
    // return this.setCallbackStack('routerGo', (callbackName: string) => this.bridge?.routerGo(step, callbackName))
    return this.bridge?.getAuthStatePicture({ limit });
  };

  keyboardShowToggle = ({ isShow }: { isShow: boolean }): Promise<object> | undefined => {
    return this.bridge?.keyboardShowToggle({ isShow });
  };

  iosBounceToggle = ({ isShow }: { isShow: boolean }): Promise<object> | undefined => {
    return this.bridge?.iosBounceToggle({ isShow });
  };

  statusBarColorWhite = ({ isWhite }: { isWhite: boolean }): Promise<object> | undefined => {
    return this.bridge?.statusBarColorWhite({ isWhite });
  };

  goMap = ({ appName }: { appName: MAP_TYPE }): Promise<{ status: boolean }> | undefined => {
    return this.bridge?.goMap({ appName });
  };

  goToStore = ({ appName }: { appName: MAP_TYPE }): void => {
    return this.bridge?.goToStore({ appName });
  };

  getUserPetType = (): Promise<{ petType: PetType }> | undefined => {
    return this.bridge?.getUserPetType();
  };

  setUserPetType = ({ petType }: { petType: PetType }): void => {
    return this.bridge?.setUserPetType({ petType });
  };

  checkLocationPermission = (): Promise<{ status: boolean }> | undefined => {
    return this.bridge?.checkLocationPermission();
  };

  getPicture = ({ isCamera }: { isCamera: boolean }): Promise<{ result: string[] }> | undefined => {
    return this.bridge?.getPicture({ isCamera });
  };

  inspectionBasic = (): Promise<InspectionBasicResponse> | undefined => {
    return this.bridge?.inspectionBasic();
  };

  inspectionDental = (): Promise<InspectionDentalResponse> | undefined => {
    return this.bridge?.inspectionDental();
  };

  inspectionHistory = (): Promise<InspectionHistoryResponse> | undefined => {
    return this.bridge?.inspectionHistory();
  };

  brand = ({ brandId }: { brandId: number }): Promise<BrandResponse> | undefined => {
    return this.bridge?.brand({ brandId });
  };
}
