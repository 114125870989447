import styled from '@emotion/styled';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import FlatIcon from '@/components/common/FlatIcon';
import useFloatingStore from '@/components/floating/useFloatingStore';
import { TOAST_ANIMATION_TIME, TOAST_MARGIN, TOAST_SIDE_PADDING } from '@/constants/toast';
import useAppRouter from '@/hooks/useAppRouter';
import { GLOBAL_COLOR } from '@/styles/colors';
import { GLOBAL_LAYOUT_PX } from '@/styles/layout';
import { Z_INDEX } from '@/styles/zIndex';
import { toRem } from '@/utils/commonUtils';

const GlobalFloating = () => {
  const router = useAppRouter();
  const { floatingList } = useFloatingStore((state) => state);
  const floatingRef = useRef<HTMLDivElement>(null);

  const floatingData = useMemo(() => {
    return floatingList?.[0];
  }, [floatingList]);

  const onCloseFloating = useCallback(() => {
    floatingData.onClose();
  }, [floatingData]);

  useEffect(() => {
    if (!floatingList?.length) {
      return;
    }
    if (floatingData) {
      // Add animation class when the floating popup appears
      floatingRef?.current?.classList.add('show-animation');
    }
  }, [floatingData, floatingList?.length]);

  if (floatingData?.allowedPaths && !floatingData?.allowedPaths?.includes(router.pathname)) {
    return null;
  }

  return (
    <GlobalFloatingContainer ref={floatingRef}>
      <GlobalFloatingWrapper>
        {floatingData?.content && (
          <ContentWrapper>
            <ContentContainer>{floatingData?.content}</ContentContainer>
            <ButtonContainer onClick={onCloseFloating}>
              <FlatIcon iconType={'icClose'} size={16} color={GLOBAL_COLOR.GRAY_300} />
            </ButtonContainer>
          </ContentWrapper>
        )}
      </GlobalFloatingWrapper>
    </GlobalFloatingContainer>
  );
};

export default GlobalFloating;

const GlobalFloatingContainer = styled.section`
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  width: calc(100% - ${TOAST_SIDE_PADDING}px);
  max-width: calc(${GLOBAL_LAYOUT_PX.SCREEN_OFFSET}px - ${TOAST_SIDE_PADDING}px);
  bottom: -2000px;
  animation: fadeIn ${TOAST_ANIMATION_TIME.FLOATING_DURATION}s ease-in-out forwards;
  z-index: ${Z_INDEX.NOTIFICATIONS};

  @keyframes fadeIn {
    0% {
      bottom: -2000px;
    }
    100% {
      bottom: calc(env(safe-area-inset-bottom) + ${TOAST_MARGIN.SMALL}px);
    }
  }
`;

const GlobalFloatingWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: ${toRem(48)};
  background-color: rgba(27, 30, 33, 0.9);
  margin-top: ${toRem(8)};
  border-radius: ${toRem(200)};
  backdrop-filter: blur(toRem(2));
`;

const ContentContainer = styled.div`
  width: 100%;
  position: relative;
`;

const ButtonContainer = styled.div`
  padding: ${toRem(4)};
  margin-right: ${toRem(12)};
  display: flex;
  align-items: center;
  font-size: ${toRem(14)};
  font-weight: 400;
  line-height: ${toRem(20)};
  color: ${GLOBAL_COLOR.BLUE_200};
`;
