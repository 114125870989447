import React, { CSSProperties, ReactNode } from 'react';
import BaseModal from './BaseModal';
import SingleButton from '@/components/common/buttons/SingleButton';
import BodyContainer from '@/components/modal/BaseBodyContainer';
import BaseButtonContainer, { BaseButtonContainerProps } from '@/components/modal/BaseButtonContainer';
import BaseContainer from '@/components/modal/BaseContainer';
import TextTitle from '@/components/modal/TextTitle';
import { ModalProps } from '@/hooks/useModal';

export interface ConfirmModalProps<D> extends BaseButtonContainerProps {
  title?: string;
  contents?: ReactNode;
  confirmText?: string;
  cancelText?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  modalProps: ModalProps<D>;
  contentsOutSideTouchClosed?: boolean;
  contentStyle?: CSSProperties;
  confirmBackgroundColor?: string;
}

const ConfirmModal = <D extends any>({
  title = '',
  contents = <></>,
  confirmText = '확인',
  cancelText = '취소',
  onConfirm,
  onCancel,
  modalProps,
  contentStyle,
  contentsOutSideTouchClosed = false,
  confirmBackgroundColor,
  ...baseButtonContainerProps
}: ConfirmModalProps<D>) => {
  const handleCancelClick = () => {
    modalProps.hideModal();
    if (onCancel) {
      modalProps.setHideModalAfterCallbackFn(onCancel);
    }
    return true;
  };

  const handleConfirmClick = () => {
    modalProps.hideModal();
    if (onConfirm) {
      modalProps.setHideModalAfterCallbackFn(onConfirm);
    }
    return true;
  };

  return (
    <BaseModal contentsOutSideTouchClosed={contentsOutSideTouchClosed} modalProps={modalProps}>
      <BaseContainer>
        <BodyContainer style={contentStyle}>
          {title && <TextTitle>{title}</TextTitle>}
          {contents}
        </BodyContainer>
        <BaseButtonContainer {...baseButtonContainerProps}>
          <SingleButton buttonType={'secondary'} onClick={handleCancelClick} fontWeight={500}>
            {cancelText}
          </SingleButton>
          <SingleButton buttonType={'primary'} onClick={handleConfirmClick} backgroundColor={confirmBackgroundColor}>
            {confirmText}
          </SingleButton>
        </BaseButtonContainer>
      </BaseContainer>
    </BaseModal>
  );
};

export default ConfirmModal;
