import { Url } from 'url';
import { isInApp } from './deviceUtils';
import { InAppBridge } from './bridge';

const getFullPath = (url: any) => {
  // if (typeof url === 'string') return `${maps.origin}${url}`
  // return (url.query) ? `${maps.origin}${Object.keys(url.query).reduce((p: string, c: string) => p.replace(`[${c}]`, url.query[c]), url.pathname)}` : `${maps.origin}${url.pathname}`
  // https, http 로 시작하는 경우 풀패스로 인식해서 현재 패스를 사용하지 않는다.
  const host = window.location.origin;
  console.log('getFullPath::', host);
  console.log('getFullPath::url::', url);
  if (typeof url === 'string') {
    // https, http 로 시작하는 경우 풀패스로 인식해서 현재 패스를 사용하지 않는다.
    if (url.startsWith('http://') || url.startsWith('https://')) {
      return url;
    }
    return `${host}${url}`;
  }

  let fullPath = '';

  if (url.query && Object.keys(url.query).length > 0) {
    fullPath = `${Object.keys(url.query).reduce(
      (p: string, c: string) => p.replace(`[${c}]`, url.query[c]),
      url.pathname,
    )}?${Object.keys(url.query)
      .map((v) => `${v}=${url.query[v]}`)
      .join('&')}`;

    if (fullPath.startsWith('http://') || fullPath.startsWith('https://')) {
      return fullPath;
    }
    if (fullPath.startsWith('/')) {
      return `${host}${fullPath}`;
    }
    return `${host}/${fullPath}`;
  }

  return `${host}${url.pathname}`;
};

let appBridge: InAppBridge | null = null;

// eslint-disable-next-line consistent-return
const appBridgeProvider = (callback: Function) => {
  console.log('appBridgeProvider::');
  if (isInApp()) {
    if (!appBridge) {
      appBridge = new InAppBridge();
      appBridge.init();
    }
    return callback(appBridge);
  }
  console.error('is Not APP');
};

export const appBridgeRouter = () => {
  if (!appBridge) {
    appBridge = new InAppBridge();
    appBridge.init();
  }

  return {
    push(url: Url | string, as?: Url, options?: object): Promise<any> | undefined {
      // @ts-ignore
      return appBridge
        ?.routerPush(getFullPath(url), options)
        .then((res: object) => console.log('useRouter::routerPush::then::res::', res));
    },
    back(): Promise<object> | undefined {
      return appBridge?.routerGo(-1);
    },
    go(step: number = -999): Promise<object> | undefined {
      return appBridge?.routerGo(parseInt(window.getAppVersion(), 10) < 41 ? -1 : step);
    },
    replace(url: Url | string, as?: Url, options?: object): Promise<object> | undefined {
      return appBridge?.routerReplace(getFullPath(url), options);
    },
  };
  // }
};

export const appBridgeLogin = () => {
  if (!appBridge) {
    appBridge = new InAppBridge();
    appBridge.init();
  }
  return appBridge?.login();
};

// url 조립하는 함수
// export const getUrl = getFullPath
export const isInAppFlag = isInApp();
// export const useAppLogin = useLogin
// export const useAppLogout = useLogout
export default appBridgeProvider;
