export const TOAST_MARGIN = {
  SMALL: 26,
  LARGE: 88,
};

export const TOAST_ANIMATION_TIME = {
  TIME_OUT: 2,
  FLOATING_DURATION: 1,
  FADE_IN_DELAY: 0.1,
  FADE_IN_DURATION: 0.2,
  FADE_OUT_DELAY: 2,
  FADE_OUT_DURATION: 0.3,
};

export const TOAST_SIDE_PADDING = 32;
