// 공통 색상
export const MODAL_BACKGROUND = '#00000080';

export const GLOBAL_COLOR = {
  // BLACK
  BLACK: '#000000',
  // WHITE
  WHITE: '#FFFFFF',
  // GRAY
  GRAY_900: '#1B1E21',
  GRAY_800: '#2C3136',
  GRAY_700: '#42494F',
  GRAY_600: '#5A6169',
  GRAY_500: '#727A82',
  GRAY_400: '#8C949C',
  GRAY_300: '#A7AEB5',
  GRAY_200: '#C2C8CF',
  GRAY_100: '#DFE3E8',
  GRAY_70: '#EEF1F5',
  GRAY_50: '#F4F7FA',
  GRAY_30: '#FAFBFD',

  // BLUE
  BLUE_900: '#002999',
  BLUE_800: '#0035A8',
  BLUE_700: '#0041CC',
  BLUE_600: '#0048E3',
  BLUE_500: '#0050FF',
  BLUE_400: '#4580FF',
  BLUE_300: '#6E9EFF',
  BLUE_200: '#8FB8FF',
  BLUE_100: '#B2CFFF',
  BLUE_70: '#CCDFFF',
  BLUE_50: '#E3EDFF',
  BLUE_30: '#EDF4FF',

  // RED
  RED_900: '#991F25',
  RED_800: '#B22B32',
  RED_700: '#CC313E',
  RED_600: '#E5404E',
  RED_500: '#FF5967',
  RED_400: '#FF737E',
  RED_300: '#FF99A2',
  RED_200: '#FFB2B9',
  RED_100: '#FFCFD3',
  RED_70: '#FFDDE0',
  RED_50: '#FFEDEE',
  RED_30: '#FFF5F6',

  // YELLOW
  YELLOW_900: '#997B0E',
  YELLOW_700: '#DEB215',
  YELLOW_500: '#FFD633',
  YELLOW_300: '#FFE880',
  YELLOW_100: '#FFF3B2',

  // MINT
  MINT_900: '#137980',
  MINT_800: '#188E96',
  MINT_700: '#1DA4AD',
  MINT_600: '#22B0BA',
  MINT_500: '#31BEC8',
  MINT_400: '#4ED0D9',
  MINT_300: '#71DBE3',
  MINT_200: '#8EE7ED',
  MINT_100: '#ABF0F5',
  MINT_70: '#C8F7FA',
  MINT_50: '#E0FDFF',
  MINT_30: '#EDFEFF',

  // ORANGE
  ORANGE_900: '#BD5500',
  ORANGE_800: '#CC5F00',
  ORANGE_700: '#E06E02',
  ORANGE_600: '#F28100',
  ORANGE_500: '#FF9300',
  ORANGE_400: '#FFA933',
  ORANGE_300: '#FFB44F',
  ORANGE_200: '#FFC575',
  ORANGE_100: '#FFDCAB',
  ORANGE_70: '#FFE6C4',
  ORANGE_50: '#FFEFD9',
  ORANGE_30: '#FFF7ED',
};

export const GLOBAL_COLOR_TYPE = {
  DESKTOP_BACKGROUND: GLOBAL_COLOR.WHITE,
  DESKTOP_MAIN_BG: GLOBAL_COLOR.WHITE,
  DESKTOP_MYPAGE_BG: GLOBAL_COLOR.WHITE,
  DIVIDER_COLOR: GLOBAL_COLOR.GRAY_50, // border-color 공통색상
  BACKGROUND: GLOBAL_COLOR.GRAY_50, // 병원 화면 여백 공통색상
  MODAL_BACKGROUND: '#00000080',
  SUCCESS: '#0D9A38',
};
