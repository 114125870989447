export const ROUTE_QUERY_KEYS = {
  HOSPITAL_ID: 'hospitalId',
  SYMPTOM_ID: 'symptomId',
  CATEGORY_ID: 'categoryId',
  CURATION_TYPE: 'curationType',
  CURATION_TITLE: 'curationTitle',
  SUB_CATEGORY_ID: 'subCategoryId',
  RESERVATION_ID: 'reservationId',
  SEARCH_VALUE: 'searchValue',
  REVIEW_ID: 'reviewId',
  MEMBER_ID: 'memberId',
  KEYWORD: 'keyword',
  ANIMAL: 'animal',
  SEARCH_POSITION: 'searchPosition',
  IS_FULLSCREEN: 'is_fullscreen',
  EVENT_ID: 'eventId',
  RECEIPT_ID: 'receiptId',
  SCROLL: 'scroll',
  RESERVATION_LIST_STATE: 'reservationListState',
  PET_ID: 'petId',
  RESERVE_TYPE: 'reserveType',
  HOSPITAL_MEMBER_ID: 'hospitalMemberId',
  PATH_PAGE: 'pathPage',
  PATH_LOCATION: 'pathLocation',
  PREVIOUS_PAGE: 'previousPage',
  IS_SIMPLE_RESERVATION_AVAILABLE: 'isSimpleReservationAvailable',
  IS_RESERVATION_POSSIBLE: 'isReservationPossible',
  IS_WORKING: 'isWorking',
  ADDRESS_CODE: 'addressCode',
  SORT_BY: 'sortBy',
};

export const ROUTE_KEYS = {
  ...ROUTE_QUERY_KEYS,
  CREATE: 'create',
  CATEGORIES: 'categories',
  CURATIONS: 'curations',
  CURATION_TYPE: 'curationType',
  GROOMING: 'grooming',
  HOSPITALS: 'hospitals',
  INDEX: '/',
  LOCATION: 'location',
  MEMBER: 'member',
  MYPAGE: 'mypage',
  PHOTO_REVIEWS: 'photo-reviews',
  PORTFOLIOS: 'portfolios',
  RESERVATIONS: 'reservations',
  REVIEWS: 'reviews',
  SEARCH: 'search',
  RESULT: 'result',
  OLD_UPDATE: 'oldUpdate',
  UPDATE: 'update',
  REPORT: 'report',
  DETAIL: 'detail',
  MAP: 'map',
  SYMPTOM: 'symptom',
  SEARCH_MAP: 'search-map',
  EVENT: 'event',
  MY: 'my',
  NEW: 'new',
  RECEIPTS: 'receipts',
  SCRAP: 'scrap',
  QNA: 'qna',
  PLAY: 'play',
  POLICY: 'policy',
  INTRO: 'intro',
  MIGRATE: 'migrate',
  RECENT_VISITED: 'recent-visited',
  ADMIN: 'admin',
  CRM_DEVELOPMENT: 'crm-development',
  CREATE_QUERY: 'create-query',
};

const ROUTE_BASE = {
  HOSPITALS: `${ROUTE_KEYS.HOSPITALS}`,
  RESERVATIONS: `${ROUTE_KEYS.RESERVATIONS}`,
  CATEGORIES: `${ROUTE_KEYS.CATEGORIES}`,
  REVIEWS: `${ROUTE_KEYS.REVIEWS}`,
  MAP: `${ROUTE_KEYS.MAP}`,
  MEMBER: `${ROUTE_KEYS.MEMBER}`,
  SCRAP: `${ROUTE_KEYS.SCRAP}`,
  MYPAGE: `${ROUTE_KEYS.MYPAGE}`,
  ADMIN: `${ROUTE_KEYS.ADMIN}`,
};

export const ROUTES = {
  HOME: `/`,
  CATEGORIES: {
    LIST: `/${ROUTE_BASE.CATEGORIES}`,
    REVIEWS: `/${ROUTE_BASE.CATEGORIES}/${ROUTE_KEYS.REVIEWS}`,
  },
  HOSPITALS: {
    LIST: `/${ROUTE_BASE.HOSPITALS}`,
    DETAIL: `/${ROUTE_BASE.HOSPITALS}/[${ROUTE_KEYS.HOSPITAL_ID}]`,
    RESERVATION: `/${ROUTE_BASE.HOSPITALS}/[${ROUTE_KEYS.HOSPITAL_ID}]/${ROUTE_KEYS.RESERVATIONS}`,
    CREATE: `/${ROUTE_BASE.HOSPITALS}/[${ROUTE_KEYS.HOSPITAL_ID}]/${ROUTE_KEYS.RESERVATIONS}/${ROUTE_KEYS.CREATE}`,
    LOCATION: `/${ROUTE_BASE.HOSPITALS}/[${ROUTE_KEYS.HOSPITAL_ID}]/${ROUTE_KEYS.LOCATION}`,
    REVIEWS: `/${ROUTE_BASE.HOSPITALS}/[${ROUTE_KEYS.HOSPITAL_ID}]/${ROUTE_KEYS.REVIEWS}`,
    REVIEWS_DETAIL: `/${ROUTE_BASE.HOSPITALS}/${ROUTE_KEYS.REVIEWS}/[${ROUTE_KEYS.REVIEW_ID}]`,
    PHOTO_REVIEWS: `/${ROUTE_BASE.HOSPITALS}/[${ROUTE_KEYS.HOSPITAL_ID}]/${ROUTE_KEYS.PHOTO_REVIEWS}`,
    PORTFOLIOS: `/${ROUTE_BASE.HOSPITALS}/[${ROUTE_KEYS.HOSPITAL_ID}]/${ROUTE_KEYS.PORTFOLIOS}`,
    MAP: `/${ROUTE_BASE.HOSPITALS}/${ROUTE_KEYS.MAP}`,
    NEW: `/${ROUTE_BASE.HOSPITALS}/${ROUTE_KEYS.NEW}`,
    REVIEW_CREATE: `/${ROUTE_BASE.HOSPITALS}/[${ROUTE_KEYS.HOSPITAL_ID}]/${ROUTE_KEYS.REVIEWS}/${ROUTE_KEYS.CREATE}`,
    SEARCH_MAP: `/${ROUTE_BASE.HOSPITALS}/${ROUTE_KEYS.MAP}/${ROUTE_KEYS.SEARCH_MAP}`,
    RECENT_VISITED: `/${ROUTE_BASE.HOSPITALS}/${ROUTE_KEYS.RECENT_VISITED}`,
    CURATION_DETAIL: `/${ROUTE_BASE.HOSPITALS}/${ROUTE_KEYS.CURATIONS}/[${ROUTE_KEYS.CURATION_TYPE}]`,
  },
  SEARCH: {
    HOSPITAL: `/${ROUTE_KEYS.SEARCH}/${ROUTE_KEYS.HOSPITALS}`,
    SYMPTOM: `/${ROUTE_KEYS.SEARCH}/${ROUTE_KEYS.SYMPTOM}`,
  },
  RESERVATIONS: {
    LIST: `/${ROUTE_BASE.RESERVATIONS}`,
    CREATE: `/${ROUTE_BASE.HOSPITALS}/[${ROUTE_KEYS.HOSPITAL_ID}]/${ROUTE_BASE.RESERVATIONS}/${ROUTE_KEYS.CREATE}`,
    MIGRATE: `/${ROUTE_BASE.RESERVATIONS}/${ROUTE_KEYS.MIGRATE}`,
    DETAIL: `/${ROUTE_BASE.RESERVATIONS}/[${ROUTE_KEYS.RESERVATION_ID}]`,
    UPDATE: `/${ROUTE_BASE.RESERVATIONS}/[${ROUTE_KEYS.RESERVATION_ID}]/${ROUTE_KEYS.UPDATE}`,
    POLICY: `/${ROUTE_BASE.RESERVATIONS}/${ROUTE_KEYS.POLICY}`,
  },
  REVIEWS: {
    LIST: `/${ROUTE_BASE.REVIEWS}`,
    REPORT: `/${ROUTE_BASE.REVIEWS}/[${ROUTE_KEYS.REVIEW_ID}]/${ROUTE_KEYS.REPORT}`,
    OLD_UPDATE: `/${ROUTE_KEYS.HOSPITALS}/${ROUTE_KEYS.REVIEWS}/[${ROUTE_KEYS.REVIEW_ID}]/${ROUTE_KEYS.OLD_UPDATE}`,
    UPDATE: `/${ROUTE_KEYS.HOSPITALS}/${ROUTE_KEYS.REVIEWS}/[${ROUTE_KEYS.REVIEW_ID}]/${ROUTE_KEYS.UPDATE}`,
  },
  MEMBER: {
    REVIEWS: `/${ROUTE_BASE.MEMBER}/${ROUTE_KEYS.REVIEWS}/[${ROUTE_KEYS.MEMBER_ID}]`,
  },
  EVENT: {
    DETAIL: `/${ROUTE_KEYS.EVENT}/[${ROUTE_KEYS.EVENT_ID}]`,
    QNA: `/${ROUTE_KEYS.EVENT}/${ROUTE_KEYS.QNA}`,
  },
  MY: {
    REVIEW_DETAIL: `/${ROUTE_KEYS.HOSPITALS}/${ROUTE_KEYS.REVIEWS}/${ROUTE_KEYS.MY}/[${ROUTE_KEYS.REVIEW_ID}]`,
    // eslint-disable-next-line max-len
    RECEIPT_DETAIL: `/${ROUTE_KEYS.HOSPITALS}/${ROUTE_KEYS.REVIEWS}/${ROUTE_KEYS.MY}/${ROUTE_KEYS.RECEIPTS}/[${ROUTE_KEYS.RECEIPT_ID}]`,
    REVIEWS: `/${ROUTE_KEYS.HOSPITALS}/${ROUTE_KEYS.REVIEWS}/${ROUTE_KEYS.MY}`,
  },
  SCRAP: {
    LIST: `/${ROUTE_BASE.MYPAGE}/${ROUTE_BASE.SCRAP}`,
  },
  PLAY: {
    INTRO: `/${ROUTE_KEYS.PLAY}/${ROUTE_KEYS.INTRO}`,
    SEARCH: `/${ROUTE_KEYS.PLAY}/${ROUTE_KEYS.SEARCH}`,
  },
  QNA: `/${ROUTE_KEYS.QNA}`,
  MALL: {
    HOME: `${process.env.NEXT_PUBLIC_FITPET_MALL_DOMAIN}`,
    ADD_INFO: `${process.env.NEXT_PUBLIC_FITPET_MALL_DOMAIN}/accounts/add-info`,
    EVENTS: `${process.env.NEXT_PUBLIC_FITPET_MALL_DOMAIN}/events`,
  },
  ADMIN: {
    CREATE_QUERY: `/${ROUTE_BASE.ADMIN}/${ROUTE_KEYS.CRM_DEVELOPMENT}/${ROUTE_KEYS.CREATE_QUERY}`,
  },
};

export const isPathnameHome = (pathname: string) => {
  const HOME_URLS = [ROUTES.HOME, `${ROUTES.HOME}/`, ROUTES.REVIEWS.LIST, `${ROUTES.REVIEWS.LIST}/`];
  return HOME_URLS.includes(pathname);
};

const SCROLL_INFO_SAVE_URLS = [
  ROUTES.HOSPITALS.DETAIL,
  ROUTES.HOSPITALS.REVIEWS,
  ROUTES.HOSPITALS.REVIEWS_DETAIL,
  ROUTES.MEMBER.REVIEWS,
];
export const isScrollInfoSavePath = (pathname: string) => {
  return SCROLL_INFO_SAVE_URLS.includes(pathname);
};
