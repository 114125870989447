import React from 'react';
import SingleButton from '@/components/common/buttons/SingleButton';
import BodyContainer from '@/components/modal/BaseBodyContainer';
import BaseButtonContainer from '@/components/modal/BaseButtonContainer';
import BaseContainer from '@/components/modal/BaseContainer';
import BaseModal, { PortalTarget } from '@/components/modal/BaseModal';
import TextTitle from '@/components/modal/TextTitle';
import { DIRECTION } from '@/constants/common';
import { useLoginModal } from '@/hooks/useLoginModal';
import useCheckLogin from '@/utils/bridge/useCheckLogin';

const LoginModal = () => {
  const loginModalProps = useLoginModal();
  const { afterLoginFunction, isLogin } = useCheckLogin();

  const handleCancelClick = () => {
    loginModalProps.hideModal();
  };

  const handleConfirmClick = () => {
    if (loginModalProps.data?.onLogin) {
      loginModalProps.hideModal();
      afterLoginFunction(isLogin())(loginModalProps.data.onLogin);
    }
  };
  return (
    <BaseModal
      contentsOutSideTouchClosed={false}
      modalProps={loginModalProps}
      portalTarget={PortalTarget.LOGIN_MODAL_ROOT}>
      <BaseContainer>
        <BodyContainer>
          <TextTitle>{'로그인이 필요해요\n로그인 페이지로 이동하시겠어요?'}</TextTitle>
        </BodyContainer>
        <BaseButtonContainer direction={DIRECTION.VERTICAL}>
          <SingleButton buttonType={'secondary'} onClick={handleCancelClick}>
            취소
          </SingleButton>
          <SingleButton buttonType={'primary'} onClick={handleConfirmClick}>
            이동
          </SingleButton>
        </BaseButtonContainer>
      </BaseContainer>
    </BaseModal>
  );
};

export default LoginModal;
