import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { DehydratedState, Hydrate, QueryCache, QueryClient, QueryClientProvider } from 'react-query';
import { setToken } from '@/api/http';
import GlobalFloating from '@/components/floating/GlobalFloating';
import LoginModal from '@/components/modal/LoginModal';
import useHospitalReviewDetailStore from '@/components/review/store/useHospitalReviewDetailStore';
import GlobalSnackBar from '@/components/snackBar/GlobalSnackBar';
import GlobalToast from '@/components/toast/GlobalToast';
import { isPathnameHome, ROUTES } from '@/constants/routes';
import { CurrentDeviceProvider } from '@/context/CurrentDeviceProvider';
import { FitpetReservationProvider } from '@/context/FitpetReservationProvider';
import useAppRouter from '@/hooks/useAppRouter';
import useInitializer from '@/hooks/useInitializer';
import useNativeBridges from '@/hooks/useNativeBridges';
import useModalHistoryStore from '@/store/useModalHistoryStore';
import GlobalStyle from '@/styles/GlobalStyle';
import useCheckLogin from '@/utils/bridge/useCheckLogin';
import { isInAppFlag } from '@/utils/utilBridge';
import Portal from 'src/components/portal';

const ReactQueryDevtools = dynamic<{ initialIsOpen: boolean }>(() =>
  import('react-query/devtools').then((resource) => resource.ReactQueryDevtools),
);

const EXCLUDED_PATHS = [ROUTES.HOME, ROUTES.ADMIN.CREATE_QUERY];

const AuthorityCheck = () => {
  const router = useRouter();
  const currentUrl = router.pathname;

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const isTest = !!params.get('is_test') || false;
    const isAllowed = EXCLUDED_PATHS.some((path) => currentUrl.includes(path));

    if (isAllowed) {
      return;
    }
    if (!isInAppFlag && process.env.NEXT_PUBLIC_FITPET_ENV === 'production' && !isTest) {
      document.location.href = `${process.env.NEXT_PUBLIC_FITPET_MALL_DOMAIN}/home/find-hospitals`;
    }
  }, [currentUrl]);

  return null;
};

const Initializer = () => {
  const router = useAppRouter();
  const { isLogin } = useCheckLogin();
  const { init, reInit, deInit } = useInitializer();

  useEffect(() => {
    if (isInAppFlag) {
      window.onlogin = () => {
        init();
      };
      window.onlogout = () => {
        deInit();
      };
    }
    if (isLogin()) {
      init();
    } else {
      deInit();
    }
  }, [deInit, init, isLogin]);

  useEffect(() => {
    window.onforeground = () => {
      if (isLogin() && isPathnameHome(router.pathname)) {
        reInit();
      }
    };
  }, [isLogin, reInit, router.pathname]);
  return null;
};

const RouterPathTracking = () => {
  const router = useAppRouter();
  const { routerPathTracking } = useNativeBridges();
  const { initIgnoreReviewIdList } = useHospitalReviewDetailStore();

  useEffect(() => {
    if (router.pathname !== ROUTES.HOSPITALS.REVIEWS_DETAIL) {
      initIgnoreReviewIdList();
    }

    if (isInAppFlag) {
      routerPathTracking();
    }
  }, [routerPathTracking, router.pathname, initIgnoreReviewIdList]);

  return null;
};

const HospitalApp = ({ Component, pageProps }: AppProps<{ dehydratedState: DehydratedState }>) => {
  const queryCache = new QueryCache();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache,
        defaultOptions: { queries: { refetchOnWindowFocus: false, staleTime: 1000 * 60 } },
      }),
  );

  // Set token to cookies for SSR authentication. Change token string to JWT token in the future.
  const [cookies, setCookie] = useCookies(['token']);
  try {
    const token = typeof window !== 'undefined' ? window.getToken?.() : undefined;
    if (token && token?.apiToken?.token && cookies.token !== token?.apiToken?.token) {
      setCookie('token', token.apiToken.token);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
  }

  // TODO: remove test token
  if (!isInAppFlag && process.env.NEXT_PUBLIC_FITPET_ENV !== 'production' && typeof window !== 'undefined') {
    const token =
      process.env.NEXT_PUBLIC_FITPET_ENV === 'stage'
        ? '2KF009MGM9617C8HT22CH85WMVV00A3PJRPEFR29DDB2EA2QMSGWHHFSB0RBB32R'
        : '4H08FCT7VP8HK049MFSHMK9R8Q1EBEY857P0V17TY1WCGBW5BHJSD95H93CB1475';
    setToken(token); // dev, stage 토큰 (res@fitpet.co.kr)
  }

  const popModalHistory = useModalHistoryStore(useCallback((state) => state.pop, []));

  useEffect(() => {
    if (isInAppFlag) {
      window.onbackkey = () => {
        const modalHistory = popModalHistory();

        if (!modalHistory) {
          // modalHistory가 없는 경우, false를 리턴하여 기본 backKey의 동작을 실행 함.
          return false;
        }
        // modalHistory가 있는 경우, true를 리턴하여 기본 backKey의 동작을 실행 안 함.
        modalHistory.hideModal();
        return true;
      };
      window.onbackkeyDefault = window.onbackkey;
    }
    return () => {
      window.onbackkey = null;
    };
  }, [popModalHistory]);

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps && pageProps.dehydratedState}>
        <GlobalStyle />
        <CurrentDeviceProvider>
          <FitpetReservationProvider>
            <AuthorityCheck />
            <Initializer />
            <RouterPathTracking />
            <Head>
              <title>핏펫 병원 서비스</title>
              <meta charSet="utf-8" />
              <meta
                name="viewport"
                content="initial-scale=1.0, width=device-width, maximum-scale=1, viewport-fit=cover"
              />
              <meta name="referrer" content="no-referrer-when-downgrade" />
              <meta property="og:type" content="website" key="type" />
              <meta property="og:title" content="핏펫 병원 서비스" key="title" />
              {/* TODO: change ImageUrl */}
              {/* <meta property="og:images" content={ImageUrl} key="images" /> */}
              <meta property="og:description" content="수의사와 함께 하는 1등 반려동물 플랫폼" key="description" />
              {/* TODO: change FRONT_BASE_URL */}
              {/* <meta property="og:url" content={FRONT_BASE_URL} key="url" /> */}
              <meta property="og:site_name" content="핏펫 병원 서비스" key="site_name" />
              <meta name="naver-site-verification" content="632277718c42e5b4ad974eac945f769b00d6b280" />
              <script type="text/javascript" src={'/js/jsToNative.js?version=1.9'} />
            </Head>
            <Component {...pageProps} />
            <ReactQueryDevtools initialIsOpen={false} />
            <Portal />
            <GlobalToast />
            <GlobalSnackBar />
            <GlobalFloating />
            <LoginModal />
          </FitpetReservationProvider>
        </CurrentDeviceProvider>
      </Hydrate>
    </QueryClientProvider>
  );
};

export default HospitalApp;
