import { css } from '@emotion/react';
import { GLOBAL_COLOR } from '@/styles/colors';

const FONT_LETTER_SPACING = '-0.02em';

export enum FONT_WEIGHT {
  BOLD = 700,
  SEMI_BOLD = 600,
  MEDIUM = 500,
  REGULAR = 400,
}

export enum FONT_SIZE {
  // heading
  H1 = 30,
  H2 = 26,
  H3 = 24,
  H4 = 22,
  H5 = 20,
  H6 = 18,

  // body
  BODY_1 = 16,
  BODY_2 = 15,
  BODY_3 = 14,
  BODY_4 = 13,
  BODY_5 = 12,

  // caption
  CAPTION_1 = 11,
  CAPTION_2 = 10,
}

export enum LINE_HEIGHT {
  // heading
  H1 = 42,
  H2 = 36,
  H3 = 34,
  H4 = 31,
  H5 = 28,
  H6 = 25,

  // body
  BODY_1 = 22,
  BODY_2 = 21,
  BODY_3 = 20,
  BODY_4 = 18,
  BODY_5 = 17,

  // caption
  CAPTION_1 = 15,
  CAPTION_2 = 14,
}

const heading = {
  H1: css`
    margin-top: 0;
    margin-bottom: 0;
    color: ${GLOBAL_COLOR.GRAY_900};
    font-size: ${FONT_SIZE.H1 / 10}rem;
    font-weight: ${FONT_WEIGHT.BOLD};
    letter-spacing: ${FONT_LETTER_SPACING};
    line-height: ${LINE_HEIGHT.H1 / 10}rem;
  `,
  H2: css`
    margin-top: 0;
    margin-bottom: 0;
    color: ${GLOBAL_COLOR.GRAY_900};
    font-size: ${FONT_SIZE.H2 / 10}rem;
    font-weight: ${FONT_WEIGHT.BOLD};
    letter-spacing: ${FONT_LETTER_SPACING};
    line-height: ${LINE_HEIGHT.H2 / 10}rem;
  `,
  H3: css`
    margin-top: 0;
    margin-bottom: 0;
    color: ${GLOBAL_COLOR.GRAY_900};
    font-size: ${FONT_SIZE.H3 / 10}rem;
    font-weight: ${FONT_WEIGHT.BOLD};
    letter-spacing: ${FONT_LETTER_SPACING};
    line-height: ${LINE_HEIGHT.H3 / 10}rem;
  `,
  H4: css`
    margin-top: 0;
    margin-bottom: 0;
    color: ${GLOBAL_COLOR.GRAY_900};
    font-size: ${FONT_SIZE.H4 / 10}rem;
    font-weight: ${FONT_WEIGHT.BOLD};
    letter-spacing: ${FONT_LETTER_SPACING};
    line-height: ${LINE_HEIGHT.H4 / 10}rem;
  `,
  H5: css`
    margin-top: 0;
    margin-bottom: 0;
    color: ${GLOBAL_COLOR.GRAY_900};
    font-size: ${FONT_SIZE.H5 / 10}rem;
    font-weight: ${FONT_WEIGHT.BOLD};
    letter-spacing: ${FONT_LETTER_SPACING};
    line-height: ${LINE_HEIGHT.H5 / 10}rem;
  `,
  H6: css`
    margin-top: 0;
    margin-bottom: 0;
    color: ${GLOBAL_COLOR.GRAY_900};
    font-size: ${FONT_SIZE.H6 / 10}rem;
    font-weight: ${FONT_WEIGHT.BOLD};
    letter-spacing: ${FONT_LETTER_SPACING};
    line-height: ${LINE_HEIGHT.H6 / 10}rem;
  `,
};

const body = {
  BODY_1: css`
    margin-top: 0;
    margin-bottom: 0;
    color: ${GLOBAL_COLOR.GRAY_900};
    font-size: ${FONT_SIZE.BODY_1 / 10}rem;
    font-weight: ${FONT_WEIGHT.REGULAR};
    letter-spacing: ${FONT_LETTER_SPACING};
    line-height: ${LINE_HEIGHT.BODY_1 / 10}rem;
  `,
  BODY_2: css`
    margin-top: 0;
    margin-bottom: 0;
    color: ${GLOBAL_COLOR.GRAY_900};
    font-size: ${FONT_SIZE.BODY_2 / 10}rem;
    font-weight: ${FONT_WEIGHT.REGULAR};
    letter-spacing: ${FONT_LETTER_SPACING};
    line-height: ${LINE_HEIGHT.BODY_2 / 10}rem;
  `,
  BODY_3: css`
    margin-top: 0;
    margin-bottom: 0;
    color: ${GLOBAL_COLOR.GRAY_900};
    font-size: ${FONT_SIZE.BODY_3 / 10}rem;
    font-weight: ${FONT_WEIGHT.REGULAR};
    letter-spacing: ${FONT_LETTER_SPACING};
    line-height: ${LINE_HEIGHT.BODY_3 / 10}rem;
  `,
  BODY_4: css`
    margin-top: 0;
    margin-bottom: 0;
    color: ${GLOBAL_COLOR.GRAY_900};
    font-size: ${FONT_SIZE.BODY_4 / 10}rem;
    font-weight: ${FONT_WEIGHT.REGULAR};
    letter-spacing: ${FONT_LETTER_SPACING};
    line-height: ${LINE_HEIGHT.BODY_4 / 10}rem;
  `,
  BODY_5: css`
    margin-top: 0;
    margin-bottom: 0;
    color: ${GLOBAL_COLOR.GRAY_900};
    font-size: ${FONT_SIZE.BODY_5 / 10}rem;
    font-weight: ${FONT_WEIGHT.REGULAR};
    letter-spacing: ${FONT_LETTER_SPACING};
    line-height: ${LINE_HEIGHT.BODY_5 / 10}rem;
  `,
};

const caption = {
  CAPTION_1: css`
    color: ${GLOBAL_COLOR.GRAY_900};
    font-size: ${FONT_SIZE.CAPTION_1 / 10}rem;
    font-weight: ${FONT_WEIGHT.REGULAR};
    letter-spacing: ${FONT_LETTER_SPACING};
    line-height: ${LINE_HEIGHT.CAPTION_1 / 10}rem;
  `,
  CAPTION_2: css`
    color: ${GLOBAL_COLOR.GRAY_900};
    font-size: ${FONT_SIZE.CAPTION_2 / 10}rem;
    font-weight: ${FONT_WEIGHT.REGULAR};
    letter-spacing: ${FONT_LETTER_SPACING};
    line-height: ${LINE_HEIGHT.CAPTION_2 / 10}rem;
  `,
};

export const FONT_STYLE = {
  ...heading,
  ...body,
  ...caption,
};
