import IcCertifiedGray from '@/icons/ic_certified_gray.svg';
import IcCertifiedMint from '@/icons/ic_certified_mint.svg';
import IcCheckbox from '@/icons/ic_checkbox.svg';
import IcCheckboxFill from '@/icons/ic_checkbox_fill.svg';
import IcCheckboxFillRound from '@/icons/ic_checkbox_fill_round.svg';
import IcCheckboxRound from '@/icons/ic_checkbox_round.svg';
import IcClockFlat from '@/icons/ic_clock_flat.svg';
import IcEmptyReservation from '@/icons/ic_empty_reservation.svg';
import IcEmptyScrap from '@/icons/ic_empty_scrap.svg';
import IcEmptySearch from '@/icons/ic_empty_search.svg';
import IcError from '@/icons/ic_error.svg';
import IcGoogleMap from '@/icons/ic_google_map.svg';
import IcHappyGray from '@/icons/ic_happy_gray.svg';
import IcHappyYellow from '@/icons/ic_happy_yellow.svg';
import IcInfoOpacity from '@/icons/ic_info_opacity.svg';
import IcKakaoMap from '@/icons/ic_kakao_map.svg';
import IcMedicalNoteEmpty from '@/icons/ic_medical_note_empty.svg';
import IcNaverMap from '@/icons/ic_naver_map.svg';
import IcNewFlat from '@/icons/ic_new_flat.svg';
import IcRadio from '@/icons/ic_radio.svg';
import IcRadioActive from '@/icons/ic_radio_active.svg';
import IcReviewEmpty from '@/icons/ic_review_empty.svg';
import IcScrapActive from '@/icons/ic_scrab_active.svg';
import IcScrap from '@/icons/ic_scrap.svg';
import IcScrapFlat from '@/icons/ic_scrap_flat.svg';
import IcThumbsUpRound from '@/icons/ic_thumbs_up_round.svg';
import IcThumbsUpRoundFill from '@/icons/ic_thumbs_up_round_fill.svg';
import IcTMap from '@/icons/ic_tmap.svg';
import IcUnhappy from '@/icons/ic_unhappy.svg';
import IcUnhappyGray from '@/icons/ic_unhappy_gray.svg';
import IcUnhappyYellow from '@/icons/ic_unhappy_yellow.svg';

/* eslint sort-keys: ["error", "asc", {"caseSensitive": true, "natural": false, "minKeys": 2}] */
const ImageIconList = {
  icCertifiedGray: IcCertifiedGray,
  icCertifiedMint: IcCertifiedMint,
  icCheckbox: IcCheckbox,
  icCheckboxFill: IcCheckboxFill,
  icCheckboxFillRound: IcCheckboxFillRound,
  icCheckboxRound: IcCheckboxRound,
  icClockFlat: IcClockFlat,
  icEmptyReservation: IcEmptyReservation,
  icEmptyScrap: IcEmptyScrap,
  icEmptySearch: IcEmptySearch,
  icError: IcError,
  icGoogleMap: IcGoogleMap,
  icHappyGray: IcHappyGray,
  icHappyYellow: IcHappyYellow,
  icInfoOpacity: IcInfoOpacity,
  icKakaoMap: IcKakaoMap,
  icMedicalNoteEmpty: IcMedicalNoteEmpty,
  icNaverMap: IcNaverMap,
  icNewFlat: IcNewFlat,
  icRadio: IcRadio,
  icRadioActive: IcRadioActive,
  icReviewEmpty: IcReviewEmpty,
  icScrap: IcScrap,
  icScrapActive: IcScrapActive,
  icScrapFlat: IcScrapFlat,
  icTMap: IcTMap,
  icThumbsUpRound: IcThumbsUpRound,
  icThumbsUpRoundFill: IcThumbsUpRoundFill,
  icUnhappy: IcUnhappy,
  icUnhappyGray: IcUnhappyGray,
  icUnhappyYellow: IcUnhappyYellow,
};

export default ImageIconList;
