import { useCallback } from 'react';
import { REVIEW_TYPE } from '@/constants/common';
import useEventTrackingStore from '@/store/useEventTrackingStore';
import { getPlatform } from '@/utils/deviceUtils';
import {
  ALARM_TYPE,
  LOCATION_TYPE,
  PetType,
  PetTypeForTracker,
  PetTypeSearch,
  RESERVATION_TYPE,
  SearchLocationSymptom,
  Service,
  SortBy,
} from '@/utils/tracker/constants';
import { HospitalEvent, HospitalEventProperties } from '@/utils/tracker/event/hospital';

interface TrackingClickHospitalHomeBCategorySymptomParams {
  symptomName: string;
}

interface TrackingClickHospitalHomeBCategoryDetailSymptomParams {
  symptomName: string;
  symptom2DepthName: string;
}

interface TrackingClickHospitalHomeBCategoryDetailTotalReviewParams {
  symptomName: string;
}

interface TrackingClickHospitalReviewRecentReviewReviewParams {
  locationName: string; // 병원 이름
  isAlliance: boolean; // 제휴 병원 여부
  hospitalReviewId: number;
  hospitalReviewType: REVIEW_TYPE; // toLower
  locationId: number; // 병원 ID
  hospitalReviewPetType: PetType; // 리뷰의 작성자의 반려동물 타입
}

interface TrackingClickHospitalCommonTopMenuTabParams {
  hospitalTopMenuName: string;
}

interface TrackingViewHospitalHomeParams {
  homeType: string; // 병원탭이 메인인 경우: hospitalreservation
  locationDetail: string; // 조회중인 지역
  locationAgree: boolean; // 사용자 위치정보 동의여부
}

interface TrackingViewHospitalHomeSearchResultParams {
  searchKeyword: string;
  searchLocationSymptom: SearchLocationSymptom;
  isHashTag: boolean;
  petTypeSearch: PetTypeSearch;
}

interface TrackingClickHospitalSymptomCategoryReview {
  locationName: string; // 병원 이름
  isAlliance: boolean; // 제휴 병원 여부
  hospitalReviewId: number;
  hospitalReviewType: REVIEW_TYPE; // toLower
  locationId: number; // 병원 ID
  hospitalReviewPetType: PetType; // 리뷰 작성자의 반려동물 타입
}

interface TrackingClickHospitalReviewRecentReviewHospitalParams {
  locationId: number; // 병원 ID
  locationName: string; // 병원 이름
  isAlliance: boolean; // 제휴 병원 여부
  isReservationAvailable: boolean; // 예약 가능 여부
}

interface TrackingClickHospitalReviewListHospitalParams {
  locationId: number; // 병원 ID
  locationName: string; // 병원 이름
  isAlliance: boolean; // 제휴 병원 여부
  isReservationAvailable: boolean; // 예약 가능 여부
}

interface TrackingClickHospitalReviewScrapParams {
  locationId: number; // 병원 ID
  locationName: string; // 병원 이름
  isAlliance: boolean; // 제휴 병원 여부
}

interface TrackingClickHospitalReviewUnScrapParams {
  locationId: number; // 병원 ID
  locationName: string; // 병원 이름
  isAlliance: boolean; // 제휴 병원 여부
}

interface TrackingViewHospitalSymptomSearchResultParams {
  isDirectResult: boolean;
  isHashtag: boolean;
  searchKeyword: string;
  searchLocationSymptom:
    | SearchLocationSymptom.SUBMIT
    | SearchLocationSymptom.RECENT_KEYWORD
    | SearchLocationSymptom.RECENT_SYMPTOM
    | SearchLocationSymptom.RECOMMEND_HASHTAG
    | SearchLocationSymptom.RELATED_HASHTAG
    | SearchLocationSymptom.REVIEW_HASHTAG
    | SearchLocationSymptom.MAIN_HASHTAG;
  petTypeFilter: PetTypeForTracker.ALL | PetTypeForTracker.DOG | PetTypeForTracker.CAT;
  sortBy: SortBy.RECOMMEND | SortBy.RECENT | SortBy.USEFUL;
  photoOnly: boolean;
}

interface TrackingViewHospitalReviewFloatingAlarmParams {
  buttonText: string;
  alarmType: ALARM_TYPE;
  petType: PetTypeForTracker | PetType | 'C' | 'D';
}

interface TrackingClickHospitalReviewFloatingAlarmParams {
  buttonText: string;
  alarmType: ALARM_TYPE;
  petType: PetTypeForTracker | PetType | 'C' | 'D';
  reservationTime: number; // 시간대만
  locationId: number;
  locationName: string;
  isReduced: boolean; // 스크롤에 따라 플로팅 크기 축소 여부
}

interface TrackingViewHospitalReviewReservationPopupParams {
  petType: PetTypeForTracker | PetType | 'C' | 'D';
  locationId: number;
  locationName: string;
  popupType?: RESERVATION_TYPE;
  popupText: string;
}

interface TrackingClickHospitalReviewReservationPopupParams {
  petType: PetTypeForTracker | PetType | 'C' | 'D';
  locationId: number;
  locationName: string;
  popupType?: RESERVATION_TYPE;
  popupText: string;
  buttonText: string;
}

const useEventTrackerHome = () => {
  const { tracker } = useEventTrackingStore();

  const trackingClickHospitalSymptomCategorySymptom = useCallback(
    ({ symptomName }: TrackingClickHospitalHomeBCategorySymptomParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALSYMPTOM_CATEGORYSYMPTOM, {
        [HospitalEventProperties.SYMPTOM_NAME]: symptomName,
      });
    },
    [tracker],
  );

  const trackingClickHospitalSymptomCategoryDetailSymptom = useCallback(
    ({ symptomName, symptom2DepthName }: TrackingClickHospitalHomeBCategoryDetailSymptomParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALSYMPTOM_CATEGORYDETAIL_SYMPTOM, {
        [HospitalEventProperties.SYMPTOM_NAME]: symptomName,
        [HospitalEventProperties.SYMPTOM_2DEPTH_NAME]: symptom2DepthName,
      });
    },
    [tracker],
  );

  const trackingClickHospitalSymptomCategoryDetailTotalReview = useCallback(
    ({ symptomName }: TrackingClickHospitalHomeBCategoryDetailTotalReviewParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALSYMPTOM_CATEGORYDETAIL_TOTALREVIEW, {
        [HospitalEventProperties.SYMPTOM_NAME]: symptomName,
      });
    },
    [tracker],
  );

  const trackingClickHospitalSymptomCategoryReview = useCallback(
    ({
      locationName,
      isAlliance,
      hospitalReviewId,
      hospitalReviewType,
      locationId,
      hospitalReviewPetType,
    }: TrackingClickHospitalSymptomCategoryReview) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALSYMPTOM_CATEGORYREVIEW, {
        [HospitalEventProperties.LOCATION_NAME]: locationName,
        [HospitalEventProperties.IS_ALLIANCE]: isAlliance,
        [HospitalEventProperties.HOSPITAL_REVIEW_ID]: hospitalReviewId,
        [HospitalEventProperties.HOSPITAL_REVIEW_TYPE]: hospitalReviewType.toLowerCase(),
        [HospitalEventProperties.LOCATION_TYPE]: LOCATION_TYPE.HOSPITAL,
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.HOSPITAL_REVIEW_PET_TYPE]: hospitalReviewPetType,
      });
    },
    [tracker],
  );

  const trackingClickHospitalHomeOnboardingButton = useCallback(() => {
    tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALHOME_ONBOARDING_BUTTON, {});
  }, [tracker]);

  const trackingViewHospitalHome = useCallback(
    ({ homeType, locationDetail, locationAgree }: TrackingViewHospitalHomeParams) => {
      tracker.sendHospitalEvent(HospitalEvent.VIEW_HOSPITALHOME, {
        [HospitalEventProperties.HOME_TYPE]: homeType,
        [HospitalEventProperties.LOCATION_DETAIL]: locationDetail,
        [HospitalEventProperties.LOCATION_AGREE]: locationAgree,
      });
    },
    [tracker],
  );

  const trackingViewHospitalHomeSearchResult = useCallback(
    ({
      searchKeyword,
      searchLocationSymptom,
      isHashTag,
      petTypeSearch,
    }: TrackingViewHospitalHomeSearchResultParams) => {
      tracker.sendHospitalEvent(HospitalEvent.VIEW_HOSPITALHOME_SEARCHRESULT, {
        [HospitalEventProperties.SEARCH_KEYWORD]: searchKeyword,
        [HospitalEventProperties.SEARCH_LOCATION_SYMPTOM]: searchLocationSymptom,
        [HospitalEventProperties.IS_HASHTAG]: isHashTag,
        [HospitalEventProperties.PET_TYPE_SEARCH]: petTypeSearch,
      });
    },
    [tracker],
  );

  const trackingClickHospitalHomeCategoryButton = useCallback(() => {
    tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALHOME_CATEGORY_BUTTON, {});
  }, [tracker]);

  const trackingViewHospitalSymptomCategory = useCallback(() => {
    tracker.sendHospitalEvent(HospitalEvent.VIEW_HOSPITALSYMPTOM_CATEGORY, {});
  }, [tracker]);

  const trackingClickHospitalTabSearch = useCallback(() => {
    tracker.sendHospitalEvent(HospitalEvent.CLICK__HOSPITALRESERVATION_TAB_SEARCH, {});
  }, [tracker]);

  const trackingViewHospitalSymptomSearchResult = useCallback(
    ({
      isDirectResult,
      isHashtag,
      searchKeyword,
      searchLocationSymptom,
      petTypeFilter,
      sortBy,
      photoOnly,
    }: TrackingViewHospitalSymptomSearchResultParams) => {
      tracker.sendHospitalEvent(HospitalEvent.VIEW_HOSPITALSYMPTOM_SEARCHRESULT, {
        [HospitalEventProperties.IS_DIRECT_RESULT]: isDirectResult,
        [HospitalEventProperties.IS_HASHTAG]: isHashtag,
        [HospitalEventProperties.SEARCH_KEYWORD]: searchKeyword,
        [HospitalEventProperties.SEARCH_LOCATION_SYMPTOM]: searchLocationSymptom,
        [HospitalEventProperties.PET_TYPE_FILTER]: petTypeFilter,
        [HospitalEventProperties.SORT_BY]: sortBy,
        [HospitalEventProperties.PHOTO_ONLY]: photoOnly,
      });
    },
    [tracker],
  );

  const trackingClickHospitalReviewRecentReviewReview = useCallback(
    ({
      locationName,
      isAlliance,
      hospitalReviewId,
      hospitalReviewType,
      locationId,
      hospitalReviewPetType,
    }: TrackingClickHospitalReviewRecentReviewReviewParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALREVIEW_RECENTREVIEW_REVIEW, {
        [HospitalEventProperties.LOCATION_NAME]: locationName,
        [HospitalEventProperties.IS_ALLIANCE]: isAlliance,
        [HospitalEventProperties.HOSPITAL_REVIEW_ID]: hospitalReviewId,
        [HospitalEventProperties.HOSPITAL_REVIEW_TYPE]: hospitalReviewType.toLowerCase(),
        [HospitalEventProperties.LOCATION_TYPE]: LOCATION_TYPE.HOSPITAL,
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.HOSPITAL_REVIEW_PET_TYPE]: hospitalReviewPetType,
      });
    },
    [tracker],
  );

  const trackingClickHospitalReviewRecentReviewHospital = useCallback(
    ({
      locationId,
      locationName,
      isAlliance,
      isReservationAvailable,
    }: TrackingClickHospitalReviewRecentReviewHospitalParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALREVIEW_RECENTREVIEW_HOSPITAL, {
        [HospitalEventProperties.LOCATION_TYPE]: LOCATION_TYPE.HOSPITAL,
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.LOCATION_NAME]: locationName,
        [HospitalEventProperties.IS_ALLIANCE]: isAlliance,
        [HospitalEventProperties.IS_RESERVATION_AVAILABLE]: isReservationAvailable,
      });
    },
    [tracker],
  );

  const trackingClickHospitalReviewListHospital = useCallback(
    ({
      locationId,
      locationName,
      isAlliance,
      isReservationAvailable,
    }: TrackingClickHospitalReviewListHospitalParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALREVIEW_LIST_HOSPITAL, {
        [HospitalEventProperties.LOCATION_TYPE]: LOCATION_TYPE.HOSPITAL,
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.LOCATION_NAME]: locationName,
        [HospitalEventProperties.IS_ALLIANCE]: isAlliance,
        [HospitalEventProperties.IS_RESERVATION_AVAILABLE]: isReservationAvailable,
      });
    },
    [tracker],
  );

  const trackingClickHospitalReviewScrap = useCallback(
    ({ locationId, locationName, isAlliance }: TrackingClickHospitalReviewScrapParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALREVIEW_SCRAP, {
        [HospitalEventProperties.LOCATION_TYPE]: LOCATION_TYPE.HOSPITAL,
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.LOCATION_NAME]: locationName,
        [HospitalEventProperties.IS_ALLIANCE]: isAlliance,
      });
    },
    [tracker],
  );

  const trackingClickHospitalReviewUnScrap = useCallback(
    ({ locationId, locationName, isAlliance }: TrackingClickHospitalReviewUnScrapParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALREVIEW_UNSCRAP, {
        [HospitalEventProperties.LOCATION_TYPE]: LOCATION_TYPE.HOSPITAL,
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.LOCATION_NAME]: locationName,
        [HospitalEventProperties.IS_ALLIANCE]: isAlliance,
      });
    },
    [tracker],
  );

  const trackingClickHospitalReviewHospitalTotalButton = useCallback(() => {
    tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALREVIEW_HOSPITAL_TOTAL_BUTTON, {});
  }, [tracker]);

  const trackingViewHospitalReviewFloatingAlarm = useCallback(
    ({ buttonText, alarmType, petType }: TrackingViewHospitalReviewFloatingAlarmParams) => {
      tracker.sendHospitalEvent(HospitalEvent.VIEW_HOSPITALREVIEW_FLOATINGALARM, {
        [HospitalEventProperties.SERVICE]: Service.HOSPITAL,
        [HospitalEventProperties.PLATFORM_DETAIL]: getPlatform(),
        [HospitalEventProperties.BUTTON_TEXT]: buttonText,
        [HospitalEventProperties.ALARM_TYPE]: alarmType,
        [HospitalEventProperties.PET_TYPE]: petType,
      });
    },
    [tracker],
  );

  const trackingClickHospitalReviewFloatingAlarm = useCallback(
    ({
      buttonText,
      alarmType,
      reservationTime,
      locationId,
      locationName,
      petType,
      isReduced,
    }: TrackingClickHospitalReviewFloatingAlarmParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALREVIEW_FLOATINGALARM, {
        [HospitalEventProperties.SERVICE]: Service.HOSPITAL,
        [HospitalEventProperties.PLATFORM_DETAIL]: getPlatform(),
        [HospitalEventProperties.BUTTON_TEXT]: buttonText,
        [HospitalEventProperties.ALARM_TYPE]: alarmType,
        [HospitalEventProperties.RESERVATION_TIME]: reservationTime,
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.LOCATION_NAME]: locationName,
        [HospitalEventProperties.PET_TYPE]: petType,
        [HospitalEventProperties.IS_REDUCED]: isReduced,
      });
    },
    [tracker],
  );

  const trackingViewHospitalReviewReservationPopup = useCallback(
    ({ locationId, locationName, petType, popupType, popupText }: TrackingViewHospitalReviewReservationPopupParams) => {
      tracker.sendHospitalEvent(HospitalEvent.VIEW_HOSPITALREVIEW_RESERVATIONPOPUP, {
        [HospitalEventProperties.SERVICE]: Service.HOSPITAL,
        [HospitalEventProperties.PLATFORM_DETAIL]: getPlatform(),
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.LOCATION_NAME]: locationName,
        [HospitalEventProperties.PET_TYPE]: petType,
        [HospitalEventProperties.POPUP_TYPE]: popupType,
        [HospitalEventProperties.POPUP_TEXT]: popupText,
      });
    },
    [tracker],
  );

  const trackingClickHospitalReviewReservationPopup = useCallback(
    ({
      locationId,
      locationName,
      petType,
      popupType,
      popupText,
      buttonText,
    }: TrackingClickHospitalReviewReservationPopupParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALREVIEW_RESERVATIONPOPUP, {
        [HospitalEventProperties.SERVICE]: Service.HOSPITAL,
        [HospitalEventProperties.PLATFORM_DETAIL]: getPlatform(),
        [HospitalEventProperties.LOCATION_ID]: locationId,
        [HospitalEventProperties.LOCATION_NAME]: locationName,
        [HospitalEventProperties.PET_TYPE]: petType,
        [HospitalEventProperties.POPUP_TYPE]: popupType,
        [HospitalEventProperties.POPUP_TEXT]: popupText,
        [HospitalEventProperties.BUTTON_TEXT]: buttonText,
      });
    },
    [tracker],
  );

  const trackingClickHospitalCommonTopMenuTab = useCallback(
    ({ hospitalTopMenuName }: TrackingClickHospitalCommonTopMenuTabParams) => {
      tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALCOMMON_TOPMENU_TAB, {
        [HospitalEventProperties.HOSPITAL_TOPMENU_NAME]: hospitalTopMenuName,
      });
    },
    [tracker],
  );

  const trackingClickHospitalCommonReservationIcon = useCallback(() => {
    tracker.sendHospitalEvent(HospitalEvent.CLICK_HOSPITALCOMMON_RESERVATION_ICON, {});
  }, [tracker]);

  return {
    trackingClickHospitalSymptomCategorySymptom,
    trackingClickHospitalSymptomCategoryDetailSymptom,
    trackingClickHospitalSymptomCategoryDetailTotalReview,
    trackingClickHospitalSymptomCategoryReview,
    trackingClickHospitalHomeOnboardingButton,
    trackingViewHospitalHome,
    trackingViewHospitalHomeSearchResult,
    trackingClickHospitalHomeCategoryButton,
    trackingViewHospitalSymptomCategory,
    trackingClickHospitalTabSearch,
    trackingViewHospitalSymptomSearchResult,
    trackingClickHospitalReviewRecentReviewReview,
    trackingClickHospitalReviewRecentReviewHospital,
    trackingClickHospitalReviewListHospital,
    trackingClickHospitalReviewScrap,
    trackingClickHospitalReviewUnScrap,
    trackingClickHospitalReviewHospitalTotalButton,
    trackingViewHospitalReviewFloatingAlarm,
    trackingClickHospitalReviewFloatingAlarm,
    trackingViewHospitalReviewReservationPopup,
    trackingClickHospitalReviewReservationPopup,
    trackingClickHospitalCommonTopMenuTab,
    trackingClickHospitalCommonReservationIcon,
  };
};

export default useEventTrackerHome;
