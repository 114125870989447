import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import useSnackBarStore from './useSnackBarStore';
import FlatIcon from '@/components/common/FlatIcon';
import ImageIcon from '@/components/common/ImageIcon';
import FlatIconList from '@/constants/flatIconList';
import ImageIconList from '@/constants/imageIconList';
import { TOAST_ANIMATION_TIME, TOAST_MARGIN, TOAST_SIDE_PADDING } from '@/constants/toast';
import { GLOBAL_COLOR } from '@/styles/colors';
import { GLOBAL_LAYOUT_PX } from '@/styles/layout';
import { Z_INDEX } from '@/styles/zIndex';
import { toRem } from '@/utils/commonUtils';

const GlobalSnackBar = () => {
  const { snackBarList, clearAllSnackBarList } = useSnackBarStore((state) => state);

  useEffect(() => {
    const toastListTimeout = setTimeout(() => {
      clearAllSnackBarList();
    }, TOAST_ANIMATION_TIME.TIME_OUT * 1000);

    return () => clearTimeout(toastListTimeout);
  }, [clearAllSnackBarList, snackBarList]);

  useEffect(() => {
    clearAllSnackBarList();
  }, [clearAllSnackBarList]);

  return (
    <Container height={snackBarList[0]?.margin || TOAST_MARGIN.SMALL}>
      <Wrapper>
        {snackBarList?.map((snackBar, index) => {
          const isOverlayed = index > 0 && snackBar.text === snackBarList[index - 1].text;

          return (
            <ContentContainer key={index} isOverlayed={isOverlayed}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {snackBar.leftIcon && (
                  <ImageIconWrapper>
                    <ImageIcon
                      iconType={snackBar.leftIcon as keyof typeof ImageIconList}
                      size={snackBar.leftIconSize || 16}
                    />
                  </ImageIconWrapper>
                )}
                <Content>{snackBar.text}</Content>
                {snackBar.secondCol && <Content>{snackBar.secondCol}</Content>}
              </div>
              <ButtonContainer
                onClick={() => {
                  snackBar.onClick();
                  clearAllSnackBarList();
                }}>
                {snackBar.onClickText}
                <FlatIcon
                  iconType={snackBar.rightIcon as keyof typeof FlatIconList}
                  size={snackBar.rightIconSize || 16}
                  color={GLOBAL_COLOR.BLUE_200}
                />
              </ButtonContainer>
            </ContentContainer>
          );
        })}
      </Wrapper>
    </Container>
  );
};

export default GlobalSnackBar;

const Container = styled.section<{ height: number }>`
  position: fixed;
  left: 50%;
  transform: translate(-50%);
  width: calc(100% - ${TOAST_SIDE_PADDING}px);
  max-width: calc(${GLOBAL_LAYOUT_PX.SCREEN_OFFSET}px - ${TOAST_SIDE_PADDING}px);
  bottom: ${({ height }) => `calc(env(safe-area-inset-bottom) + ${toRem(height)})`};
  z-index: ${Z_INDEX.TOAST + 100};
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const ContentContainer = styled.div<{ isOverlayed: boolean }>`
  padding: ${toRem(14)} ${toRem(16)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  opacity: 0;
  min-height: ${toRem(48)};
  border-radius: ${toRem(8)};
  background-color: rgba(27, 30, 33, 0.9);
  color: white;
  font-size: ${toRem(15)};
  font-weight: 400;
  line-height: ${toRem(21)};
  margin-top: ${toRem(8)};
  animation: ${TOAST_ANIMATION_TIME.FADE_IN_DURATION}s cubic-bezier(0.65, 0, 0.35, 1),
    ${TOAST_ANIMATION_TIME.FADE_IN_DELAY}s forwards fadeIn,
    ${TOAST_ANIMATION_TIME.FADE_OUT_DURATION}s cubic-bezier(0.65, 0, 0.35, 1) ${TOAST_ANIMATION_TIME.FADE_OUT_DELAY}s
      forwards fadeOut,
    0s step-end ${TOAST_ANIMATION_TIME.TIME_OUT}s forwards hide;

  ${(props) =>
    props.isOverlayed &&
    css`
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    `};

  @keyframes fadeIn {
    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    100% {
      opacity: 0;
    }
  }

  @keyframes hide {
    100% {
      position: absolute;
      bottom: -2000px;
    }
  }
`;

const Content = styled.div`
  text-align: center;
`;

const ImageIconWrapper = styled.div`
  margin-right: ${toRem(8)};
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${toRem(14)};
  font-weight: 400;
  line-height: ${toRem(20)};
  color: ${GLOBAL_COLOR.BLUE_200};
  margin-left: ${toRem(8)};
`;
