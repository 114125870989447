import { useCallback, useEffect, useState } from 'react';
import { LoginModalDataType } from '@/constants/modal';
import { ModalProps, UseModalProps } from '@/hooks/useModal';
import useLoginModalStore from '@/store/useLoginModalStore';
import useModalHistoryStore from '@/store/useModalHistoryStore';

interface LoginModalProps extends ModalProps<LoginModalDataType> {
  showModalWithCallback: (loginModalData: LoginModalDataType) => void;
}

export const useLoginModal = (props?: UseModalProps) => {
  const pushOrUpdateModalHistory = useModalHistoryStore(useCallback((state) => state.pushOrUpdate, []));
  const removeModalHistory = useModalHistoryStore(useCallback((state) => state.remove, []));
  const [modalHistoryKey] = useState<string>('loginModalKey');
  const beforeModalHideAction = useCallback(() => props?.beforeModalHideAction, [props]);
  const setIsShowing = useLoginModalStore(useCallback((state) => state.setIsShowing, []));
  const setData = useLoginModalStore(useCallback((state) => state.setData, []));
  const { isShowing, data } = useLoginModalStore();

  const showModal = () => {
    throw new Error('Call showModalWithCallback() instead showModal()!!');
  };

  const hideModal = useCallback(() => {
    if (isShowing) {
      beforeModalHideAction?.();
      setIsShowing(false);
    }
  }, [beforeModalHideAction, isShowing, setIsShowing]);

  const setHideModalAfterCallbackFn = useCallback(() => {
    throw new Error('LoginModal is not supported for setHideModalAfterCallbackFn!');
  }, []);

  const showModalWithCallback = useCallback(
    (loginModalData: LoginModalDataType) => {
      if (!isShowing) {
        setData(loginModalData);
        setIsShowing(true);
      }
    },
    [isShowing, setData, setIsShowing],
  );

  useEffect(() => {
    if (isShowing) {
      pushOrUpdateModalHistory({ key: modalHistoryKey, hideModal });
    } else {
      removeModalHistory(modalHistoryKey);
    }
  }, [hideModal, isShowing, modalHistoryKey, pushOrUpdateModalHistory, removeModalHistory]);

  useEffect(() => {
    return () => {
      hideModal();
    };
    // Do not add dependency. Because clean up function is must be called when component unmount.
  }, [hideModal]);

  const modalProps: LoginModalProps = {
    isShowing,
    data,
    setData,
    showModal,
    showModalWithCallback,
    hideModal,
    setHideModalAfterCallbackFn,
  };

  return modalProps;
};
