import create from 'zustand';

interface UseReviewScrapIdListStoreStore {
  reviewScrapedIdList: Set<number>;
  setReviewScrapedIdList: (idList: number[]) => void;
  isContainsReviewScrapedId: (reviewId: number) => boolean;
  addReviewScraped: (reviewId: number) => void;
  removeReviewScraped: (reviewId: number) => boolean;
  clearReviewScrapedList: () => void;
}

const useReviewScrapIdListStore = create<UseReviewScrapIdListStoreStore>((set, get) => ({
  reviewScrapedIdList: new Set(),
  setReviewScrapedIdList: (idList: number[]) =>
    set(() => {
      const reviewScrapIdList = new Set<number>();
      idList.map((id: number) => reviewScrapIdList.add(id));
      return { reviewScrapedIdList: reviewScrapIdList };
    }),
  isContainsReviewScrapedId: (reviewId: number) => get().reviewScrapedIdList.has(reviewId),
  addReviewScraped: (reviewId: number) => {
    const temp = new Set(get().reviewScrapedIdList);
    temp.add(reviewId);
    set(() => ({ reviewScrapedIdList: temp }));
  },
  removeReviewScraped: (reviewId: number) => {
    try {
      const temp = new Set(get().reviewScrapedIdList);
      temp.delete(reviewId);
      set(() => ({ reviewScrapedIdList: temp }));
      return true;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      return false;
    }
  },
  clearReviewScrapedList: () => set(() => ({ reviewScrapedIdList: new Set() })),
}));

export default useReviewScrapIdListStore;
