import create from 'zustand';

interface ModalHistoryItem {
  key: string;
  hideModal: () => void;
}

interface ModalHistoryStore {
  modalHistoryItems: ModalHistoryItem[];
  pushOrUpdate: (item: ModalHistoryItem) => void;
  pop: () => ModalHistoryItem | undefined;
  remove: (modalHistoryItemKey: string) => ModalHistoryItem | undefined;
}

const getModalHistoryItem = (modalHistoryItems: ModalHistoryItem[], key: string): ModalHistoryItem | undefined => {
  return modalHistoryItems.find((item) => item.key === key);
};

const useModalHistoryStore = create<ModalHistoryStore>((set, get) => ({
  modalHistoryItems: [],
  pushOrUpdate: (modalHistoryItem: ModalHistoryItem) => {
    const modalHistoryItemFound = getModalHistoryItem(get().modalHistoryItems, modalHistoryItem.key);

    if (modalHistoryItemFound) {
      set((state) => ({
        ...state,
        modalHistoryItems: [
          ...get().modalHistoryItems.map((item) => {
            if (item.key === modalHistoryItem.key) {
              return modalHistoryItem;
            }
            return item;
          }),
        ],
      }));
      return;
    }
    set((state) => ({ ...state, modalHistoryItems: [...get().modalHistoryItems, modalHistoryItem] }));
  },
  pop: () => {
    const modalHistoryItemsCopied = [...get().modalHistoryItems];
    const modalHistoryItem = modalHistoryItemsCopied.pop();
    set((state) => ({ ...state, modalHistoryItems: [...modalHistoryItemsCopied] }));

    return modalHistoryItem;
  },
  remove: (modalHistoryItemKey: string) => {
    const modalHistoryItemRemoved = get().modalHistoryItems.find(
      (modalHistoryItem) => modalHistoryItem.key === modalHistoryItemKey,
    );
    if (!modalHistoryItemRemoved) {
      return undefined;
    }
    const modalHistoryItemsCopied = [
      ...get().modalHistoryItems.filter((modalHistoryItem) => modalHistoryItem.key !== modalHistoryItemKey),
    ];
    set((state) => ({ ...state, modalHistoryItems: [...modalHistoryItemsCopied] }));
    return modalHistoryItemRemoved;
  },
}));

export default useModalHistoryStore;
