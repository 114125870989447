import type AmplitudeJs from 'amplitude-js';
import { InAppBridge } from '@/utils/bridge';
import { EventHandler, EventLogger } from '@/utils/tracker/handlers/base';
import { isInApp, PlatformDetail } from '@/utils/deviceUtils';
import { HospitalEvent } from '@/utils/tracker/event/hospital';
import { EventType } from '@/utils/tracker/model';

declare global {
  interface Window {
    location: Location;
  }
}

// eslint-disable-next-line global-require
// const Amplitude = process.browser && require('amplitude-js');

export class AmplitudeHandler implements EventHandler {
  private amplitudeAPIKey: string = process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY || '';

  private logger: EventLogger = new EventLogger('AmplitudeHandler');

  private appBridge: InAppBridge = new InAppBridge();

  private appEvents: Array<EventType> = [];

  private brazeEvents: Array<EventType> = [...Object.values(HospitalEvent)];

  private amplitudeClient?: AmplitudeJs.AmplitudeClient = undefined;

  private identify?: AmplitudeJs.Identify = undefined;

  // TODO: resolve amplitudeClient and identify
  // private amplitudeClient?: AmplitudeJs.AmplitudeClient = process.browser ? Amplitude.getInstance() : undefined;

  // private identify: AmplitudeJs.Identify = process.browser ? new Amplitude.Identify() : undefined;

  initialize = () => {
    const amplitudeOpt: AmplitudeJs.Config = {
      includeReferrer: true,
      includeUtm: true,
      includeGclid: true,
      saveParamsReferrerOncePerSession: true,
    };

    this.amplitudeClient?.init(this.amplitudeAPIKey, undefined, amplitudeOpt);
    this.appBridge.init();
    this.logger.info('Amplitude client is initialized.');
  };

  handlePageviewEvent = (url: string) => {
    this.logger.info('please use tracker.sendEvent() for amplitude pageview events.', url);
  };

  handleEvent = (event: EventType, properties: Record<string, any>, platform: PlatformDetail) => {
    const eventProperties = {
      ...properties,
      platform_detail: platform,
    };

    this.logger.info(this.amplitudeClient, event, eventProperties, platform);

    if (isInApp()) {
      const data = { eventName: event, eventParam: eventProperties };
      this.logger.info(`send InAppEvent, data`, data);
      this.appBridge?.amplitudeEvent(data);

      if (this.appEvents.includes(event)) {
        this.appBridge?.appsFlyerEvent(data);
      }

      if (this.brazeEvents.includes(event)) {
        this.appBridge?.brazeEvent(data);
      }
    } else {
      this.amplitudeClient?.logEvent(event, eventProperties);
    }
  };

  setUserID = (userID: string) => {
    this.logger.info(userID);
    this.amplitudeClient?.setUserId(userID);
  };

  setUserProperties = (properties: any) => {
    this.logger.info(`set user properties`, properties);
    Object.keys(properties).forEach((key) => {
      this.identify?.set(key, properties[key]);
    });
    if (this.identify) {
      this.amplitudeClient?.identify(this.identify);
    }
  };
}
