import { Address } from './address';
import { getQueryParams } from './apiUtils';
import { BASE_URL, HTTP } from './http';
import { HospitalsReviewStatus, ImageFileResponse } from './reviewWrite';
import { HospitalDetail, INTO_LINK_STATUS, LikeResponse, ScrapResponse } from '@/api/hospital';
import { AnimalFilterType } from '@/components/category/useCategoryReviewStore';
import {
  ANIMAL,
  Pageable,
  PAGEABLE_KEY,
  PageableObject,
  PAGINATION_SIZE,
  ResponseList,
  ResponsePagingList,
  REVIEW_TYPE,
  Sort,
  SortOptionalPageable,
} from '@/constants/common';
import { SORT_FILTER_LIST } from '@/constants/filter';
import { comma, textClean } from '@/utils/stringUtils';

export enum GENDER {
  MALE = 'M',
  FEMALE = 'F',
}

export const GENDER_STRING = {
  [GENDER.MALE]: '수컷',
  [GENDER.FEMALE]: '암컷',
};

export const REVIEW_TYPE_STRING = {
  [REVIEW_TYPE.PRESCRIP]: '영수증 인증',
  [REVIEW_TYPE.RECEIPT]: '방문 인증',
  [REVIEW_TYPE.NORMAL]: '간편 리뷰',
};

export interface ReviewTag {
  id: number;
  name: string;
  reviewCount: number;
}

export const ReviewTagUtil = {
  sortByKeyword: (list: ReviewTag[], keyword: string | undefined) => {
    if (!keyword) {
      return list;
    }
    return list.sort((a, b) => {
      if (a.name === keyword) {
        return -1;
      }
      if (b.name === keyword) {
        return 1;
      }
      return 0;
    });
  },
};

export interface Review {
  animal: ANIMAL; // 펫 종류
  content: string; // 후기내용
  createdAt: string; // 생성일
  diseaseTags: ReviewTag[];
  display: boolean;
  highLight: string[];
  hospital: HospitalDetail;
  id: number; // 리뷰 id
  isDeleted: boolean; // 리뷰 더미 여부
  isDummy: boolean; // 리뷰 더미 여부
  isPhotoReview: boolean; // 리뷰 포토 여부
  likeCount: number; // 좋아요 갯수
  myLike: boolean; // 로그인 되어있는 유저가 좋아요를 선택했는지 여부
  myScrap: boolean; // 로그인 되어있는 유저가 스크랩을 했는지 여부
  petAgeMonth: number; // 펫의 개월수
  petBreed: string; // 펫의 품종
  petGender: GENDER; // 펫의 성별
  petId: number; // 펫 아이디
  petIsNeutralized: boolean; // 펫의 중성화 여부
  petName: string; // 펫 이름
  receiptAt?: string; // 영수증 날짜
  receiptImages: ImageFileResponse[];
  reviewImages: string[];
  reviewStatus: HospitalsReviewStatus; // 리뷰 상태
  revisit: boolean; // 재방문 여부
  score: number; // 리뷰의 점수
  scrapCount: number;
  symptomTags: ReviewTag[];
  type: REVIEW_TYPE; // 리뷰 유형
  userId: number; // 유저의 id
  userName: string; // 유저의 로그인 ID
  userReviewCount: number; // 유저의 리뷰 작성 횟수
  symptomContent: string | null; // 증상 필드 내용
  diseaseContent: string | null; // 질환 필드 내용
  reviewClinicResult: string | null; // 진료 후 선택 값
  userActive: boolean | null; // 회원 탈퇴 여부
}

export const ReviewUtil = {
  getReviewCountStr: (totalReviewCount: number) => {
    if (totalReviewCount < 10000) {
      return `${comma(totalReviewCount)}`;
    }
    const tenThousand = parseInt(`${totalReviewCount / 10000}`, 10);
    const remainder = totalReviewCount % 10000;
    return `${tenThousand > 0 ? `${comma(tenThousand)}만 ` : ''}${remainder > 0 ? `${comma(remainder)}` : ''}`.trim();
  },
  getReviewCountStrByRoundsThousand: (totalReviewCount: number) => {
    const tenThousandRound = Math.round(totalReviewCount / 10000);
    if (tenThousandRound < 1) {
      return `${comma(totalReviewCount)}`;
    }
    return `${comma(tenThousandRound)}만`;
  },
};

export interface ReviewSearch {
  animal?: AnimalFilterType;
  isPhotoReview: boolean;
  keyword: string;
  searchPosition?: SEARCH_POSITION_TYPE;
}

export interface ReviewDetail extends ReviewSearch {
  reviewId: number;
}

export const SEARCH_POSITION = {
  TAG: 'TAG',
  BODY: 'BODY',
} as const;

export const DEFAULT_REVIEW_DETAIL_PARAMS: ReviewDetail = {
  reviewId: 0,
  animal: ANIMAL.DOG,
  isPhotoReview: false,
  keyword: '',
  searchPosition: SEARCH_POSITION.TAG,
};

export enum HOSPITAL_REVIEW_QUERY_TYPE {
  HOSPITAL_DETAIL = 'HOSPITAL_DETAIL',
  HOSPITAL_REVIEW_LIST = 'HOSPITAL_REVIEW',
}

export interface HospitalReviewsParams extends ReviewSearch, Pageable {
  hospitalId: number;
}

export enum REVIEW_PARAMS_KEY {
  HOSPITAL_ID = 'hospitalId',
  PAGE = 'page',
  SIZE = 'size',
  SORT = 'sort',
  'ANIMAL' = 'animal',
  IS_PHOTO_REVIEW = 'isPhotoReview',
  KEYWORD = 'keyword',
  'SEARCH_POSITION' = 'searchPosition',
}

export const PHOTO_REVIEW_LIST_PREVIEW_SIZE = 3;

export const DEFAULT_REVIEW_LIST_SIZE = 3; // 병원 상세 페이지에 기본으로 보여지는 리뷰 갯수

export const DEFAULT_REVIEWS_PARAMS: HospitalReviewsParams = {
  hospitalId: -1,
  page: 0,
  size: PAGINATION_SIZE,
  sort: SORT_FILTER_LIST[0].value,
  animal: '',
  isPhotoReview: false,
  keyword: '',
  searchPosition: SEARCH_POSITION.TAG,
};

export interface HospitalPhotoReviewsParams {
  hospitalId: number;
  size: number;
  page: number;
}

export type HospitalPhotoReview = string;

export const DEFAULT_PHOTO_REVIEWS_PARAMS: HospitalPhotoReviewsParams = {
  hospitalId: -1,
  size: 20,
  page: 0,
};

export enum REPORT_TYPE {
  ABUSE = 'ABUSE',
  ILLEGAL = 'ILLEGAL',
  SPAM = 'SPAM',
  ETC = 'ETC',
}

export interface ReviewReport {
  type: keyof typeof REPORT_TYPE;
  description: string;
}

export interface ReviewReportParams extends ReviewReport {
  reviewId: number;
}

// 최신순 : sort=createdAt,DESC
// 도움 많은순, 같을 경우 최신순 : sort=likeCount,DESC&sort=createdAt,DESC
// 평점 높은순, 같을 경우 최신순 : sort=score,DESC&sort=createdAt,DESC
// 평점 낮은순, 같을 경우 최신순 : sort=score,ASC&sort=createdAt,DESC

export enum REVIEW_SORT {
  CREATE_AT_DESC = 'createdAt,DESC', // 최신순
  LIKE_COUNT = 'likeCount,DESC&sort=createdAt,DESC', // 도움 많은순, 같을 경우 최신순
  SCORE_DESC = 'score,DESC&sort=createdAt,DESC', // 평점 높은순, 같을 경우 최신순
  SCORE_ASC = 'score,ASC&sort=createdAt,DESC', // 평점 낮은순, 같을 경우 최신순
}

export const SORT_FILTER = [
  { value: REVIEW_SORT.CREATE_AT_DESC, label: '최신 순' },
  { value: REVIEW_SORT.LIKE_COUNT, label: '도움 많은순' },
  { value: REVIEW_SORT.SCORE_DESC, label: '평점 높은순' },
  { value: REVIEW_SORT.SCORE_ASC, label: '평점 낮은순' },
];

export type SEARCH_POSITION_TYPE = keyof typeof SEARCH_POSITION;

export interface ReviewsParams {
  animal?: ANIMAL;
  isPhotoReview?: boolean;
  keyword: string;
  searchPosition: SEARCH_POSITION_TYPE;
  hospitalId?: number;
  userId?: number;
  sort: REVIEW_SORT;
  size: number;
  ignoreReviewId?: string;
}

export const POPULARITY_REVIEWS_SIZE = 3;
export const DEFAULT_POPULARITY_REVIEWS_PARAMS: ReviewsParams = {
  isPhotoReview: false,
  keyword: '',
  searchPosition: SEARCH_POSITION.TAG,
  sort: REVIEW_SORT.LIKE_COUNT,
  size: POPULARITY_REVIEWS_SIZE,
};

export interface ReviewRelationHospitalParams {
  keyword?: string;
  animal?: ANIMAL;
  ignoreHospitalId: number;
  currentLatitude?: number;
  currentLongitude?: number;
  addressCode: string;
}

export interface ReviewRelationHospital {
  hospitalId: number;
  name: string;
  addr: string;
  address: Address;
  distance: number;
  totalScore: number;
  reviewCount: number;
  reviewContent: string;
  isAlliance: boolean;
  diseaseTags: ReviewTag[];
  symptomTags: ReviewTag[];
  intoLinkStatus: keyof typeof INTO_LINK_STATUS;
  isReservationAvailable: boolean;
  intoLinkHospitalWorkingStatus: boolean | null;
  isReservationPossible: boolean;
}

export interface ReviewRelationHospitalResponse {
  category: string;
  reviewHospitalList: ReviewRelationHospital[];
  addr: string;
  address: string;
  distance: string;
  hospitalId: number;
  isAlliance: boolean;
  name: string;
  reviewContent: string;
  reviewCount: number;
  totalScore: number;
  isReservationAvailable: boolean;
  intoLinkHospitalWorkingStatus: boolean | null;
  isReservationPossible: boolean;
}

export interface CategoryReviewsParams {
  diseaseIds: number[];
  symptomIds: number[];
  animal?: AnimalFilterType;
  isPhotoReview?: boolean;
  addressCode?: string;
  pageable: Pageable;
}

export type MyScrapReviewIdList = number[];
export type MyLikeReviewIdList = number[];

export interface ReviewProductParams {
  keyword: string;
  animal: ANIMAL;
}

export interface ReviewProduct {
  id: number;
  name: string;
  price: number;
  score: string;
  image: string;
  status: string;
  url: string;
  review_count: number;
  category_ids: number[];
  category_names: string[];
  discount_rate: number;
  is_timesale: boolean;
  original_price: number;
  small_image: string;
}

export const DEFAULT_REVIEW_PRODUCT_PARAMS: ReviewProductParams = {
  keyword: '',
  animal: ANIMAL.DOG,
};

export interface GetMyReviewDetailParams {
  reviewId: number;
}

export interface ReviewScrapListResponse {
  totalPages: number;
  totalElements: number;
  sort: Sort;
  first: boolean;
  last: boolean;
  numberOfElements: number;
  pageable: PageableObject;
  size: number;
  content: Review[];
  number: number;
  empty: boolean;
}

export interface HospitalSearchReviewParams {
  animal: ANIMAL;
  isPhotoReview: boolean;
  keyword: string;
  addressCode?: string;
  searchPosition: SEARCH_POSITION_TYPE;
  pageable: Pageable;
}

interface HospitalRecommendReviewParam {
  pageable: Pageable;
}

export interface GetSymptomDiseaseQuestions {
  [key: string]: string[];
}

export interface SaveSearchKeywordParams {
  searchKeyword: string;
}

export interface SaveSearchKeywordResponse {}

export interface GetTagRecommendParams {
  keyword: string;
  animal: ANIMAL.DOG | ANIMAL.CAT | ANIMAL.ETC;
}

export interface TagRecommend {
  tagName: string;
  cnt: number;
}

export type GetTagRecommendResponse = TagRecommend[];

export const ReviewApi = {
  getHospitalReviews: async (params: HospitalReviewsParams) => {
    const queryParams = getQueryParams(params);
    const response = await HTTP.get<ResponseList<Review>>(
      `${BASE_URL}/hospitals/${params.hospitalId}/reviews?${queryParams}`,
    );
    return response.data;
  },
  getHospitalPhotoReviews: async (params: HospitalPhotoReviewsParams) => {
    const queryParams = getQueryParams({
      [PAGEABLE_KEY.PAGE]: params.page,
      [PAGEABLE_KEY.SIZE]: params.size,
    });
    const response = await HTTP.get<ResponseList<HospitalPhotoReview>>(
      `${BASE_URL}/hospitals/${params.hospitalId}/review-images?${queryParams}`,
    );
    return response.data;
  },
  createHospitalReviewLike: async (reviewId: number) => {
    const response = await HTTP.post<number, LikeResponse>(`${BASE_URL}/hospitals/reviews/${reviewId}/likes`);
    return response.data;
  },

  getHospitalReviewDetail: async (params: ReviewDetail) => {
    const queryParams = getQueryParams(params);
    const response = await HTTP.get<Review>(`${BASE_URL}/hospitals/reviews/${params.reviewId}?${queryParams}`);
    return response.data;
  },
  createHospitalReviewReport: async (params: ReviewReportParams) => {
    const response = await HTTP.post<ReviewReportParams, {}>(
      `${BASE_URL}/hospitals/reviews/${params.reviewId}/reports`,
      params,
    );
    return response.data;
  },
  getReviews: async (params: ReviewsParams) => {
    const queryParams = getQueryParams({ ...params, sort: params.sort });
    const response = await HTTP.get<ResponseList<Review>>(`${BASE_URL}/hospitals/reviews?${queryParams}`);
    return response.data;
  },
  createReviewScrap: async (reviewId: number) => {
    const response = await HTTP.post<number, ScrapResponse>(`${BASE_URL}/hospitals/review-scrap/${reviewId}`);
    return response.data;
  },
  getReviewRelationHospital: async (params: ReviewRelationHospitalParams) => {
    const queryParams = getQueryParams(params);
    const response = await HTTP.get<ReviewRelationHospitalResponse>(
      `${BASE_URL}/hospitals/reviews/review-hospital?${queryParams}`,
    );
    return response.data;
  },
  getCategoryReviews: async (params: CategoryReviewsParams): Promise<ResponsePagingList<Review>> => {
    const queryParams = getQueryParams(params);
    const response = await HTTP.get<ResponsePagingList<Review>>(`${BASE_URL}/hospitals/home/reviews?${queryParams}`);
    return response.data;
  },
  getMyScrapReviewIdList: async () => {
    const response = await HTTP.get<MyScrapReviewIdList>(`${BASE_URL}/hospitals/my-review-id`);
    return response.data;
  },
  getMyLikeReviewIdList: async () => {
    const response = await HTTP.get<MyLikeReviewIdList>(`${BASE_URL}/hospitals/reviews/my-like-id`);
    return response.data;
  },
  getReviewProduct: async (params: ReviewProductParams) => {
    const queryParams = getQueryParams(params);
    const response = await HTTP.get<ReviewProduct[]>(`${BASE_URL}/hospitals/review/products?${queryParams}`);
    return response.data;
  },
  getMyReviewDetail: async (params: GetMyReviewDetailParams) => {
    const response = await HTTP.get<Review>(`${BASE_URL}/hospitals/reviews/my/${params.reviewId}`);
    return response.data;
  },
  getReviewScrapList: async (params: SortOptionalPageable) => {
    const queryParams = getQueryParams(params);
    const response = await HTTP.get<ReviewScrapListResponse>(`${BASE_URL}/hospitals/my-review?${queryParams}`);
    return response.data;
  },
  getReviewTotalCount: async () => {
    const response = await HTTP.get<number>(`${BASE_URL}/hospitals/reviews/count`);
    return response.data;
  },
  getHospitalSearchReviews: async (params: HospitalSearchReviewParams) => {
    const queryParams = getQueryParams({
      [PAGEABLE_KEY.PAGE]: params.pageable.page,
      [PAGEABLE_KEY.SIZE]: params.pageable.size,
      [PAGEABLE_KEY.SORT]: params.pageable.sort,
      keyword: textClean(params.keyword),
      animal: params.animal,
      isPhotoReview: params.isPhotoReview,
      searchPosition: params.searchPosition,
      addressCode: params.addressCode,
    });
    const response = await HTTP.get<ResponseList<Review>>(`${BASE_URL}/hospitals/reviews?${queryParams}`);
    return response.data;
  },
  getHospitalRecommendReviews: async (params: HospitalRecommendReviewParam) => {
    const queryParams = getQueryParams({
      [PAGEABLE_KEY.PAGE]: params.pageable.page,
      [PAGEABLE_KEY.SIZE]: params.pageable.size,
      [PAGEABLE_KEY.SORT]: params.pageable.sort,
    });
    const response = await HTTP.get<ResponseList<Review>>(`${BASE_URL}/hospitals/recommend-reviews?${queryParams}`);
    return response.data;
  },
  getLatelyReviews: async (params: ReviewsParams) => {
    const response = await HTTP.get<ResponseList<Review>>(
      `${BASE_URL}/hospitals/reviews-lately?keyword=${params.keyword}`,
    );
    return response.data;
  },
  getSymptomQuestions: async () => {
    const response = await HTTP.get<GetSymptomDiseaseQuestions>(`${BASE_URL}/hospitals/symptom/questions`);
    return response;
  },
  getDiseaseQuestions: async () => {
    const response = await HTTP.get<GetSymptomDiseaseQuestions>(`${BASE_URL}/hospitals/disease/questions`);
    return response;
  },
  saveSearchKeyword: async (params: SaveSearchKeywordParams) => {
    const response = await HTTP.post<SaveSearchKeywordParams, SaveSearchKeywordResponse>(
      `${BASE_URL}/hospitals/reviews/search-keyword`,
      params,
    );
    return response;
  },
  getTagRecommend: async (params: GetTagRecommendParams) => {
    const queryParams = getQueryParams({ keyword: params.keyword, animal: params.animal });
    const response = await HTTP.get<GetTagRecommendResponse>(
      `${BASE_URL}/hospitals/reviews/tag-recommend?${queryParams}`,
    );
    return response;
  },
};
