import { Body1 } from './Body1';
import { Body2 } from './Body2';
import { Body3 } from './Body3';
import { Body4 } from './Body4';
import { Body5 } from './Body5';
import { Caption1 } from './Caption1';
import { Caption2 } from './Caption2';
import { H1 } from './H1';
import { H2 } from './H2';
import { H3 } from './H3';
import { H4 } from './H4';
import { H5 } from './H5';
import { H6 } from './H6';

const Text = { H1, H2, H3, H4, H5, H6, Body1, Body2, Body3, Body4, Body5, Caption1, Caption2 };

export default Text;
